import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const UserChatsAPI = createAsyncThunk("userchats/fetch", async (params) => {
  let token5 = ""
  if (localStorage.getItem("auth_token") != null) {
    token5 = localStorage.getItem("auth_token")
  }
  try {
    const response = await fetch(`${process.env.REACT_APP_MODEL_URL}/admin_user_chat/?user_id=${params.user_id}&ucid=${params.chat_id}`, {
      credentials: "include",
      method: 'GET',
      headers: { Authorization: `Bearer ${token5}` }
    })
    const data = await response.json()
    return data
  }
  catch (error) {
  }
})

const userChatSlice = createSlice({
    name: "userChat",
    initialState: {
        status: 'idle',
        userQueriesArr: [],
        chatDate: -1,
        userName: undefined
    },
    extraReducers: (builder) => {
      builder
      .addCase(UserChatsAPI.pending, (state) => {
        state.status = 'loading'
        state.userQueriesArr = []
        state.chatDate = -1
        state.userName = undefined
      })
      .addCase(UserChatsAPI.fulfilled, (state, action) => {
        state.status = "succeeded"
        if(action.payload.message_history){
          state.userQueriesArr = action.payload.message_history
        }
        if(action.payload.date){
          state.chatDate = action.payload.date
        }
        if(action.payload.user_name){
          state.userName = action.payload.user_name
        }
      })
      .addCase(UserChatsAPI.rejected, (state) => {
        state.status = "failed"
        state.userQueriesArr = []
        state.chatDate = -1
        state.userName = undefined
      })
    }
})

export default userChatSlice.reducer