import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Query Count API
export const userStatAPI = createAsyncThunk("userstat/fetch", async (date) => {
  let token = "";
  if (localStorage.getItem("auth_token") != null) {
    token = localStorage.getItem("auth_token");
  }
  const res = await fetch(
    `${process.env.REACT_APP_MODEL_URL}/get_users_stats/?filter=${date.filter}`,
    {
      credentials: "include",
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  const data = await res.json();
  return data;
});

const userStatPageSlice = createSlice({
  name: "adminDashboard",
  initialState: {
    // Query Count
    queryCountAPIStatus: "idle",
    chartData: [],
    chartLabel: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(userStatAPI.pending, (state) => {
        state.queryCountAPIStatus = "loading";
        state.chartData = [];
        state.chartLabel = [];
      })
      .addCase(userStatAPI.fulfilled, (state, action) => {
        state.queryCountAPIStatus = "succeeded";
        if(action.payload.data){
          const resData = action.payload.data

          let label = Object.keys(resData); // Get the keys (labels) from the original data
          let data = [];

          // Iterate over each name and construct the desired format
          Object.keys(resData[label[0]]).forEach(name => {
              let newData = {
                  name: name,
                  data: label.map(key => resData[key][name] || 0) // Map the data for each label, default to 0 if not present
              };
              data.push(newData);
          });
          
          state.chartData = data;
          state.chartLabel = label;
        }
      })
      .addCase(userStatAPI.rejected, (state) => {
        state.queryCountAPIStatus = "failed";
        // state.queryCountAPIStatus = "loading";
        state.chartData = [
          { name: "Aditi Maini", data: [0, 0] },
          { name: "Arushi Verma", data: [0, 623] },
          { name: "Aryan Jain", data: [0, 91] },
          { name: "Ashish Sharma", data: [0, 0] },
          { name: "Gremener Team", data: [1, 0] },
        ];
        // state.chartData = [];
        // state.chartLabel = [];

        state.chartLabel = [
          "2",
          "12",
        ];
      });
  },
});

export default userStatPageSlice.reducer;
