import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

import HashLoader from "react-spinners/HashLoader";
import { Tooltip } from 'antd';

import { useSelector } from 'react-redux';

const QueryMetricsRadarChart = () => {
  // React Redux
  const {evalChartData, evalRunStatus, evalDataStatus} = useSelector((state) => state.querymetrics)


  const [dataPoints, setDataPoints] = useState([])
  const [xAxisLabel, setXAxisLabel] = useState([])
  const [maxValue, setMaxValue] = useState()

  useEffect(() => {
    let dataPointsArr = []
    let xAxisLabelArr = []

    if(Object.keys(evalChartData).length>0){
      let max = 0
      for(let key in evalChartData){
        let dataPoint = formatNumberWithOneDecimal(evalChartData[key])

        if(typeof(+dataPoint) === "number"){
          if((+dataPoint) > max){
            max = +dataPoint
          }
        }

        dataPointsArr.push(dataPoint)
        let labelKey = key
        labelKey = formatString(labelKey)
        xAxisLabelArr.push(labelKey)
      }
      if(max===1){
        setMaxValue(10)
      }
      else{
        const maxVal = extractValueAfterDecimal(max)
        setMaxValue(maxVal)
      }

      setDataPoints(dataPointsArr)
      setXAxisLabel(xAxisLabelArr)
    }
  }, [evalChartData])

  // Formatting data points only upto (1 decimal after point with no-roundoff)
  const formatNumberWithOneDecimal = (number) => {
    let formattedNumber = number.toString();
    let decimalIndex = formattedNumber.indexOf('.');
    if (decimalIndex !== -1 && formattedNumber.length >= decimalIndex + 3) {
        formattedNumber = formattedNumber.substring(0, decimalIndex + 2);
    }
    return formattedNumber;
  }

  // Formatting the x-Axis Label String
  const formatString = (str) => {
    return str.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
  }

  const extractValueAfterDecimal = (value) => {
    // Convert the value to a string
    const stringValue = value.toString();

    // Find the index of the decimal point
    const decimalIndex = stringValue.indexOf('.');

    // If there is no decimal point, return 0
    if (decimalIndex === -1) {
        return 0;
    }

    // Extract the digits after the decimal point
    const decimalDigitsStr = stringValue.substring(decimalIndex + 1);

    // Parse the extracted digits to a number
    const decimalDigits = parseFloat(decimalDigitsStr);

    return decimalDigits;
  }


  const series = [
    {
      name: 'Value',
      data: dataPoints,
    }
  ]



  const chartColors =  [ 
  '#56f556',  '#79f279', '#9beb9b', '#b0f5b0',  //green
  '#ede861', '#f0e918',  //yellow
  '#FFAF4D', '#FF8C00',  //orange
  '#f53838', '#fa0202' //red 
  ]

  const options = {
    chart: {
      // height: 650,
      type: 'radar',
    },
    dataLabels: {
      enabled: true
    },
    plotOptions: {
      radar: {
        size: 180,
        polygons: {
          // strokeColors: '#e9e9e9',
          strokeColors: 'blue',
          fill: {
            colors: chartColors.slice(-maxValue, )
          },

          
        //   strokeColors: 'black',
        //   fill: {
        //     colors: ['blue', '#fff']
        //   }
        }
      }
    },
    title: {
      text: 'Radar Chart'
    },
    // colors: ['#FF4560'],
    colors: ['black'],
    markers: {
    //   size: 4,
    //   colors: ['#fff'],
    //   strokeColor: '#FF4560',
    //   strokeWidth: 2,
      
    //   size: 4,
    //   colors: ['black'],
    //   strokeColor: 'black',
    //   strokeWidth: 4,
    },
    tooltip: {
      y: {
        formatter: function(val) {
          return val;
        }
      },
    },
    xaxis: {
      categories: xAxisLabel,
      labels: {
        style: {
            colors: ["black", "black", "black", "black", "black", "black", "black"],
            fontSize: '12px',
            fontWeight: "bold",
            cssClass: 'query__metrics__radar__chart--xaxis',
        }
      }
    },
    yaxis: {
      // tickAmount: 7,
      labels: {
        // formatter: function(val, i) {
        //   if (i % 2 === 0) {
        //     return val;
        //   } else {
        //     return '';
        //   }
        // }
      }
    }
  };

  const descriptionData = [
    {key: "Answer Relevancey", desc: "It is a metric used to evaluate how well-matched a generated answer is to the original question. It focuses on the relativity of the answer, rather than its factuality."},
    {key: "Context Precision", desc: "It refers to the accuracy and relevance of the information provided within a specific context. For example, if you're using RAGAS (Relevance and Grading Assessment System) to evaluate search results for a particular topic, context precision ensures that the information retrieved is directly related to that topic and is accurate in addressing the specific needs or queries within that context."},
    {key: "Context Recall", desc: "It is like checking if you have all the pieces to solve a puzzle. It assesses whether the retrieved context contains all the necessary information for a complete answer. A high score means the context covers everything needed for the answer."},
    {key: "Faithfulness", desc: "It assesses how accurately the generated answer aligns with the provided context. It checks if the answer stays true to the facts presented in the retrieved passages."},
  ]



  return (
    <div>
      {
        (evalDataStatus === "loading" || evalRunStatus === "loading")
        ?
        <div className='query__metrics__radar__chart__loader'>
          <HashLoader size={35} color="#5c6160" />
        </div>

        :
        
        <ReactApexChart options={options} series={series} type="radar" 
        height={450} 
        />
      }

      {
        xAxisLabel.length>0 
        &&
        <div className='query__metrics__chart__label__desc__box'>
          {
            descriptionData.map((data, i) => (
              <div key={i} className='query__metrics__chart__label__desc'>
                <span className='query__metrics__chart__label'>{data.key}: </span>
                <span className='query__metrics__chart__desc'>{data.desc}</span>
              </div>
            ))
          }
        </div>
      }
    </div>
  );
};

export default QueryMetricsRadarChart;


