
import Navbar from '../../components/Navbar/Navbar'
import AdminSidebar from '../../components/AdminSidebar/AdminSidebar';
import IterationComparisonGraph from '../../components/IterationComparisonGraph/IterationComparisonGraph';

const IterationComparisonGraphPage = () => {
    return (
        <main className="admin__dashboard__main__page">
      <div className="admin__dashboard__box">
        <Navbar />
        <div className='admin__dashboard__elems'>
          <AdminSidebar />
          <IterationComparisonGraph />
        </div>
      </div>
    </main>
    )
}

export default IterationComparisonGraphPage;