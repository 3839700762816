import React, { useEffect, useState } from 'react'
import { RxDashboard } from "react-icons/rx";
import { IoMdStats } from "react-icons/io";
import evaluationIcon from "../../components/image/admin-sidebar/assessment.png"

// Icons
import { BiSolidDownArrow } from "react-icons/bi";
import { BiSolidUpArrow } from "react-icons/bi";

import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { RotatingLines } from 'react-loader-spinner'
import { CiCalendar } from "react-icons/ci";

// React Redux
import { useDispatch, useSelector } from 'react-redux';
import { setQueryMetricSidebarStatus, evalRunAPI, evalDataAPI, setSelectedEvalMetricsComponent } from '../../features/QueryMetricsPage/QueryMetricsPageSlice';
import { setRealTimeMetricsSidebarStatus, setCurrentSelectedWeek, evalMatricFetchDataAPI } from '../../features/RealTimeMetricsPage/RealTimeMetricsPageSlice';


const AdminSidebar = () => {
  const location = useLocation();
  const navigate = useNavigate("")

  const [activeRunID, setActiveRunID] = useState()
  const [evaluationMatrixWeekData, setEvaluationMatrixWeekData] = useState([])
  const [currentSidebarActiveElem, setCurrentSidebarActiveElem] = useState(null)

  // const [selectedEvalMetricsComponent, setSelectedEvalMetricsComponent] = useState(-1)

  

  

  // React Redux
  const {queryMetricSidebarStatus, evalRunStatus, evalRunSidebarData, defaultQueryMetricsRunID, selectedEvalMetricsComponent} = useSelector((state) => state.querymetrics)
  const {realTimeMetricsSidebarStatus, evalOverviewStatus, sideBarData, currentSelectedEvalData, selectedProject} = useSelector((state) => state.realtimemetrics)
  const dispatch = useDispatch()


  useEffect(() => {
    if(defaultQueryMetricsRunID !== -1){
      setActiveRunID(defaultQueryMetricsRunID)
    }
  }, [defaultQueryMetricsRunID])


  useEffect(() => {
    let dataArr = []

    if(sideBarData.length>0){
      sideBarData.forEach((value, index) => {
        let month = value["month"]
        if(value["week"].length>0){
          value["week"].forEach((value, index) => {
            dataArr.push(`${month}-${value}`)
          })
        }
        // count++
      })


      dataArr.forEach((value, index) => {
            if(value === currentSelectedEvalData){
              setCurrentSidebarActiveElem(index)
            }
      })


    }

    setEvaluationMatrixWeekData(dataArr)

  }, [sideBarData])


  const sidebarClickHandler = (flag) => {
    if(flag === "adminDashboard"){
      dispatch(setQueryMetricSidebarStatus(false))
      dispatch(setRealTimeMetricsSidebarStatus(false))
      dispatch(setSelectedEvalMetricsComponent(-1))
      navigate("/admin/dashboard")
    }
    else if(flag === "userstats"){
      dispatch(setQueryMetricSidebarStatus(false))
      dispatch(setRealTimeMetricsSidebarStatus(false))
      dispatch(setSelectedEvalMetricsComponent(-1))
      navigate("/userstats")
    }

    else if(flag === "realtime_metrics"){
      dispatch(setQueryMetricSidebarStatus(false))
      dispatch(setRealTimeMetricsSidebarStatus(!realTimeMetricsSidebarStatus))
      dispatch(setSelectedEvalMetricsComponent(-1))
      navigate("/realtime_metrics")
    }
    else{
      dispatch(setRealTimeMetricsSidebarStatus(false))
      dispatch(setQueryMetricSidebarStatus(!queryMetricSidebarStatus))
      // if(evalRunStatus === 'idle'){
      //   dispatch(evalRunAPI())
      // }
      // navigate("/metrics")
    }
  }

  const evalRunClickHandler = (dataID) => {
    setActiveRunID(dataID)
    if(activeRunID !== dataID){
      dispatch(evalDataAPI({id: dataID}))
    }
  }

  // Real-Time Evaluation Metrics Sidebar
  const sidebarDataClickHandler = (week_data, i) => {
    let format_week_data = week_data.replace("-", "24_")
    dispatch(evalMatricFetchDataAPI({week: format_week_data, project_name: selectedProject}))
    setCurrentSidebarActiveElem(i)
    dispatch(setCurrentSelectedWeek(format_week_data))
  }
  console.log("selectedEvalMetricsComponent: ", selectedEvalMetricsComponent)
  // Ground Truth Evaluation Metrics Sidebar
  const groundTruthComponentClickHandler = (elem) => {
    console.log("Yes")
    if(elem === 0){
      if(location.pathname !== '/metrics'){
        if(evalRunStatus === 'idle'){
          dispatch(evalRunAPI())
        }
        navigate("/metrics")
      }
      if(selectedEvalMetricsComponent === 0){
        dispatch(setSelectedEvalMetricsComponent(-1))
      }
      else{
        dispatch(setSelectedEvalMetricsComponent(0))
      }
    }
    else if(elem === 1){
      if(selectedEvalMetricsComponent === 1){
        dispatch(setSelectedEvalMetricsComponent(-1))
      }
      else{
        console.log("Yes2")
        dispatch(setSelectedEvalMetricsComponent(1))
      }
    }
  }
  

  return (
    <div className='admin__sidebar__main'>
      <div className="admin__sidebar__main__box">
        <div className="admin__sidebar__box">

          <div className="a__sidebar__heading">
            <div className="a__sidebar__data">
              <span>Admin</span>
            </div>
            <div className='a__sidebar__line'></div>
          </div>

          <div className='a__sidebar__content__box'>
            <div onClick={() => sidebarClickHandler("adminDashboard")} className={(location.pathname === '/admin/dashboard' || location.pathname.includes('/userchat') || location.pathname.includes('/userquery')) ? "a__sidebar__content a__sidebar__content--active" : "a__sidebar__content"}>
              <div className='a__sidebar__content__elem1'>
                <RxDashboard size={20}/>
                <div>Overview</div>
              </div>
            </div>
            <div onClick={() => sidebarClickHandler("userstats")} className={location.pathname === '/userstats' ? "a__sidebar__content a__sidebar__content--active" : "a__sidebar__content"}>
              <div className='a__sidebar__content__elem1'>
                <IoMdStats size={25}/>
                <div>Usage Statistics</div>
              </div>
            </div>

            <div onClick={() => sidebarClickHandler("evalmetrics")} className={location.pathname === '/metrics' ? "a__sidebar__content a__sidebar__content--active" : "a__sidebar__content"}>
              <div className='a__sidebar__content__elem1'>
                <img className='a__sidebar__mtr__img' src={evaluationIcon} alt="mtrx" />
                <div>Ground Truth Evaluation Metrics</div>
              </div>
              <div className='a__sidebar__content__elem2'>
                {
                  queryMetricSidebarStatus ?
                  <BiSolidUpArrow />
                  :
                  <BiSolidDownArrow />
                }
              </div>
            </div>
            
            {
              queryMetricSidebarStatus 
              &&
              <div className='a__sidebar__dropdown__box__main a__sidebar__dropdown__box__main__new'>
                
                    <div className='a__sidebar__dropdown__data__box'>
                      <div 
                      className={selectedEvalMetricsComponent===0 ? 'a__sidebar__subcategory__heading a__sidebar__subcategory__heading--active' : 'a__sidebar__subcategory__heading' }
                      onClick={() => groundTruthComponentClickHandler(0)}>
                        Iterations
                        {
                          selectedEvalMetricsComponent===0 ?
                          <BiSolidUpArrow />
                          :
                          <BiSolidDownArrow />
                        }
                      </div>
                      {
                        selectedEvalMetricsComponent===0 &&
                        <div 
                        className={(evalRunStatus === "loading" || evalRunStatus==="failed") ? "a__sidebar__subcategory__content a__sidebar__subcategory__content--loading" : "a__sidebar__subcategory__content"}
                        >
                        {
                            evalRunStatus === "loading" ?
                            <RotatingLines
                              strokeColor="black"
                              strokeWidth="3"
                              animationDuration="1.2"
                              width="50"
                              visible={true}
                            />
                          :   
                                (evalRunSidebarData.length>0) &&
                                evalRunSidebarData.map((data, i) => (
                                  <div key={i} onClick={() => evalRunClickHandler(data.id)} 
                                  className={(activeRunID===data.id) ? 'a__sidebar__dropdown__content a__sidebar__dropdown__content--active' : 'a__sidebar__dropdown__content'}
                                  >
                                    {`Iteration ${data.id+1}`}
                                  </div>
                                ))
                        }
                        </div>
                      }
                      
                    </div>

                    <div className='a__sidebar__dropdown__data__box'>
                      <div 
                      className={selectedEvalMetricsComponent===1 ? 'a__sidebar__subcategory__heading a__sidebar__subcategory__heading--active' : 'a__sidebar__subcategory__heading' }
                        onClick={() => groundTruthComponentClickHandler(1)}>
                        View Comparison
                        {
                          selectedEvalMetricsComponent===1 ?
                          <BiSolidUpArrow />
                          :
                          <BiSolidDownArrow />
                        }
                      </div>
                      {selectedEvalMetricsComponent === 1 &&
                        <div className="a__sidebar__subcategory__content">
                          <div 
                          className={location.pathname === "/iteration/comparison/graph" ? "a__sidebar__subcategory__content--data a__sidebar__subcategory__content--dataActive" : "a__sidebar__subcategory__content--data"}
                          onClick={() => navigate("/iteration/comparison/graph")}>Iteration Comparison Graph</div>
                          <div className={location.pathname === "/iteration/comparison/table" ? "a__sidebar__subcategory__content--data a__sidebar__subcategory__content--dataActive" : "a__sidebar__subcategory__content--data"}
                          onClick={() => navigate("/iteration/comparison/table")}
                          >
                            Iteration Comparison Table
                          </div>
                        </div>
                      }
                    </div>
              
              </div>
            }


            {/* {
              queryMetricSidebarStatus 
              &&
              <div className={(evalRunStatus === "loading" || evalRunStatus==="failed") ? 'a__sidebar__dropdown__box__main a__sidebar__loader__box' : 'a__sidebar__dropdown__box__main'}>
                {
                  evalRunStatus === "loading"
                ?
                  <RotatingLines
                    strokeColor="black"
                    strokeWidth="3"
                    animationDuration="1.2"
                    width="50"
                    visible={true}
                  />
                :
                  evalRunStatus === 'failed'
                  ?
                  "Failed to fetch data"
                :
                  <div className="a__sidebar__dropdown__box--category">

                    <div className='a__sidebar__dropdown__data__box'>
                      <div 
                      className={selectedEvalMetricsComponent===0 ? 'a__sidebar__subcategory__heading a__sidebar__subcategory__heading--active' : 'a__sidebar__subcategory__heading' }
                      onClick={() => groundTruthComponentClickHandler(0)}>
                        Iterations
                        {
                          selectedEvalMetricsComponent===0 ?
                          <BiSolidUpArrow />
                          :
                          <BiSolidDownArrow />
                        }
                      </div>
                      {selectedEvalMetricsComponent===0 &&
                        <div className="a__sidebar__subcategory__content">
                            {
                              (evalRunSidebarData.length>0) &&
                              evalRunSidebarData.map((data, i) => (
                                <div key={i} onClick={() => evalRunClickHandler(data.id)} 
                                className={(activeRunID===data.id) ? 'a__sidebar__dropdown__content a__sidebar__dropdown__content--active' : 'a__sidebar__dropdown__content'}
                                >
                                  {`Iteration ${data.id+1}`}
                                </div>
                              ))
                            }
                        </div>
                      }
                    </div>

                    <div className='a__sidebar__dropdown__data__box'>
                      <div className={selectedEvalMetricsComponent===1 ? 'a__sidebar__subcategory__heading a__sidebar__subcategory__heading--active' : 'a__sidebar__subcategory__heading' }
                        onClick={() => groundTruthComponentClickHandler(1)}>
                        View Comparison
                        {
                          selectedEvalMetricsComponent===1 ?
                          <BiSolidUpArrow />
                          :
                          <BiSolidDownArrow />
                        }
                      </div>
                      {selectedEvalMetricsComponent===1 &&
                        <div className="a__sidebar__subcategory__content">
                          <div className="">Iteration 1</div>
                          <div className="">Iteration 2</div>
                          <div className="">Iteration 1</div>
                          <div className="">Iteration 2</div>
                          <div className="">Iteration 1</div>
                          <div className="">Iteration 2</div>
                          <div className="">Iteration 1</div>
                          <div className="">Iteration 2</div>
                          <div className="">Iteration 1</div>
                          <div className="">Iteration 2</div>
                          <div className="">Iteration 1</div>
                          <div className="">Iteration 2</div>
                          <div className="">Iteration 1</div>
                          <div className="">Iteration 2</div>
                          <div className="">Iteration 1</div>
                          <div className="">Iteration 2</div>
                        </div>
                      }
                    </div>

                  </div>
              }
              </div>
            } */}

            <div onClick={() => sidebarClickHandler("realtime_metrics")} className={location.pathname === '/realtime_metrics' ? "a__sidebar__content a__sidebar__content--active" : "a__sidebar__content"}>
              <div className='a__sidebar__content__elem1'>
                <CiCalendar size={37}/>
                <div>Real-Time Evaluation Metrics</div>
              </div>
              <div className='a__sidebar__content__elem2'>
                {
                  realTimeMetricsSidebarStatus ?
                  <BiSolidUpArrow />
                  :
                  <BiSolidDownArrow />
                }
              </div>
            </div>


            {
              realTimeMetricsSidebarStatus 
              &&
              <div className={(evalOverviewStatus === "loading" || evalOverviewStatus==="failed") ? 'a__sidebar__dropdown__box__main a__sidebar__loader__box' : 'a__sidebar__dropdown__box__main'}>
                {
                  evalOverviewStatus === "loading"
                ?
                  <RotatingLines
                    strokeColor="black"
                    strokeWidth="3"
                    animationDuration="1.2"
                    width="50"
                    visible={true}
                  />
                :
                evalOverviewStatus === 'failed'
                  ?
                  "Failed to fetch data"
                :
                  <div className="a__sidebar__dropdown__box">
                    <div className='a__sidebar__dropdown__content__box'>
                      {
                        (evaluationMatrixWeekData.length>0) &&
                        evaluationMatrixWeekData.map((data, i) => (
                          <div key={i} onClick={() => sidebarDataClickHandler(data, i)} 
                          // className='a__sidebar__dropdown__content'
                          className={(currentSidebarActiveElem===i) ? 'a__sidebar__dropdown__content a__sidebar__dropdown__content--active' : 'a__sidebar__dropdown__content'}
                          >
                            {data}
                          </div>
                        ))
                      }
                    </div>
                  </div>
              }
              </div>
            }

          </div>

        </div>
      </div>
    </div>
  )
}

export default AdminSidebar
