import { configureStore } from "@reduxjs/toolkit";

import AdminPageReducer from "../features/AdminPage/AdminPageSlice"
import UserInqueriesPageReducer from "../features/UserInqueriesPage/UserInqueriesSlice"
import UserChatPageReducer from "../features/UserChatPage/UserChatPageSlice"
import UserStatPageReducer from "../features/UserStatPage/UserStatPageSlice"
import queryMetricsPageReducer from "../features/QueryMetricsPage/QueryMetricsPageSlice"
import RealTimeMetricsPageReducer from "../features/RealTimeMetricsPage/RealTimeMetricsPageSlice"

const store = configureStore({
    reducer: {
        admindashboard: AdminPageReducer,
        userinquery: UserInqueriesPageReducer,
        userchat: UserChatPageReducer,
        userstats: UserStatPageReducer,
        querymetrics: queryMetricsPageReducer,
        realtimemetrics: RealTimeMetricsPageReducer
    }
})

export default store