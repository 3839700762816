import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./components/css/Index.css";

import SectorPage from "./pages/SectorPage/SectorPage";
import TechnicalAdvisorPage from "./pages/TechnicalAdvisorPage/TechnicalAdvisorPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import AdminPage from "./pages/AdminPage/AdminPage";
import UserInqueriesPage from "./pages/UserInqueriesPage/UserInqueriesPage";
import UserChatPage from "./pages/UserChatPage/UserChatPage";
import UserStatGraphPage from "./pages/UserStatGraphPage/UserStatGraphPage";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import QueryMetricsPage from "./pages/QueryMetricsPage/QueryMetricsPage";
import RealTimeMetricsPage from "./pages/RealTimeMetricsPage/RealTimeMetricsPage";
import IterationComparisonGraphPage from "./pages/IterationComparisonGraph/IterationComparisonGraphPage";

import ProtectedRoutes from "./Services/ProtectedRoutes";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/sector" element={<ProtectedRoutes />}>
          <Route path="/sector" element={<SectorPage />} />
        </Route>
        
        <Route path="/technicaladvisor" element={<ProtectedRoutes />}>
          <Route path="/technicaladvisor" element={<TechnicalAdvisorPage />} />
        </Route>

        <Route path="/admin/dashboard" element={<AdminPage />} />
        <Route path="/userchat" element={<UserInqueriesPage />} />
        <Route path="/userquery" element={<UserChatPage />} />

        <Route path="/userstats" element={<UserStatGraphPage />} />
        <Route path="/metrics" element={<QueryMetricsPage />}/>
        <Route path="/realtime_metrics" element={<RealTimeMetricsPage />}/>
        <Route path="/iteration/comparison/graph" element={<IterationComparisonGraphPage />}/>

        <Route path="/" element={<LoginPage />} />

        <Route path='*' element={<NotFoundPage />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
