import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";



export const userInqueriesAPI = createAsyncThunk('userinquery/fetch', async (params) => {
    let token = ""
    if(localStorage.getItem("auth_token") != null){
        token = localStorage.getItem("auth_token")
    }

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const res = await fetch(`${process.env.REACT_APP_MODEL_URL}/admin_user_data/?user_id=${params.user_id}&start=${params.startDate}&end=${params.endDate}`, requestOptions)
    const data = await res.json();
    return data
})

const userInqueriesSlice = createSlice({
    name: "userInqueries",
    initialState: {
        status: 'idle',
        chatList: [],
        totalChats: -1,
        totalQueries: -1,
        userName: null
    },
    extraReducers: (builder) => {
        builder
        .addCase(userInqueriesAPI.pending, (state) => {
            state.status = 'loading'
            state.chatList = []
            state.totalChats = -1
            state.totalQueries = -1
            state.userName = null
        })
        .addCase(userInqueriesAPI.fulfilled, (state, action) => {
            state.status = "succeeded"
            let new_arr = action.payload.chats
            if(action.payload.chats){
                new_arr.sort((a, b) => {
                    return a.ucid - b.ucid;
                });
                state.chatList = new_arr
            }
            if(action.payload.num_chats){
                state.totalChats = action.payload.num_chats
            }
            if(action.payload.num_queries){
                state.totalQueries = action.payload.num_queries / 2
            }
            if(action.payload.user_name){
                let name = action.payload.user_name
                state.userName = name
            }
        })
        .addCase(userInqueriesAPI.rejected, (state) => {
            state.status = 'failed'
            state.chatList = []
            state.totalChats = -1
            state.totalQueries = -1
            state.userName = null
        })
    }
})

export default userInqueriesSlice.reducer