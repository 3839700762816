import React, {useState, useEffect, useRef} from 'react'

import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom';

// Date Calendra
import { DatePicker, Tooltip } from 'antd';
import dayjs from 'dayjs';

// Skeleton Loader
import Skeleton from '@mui/material/Skeleton';

// Image 
import leftArrow from "../svg-store/userInquires/left_arrow.svg"
import inActiveArrow from "../svg-store/userInquires/inactive.svg"
import activeArrow from "../svg-store/userInquires/active.svg"
import filterIcon from "../svg-store/userInquires/filter.svg"
import sortIcon from "../svg-store/userInquires/sort.svg"
import expandIcon from "../svg-store/userInquires/expand.svg"
import contractIcon from "../svg-store/userInquires/contract.svg"
import { IoIosArrowForward } from "react-icons/io";
import { CiCalendar } from "react-icons/ci";
import { RxCross1 } from "react-icons/rx";
import selectedIcon from "../svg-store/userstatistics/selected.svg"
import { MdCancel } from "react-icons/md";

// APIS
import { useDispatch, useSelector } from "react-redux";
import { userInqueriesAPI } from '../../features/UserInqueriesPage/UserInqueriesSlice';


// Time ago
import {subMonths, format, subDays, subWeeks, subYears } from 'date-fns'

const UserInqueries = ({userDataIsLoading, setUserClickedStatus}) => {
  // Accessing User ID from URL
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  let userID = null
  userID = params.get('user');
  const [userIDData, setUserIDData] = useState(false)

  // console.log(userID)
  
  useEffect(() => {
    if(userID!==null && userID.length === 0){
      setUserIDData(true)
    }
    // console.log(userID, userID.length)
  }, [userID])

  const dummyUserQueries = [
    { chatID: "1", 
      chatTitle: "As a mining engineer, how do you prioritize between exploring for new mineral deposits and maximizing extraction from existing mines? The principal basis for restricting cell phone use in the classroom is that phones can act as a distraction. Not only do students and teachers become diverted, but this has an analogous.",
      totalQueries: "12",
      date: "02 Jan 2024"
    },
    { chatID: "1", 
      chatTitle: "As a mining engineer, how do you prioritize between exploring for new mineral deposits and maximizing extraction from existing mines? The principal basis for restricting cell phone use in the classroom is that phones can act as a distraction. Not only do students and teachers become diverted, but this has an analogous.",
      totalQueries: "12",
      date: "02 Jan 2024"
    },
    { chatID: "1", 
      chatTitle: "As a mining engineer, how do you prioritize between exploring for new mineral deposits and maximizing extraction from existing mines? The principal basis for restricting cell phone use in the classroom is that phones can act as a distraction. Not only do students and teachers become diverted, but this has an analogous.",
      totalQueries: "12",
      date: "02 Jan 2024"
    },
    { chatID: "1", 
      chatTitle: "As a mining engineer, how do you prioritize between exploring for new mineral deposits and maximizing extraction from existing mines? The principal basis for restricting cell phone use in the classroom is that phones can act as a distraction. Not only do students and teachers become diverted, but this has an analogous.",
      totalQueries: "12",
      date: "02 Jan 2024"
    },
    { chatID: "1", 
      chatTitle: "As a mining engineer, how do you prioritize between exploring for new mineral deposits and maximizing extraction from existing mines? The principal basis for restricting cell phone use in the classroom is that phones can act as a distraction. Not only do students and teachers become diverted, but this has an analogous.",
      totalQueries: "12",
      date: "02 Jan 2024"
    },
    { chatID: "1", 
      chatTitle: "As a mining engineer, how do you prioritize between exploring for new mineral deposits and maximizing extraction from existing mines? The principal basis for restricting cell phone use in the classroom is that phones can act as a distraction. Not only do students and teachers become diverted, but this has an analogous.",
      totalQueries: "12",
      date: "02 Jan 2024"
    },
    { chatID: "1", 
      chatTitle: "As a mining engineer, how do you prioritize between exploring for new mineral deposits and maximizing extraction from existing mines? The principal basis for restricting cell phone use in the classroom is that phones can act as a distraction. Not only do students and teachers become diverted, but this has an analogous.",
      totalQueries: "12",
      date: "02 Jan 2024"
    },
  ]

  const [userChatArr, setUserChatArr] = useState(dummyUserQueries)
  
  
  // Redux
  const {status, chatList, totalQueries, totalChats, userName} = useSelector((state) => state.userinquery)
  // console.log(chatList)

  const curr_date = new Date();
  let currentDate = curr_date.getDate().toString() + "/" + (curr_date.getMonth() + 1).toString() + "/" + curr_date.getFullYear().toString().slice(2)

  const start_date = "1/1/21"

  const dispatch = useDispatch()
  useEffect(() => {
    // if(status === 'idle'){
      dispatch(userInqueriesAPI({user_id: userID, startDate: start_date, endDate: currentDate}))
    // }
  }, [userID])


  useEffect(() => {
    if(chatList.length !== 0){
      let newUserQuery = []
      chatList.forEach((value) => {
        newUserQuery.push({chatID: value.ucid, chatTitle: value.chat_title, totalQueries: (value.queries / 2), date: value.date})
      })
      setUserChatArr(newUserQuery)
    }
  }, [chatList])
  // console.log(typeof(chatList))




  const [cleared, setCleared] = React.useState(false);

  useEffect(() => {
    if (cleared) {
      const timeout = setTimeout(() => {
        setCleared(false);
      }, 1500);

      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [cleared]);


  const navigate = useNavigate()

  // Filter useState
  const [filterClickedStatus, setFilterClickedStatus] = useState(false)
  const [currentActiveFilter, setCurrentActiveFilter] = useState(0)

  const [sortByFilterStatus, setSortByFilterStatus] = useState(false)

  

                   
  const [viewOnlyQuesStatus, setViewOnlyQuesStatus] = useState(false)
  // view Only Question then contract all questions
  const viewQuesOnlyClickHandler = () => {
      setViewOnlyQuesStatus(!viewOnlyQuesStatus)
      setClickedQuesArray([])
  }                  


  const [clickedQuesArray, setClickedQuesArray] = useState([])
  // Expand and contract question handler
  const clickedQuesArrayHandler = (index) => {
    if(viewOnlyQuesStatus){
      return
    }
    if(clickedQuesArray.includes(index)){
      const newArray = clickedQuesArray.filter((element) => element !== index);
      setClickedQuesArray(newArray);
    }
    else{
      setClickedQuesArray(oldArray => [...oldArray, index]);
    }
  }

  const dateFilterHandler = () => {
    setFilterClickedStatus(!filterClickedStatus)
    setCurrentActiveFilter(0)
  }

  const filterByDateClickHandler = () => {
    if(currentActiveFilter === 1){
      setCurrentActiveFilter(0)
    }
    else{
      setCurrentActiveFilter(1)
    }
  }

  const filterByChatClickHandler = () => {
    if(currentActiveFilter === 2){
      setCurrentActiveFilter(0)
    }
    else{
      setCurrentActiveFilter(2)
    }
  }


  // useRef
  let menuRef1 = useRef();
  let menuRef2 = useRef();

  // useEffect(() => {

  //   let handler1 = (e)=>{
  //     if(filterClickedStatus){
  //       if(!document.getElementsByClassName("filter__box__dropdown")[0].contains(e.target) && 
  //       !menuRef1.current.contains(e.target)){
  //         if(currentActiveFilter !== 0){
  //           if(!document.getElementsByClassName("filter__box__content1")[0].contains(e.target)){
  //             setFilterClickedStatus(false);
  //             setCurrentActiveFilter(0);
  //           }
  //         }
  //         else{
  //           setFilterClickedStatus(false);
  //           setCurrentActiveFilter(0);
  //         }
  //       }    
  //   }  
  //   };

  //   let handler2 = (e)=>{
  //     if(sortByFilterStatus){
  //       if(!document.getElementsByClassName("sortBy__filter__box")[0].contains(e.target) && 
  //       !menuRef2.current.contains(e.target)){
  //         setSortByFilterStatus(false);
  //         setCurrentActiveFilter(0);
  //       }
  //     }    
  //   }  
    


  //   document.addEventListener("mousedown", handler1);
  //   document.addEventListener("mousedown", handler2);
    

  //   return() =>{
  //     document.removeEventListener("mousedown", handler1);
  //     document.removeEventListener("mousedown", handler2);
  //   }

  // });

  const [selectedDate, setSelectedDate] = useState([])


  const dateRangeSelectorFunction = (date_data) => {
    let st_date = null
    let ed_date = null

    if(date_data !== null){
      let year = date_data[0]["$y"].toString().slice(2)
      st_date = date_data[0]["$D"].toString() + "/" + (date_data[0]["$M"] + 1).toString() + "/" +year

      // console.log(st_date)

      year = date_data[1]["$y"].toString().slice(2)
      ed_date = date_data[1]["$D"].toString() + "/" + (date_data[1]["$M"] + 1).toString() + "/" +year
      // console.log(ed_date)
      
      dispatch(userInqueriesAPI({user_id: userID, startDate: st_date, endDate: ed_date}))

      setFilterClickedStatus(false)
      setCurrentActiveFilter(0)
      setSortByFilterStatus(false)

      setSelectedDate([formatDateToDisplay(st_date), formatDateToDisplay(ed_date)])
    }

    if(st_date !== null){
      
    }
  }   

  const clearFilter = () => {
    setSelectedDate([])
    dispatch(userInqueriesAPI({user_id: userID, startDate: start_date, endDate: currentDate}))

    setFilterClickedStatus(false)
    setCurrentActiveFilter(0)
    setSortByFilterStatus(false)
    setSelectedFilterDate(0)

    setInputValue("")
  }

  const [selectedIndexOfSort, setSelectedIndexOfSort] = useState(0)

  const sortingClickHandler = (sort) => {
    if(sort === "new"){
      const sortedAsc = sortArrNewestOldest(userChatArr, "new")
      setUserChatArr(sortedAsc)

      setSelectedIndexOfSort(1)
      setSortByFilterStatus(false)

    }
    else{
      const sortedDsc = sortArrNewestOldest(userChatArr, "old")
      setUserChatArr(sortedDsc)
      
      setSelectedIndexOfSort(2)
      setSortByFilterStatus(false)
    }
  }

  const clearSortFilter = () => {
    setSelectedIndexOfSort(0)

    const sortedDsc = sortArrNewestOldest(userChatArr, "old")
    setUserChatArr(sortedDsc)

  }


  const sortArrNewestOldest = (chat_arr, flag) => {

    const arr1 = chat_arr.map(obj => {
      const parts = obj.date.split('/');
      if (parts.length === 3) {
        const year = parseInt(parts[2], 10); 
        obj.date = new Date(year > 50 ? 1900 + year : 2000 + year, parts[1] - 1, parts[0]); // Adjust the year
      } 
      else {
        obj.date = new Date(obj.date);
      }
      return obj;
    });

    let sortedAsc = []
    if(flag === "new"){
      sortedAsc = arr1.sort((objA, objB) => objB.date - objA.date,);
    }
    else{
      sortedAsc = arr1.sort((objA, objB) => objA.date - objB.date,);
    }

    
    sortedAsc.forEach(obj => {
      const date = obj.date;
      const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear() % 100}`; // Get last two digits of the year
      obj.date = formattedDate;
    });
    
    return sortedAsc

  }

  // Format Date
  const formatDateToDisplay = (input_date) => {
    let dateStr = input_date

    // Split the date string into day, month, and year
    var parts = dateStr.split('/');

    // Convert the string parts into integers
    var day = parseInt(parts[0], 10);
    var month = parseInt(parts[1], 10);
    var year = parseInt(parts[2], 10);


    // Define month names
    var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    // Format the date string as "DD Mon YY"
    var formattedDate = ('0' + day).slice(-2) + ' ' + monthNames[month - 1] + ' ' + ('' + year).slice(-2);

    return formattedDate
  }

  const [selectedFilterDate, setSelectedFilterDate] = useState(0)

  // Time Ago
  const customDateHandler = (flag) => {
    if(flag === "today"){
      setSelectedFilterDate(1)
      if(selectedFilterDate !== 1){
        dispatch(userInqueriesAPI({user_id: userID, startDate: currentDate, endDate: currentDate}))
        setSelectedDate([formatDateToDisplay(currentDate), formatDateToDisplay(currentDate)])
      }
    }
    else if(flag === "yesterday"){
      setSelectedFilterDate(2)
      if(selectedFilterDate !== 2){
        let yesterday = subDays(new Date(), 1);
        yesterday = format(yesterday, "dd/MM/yy")
        dispatch(userInqueriesAPI({user_id: userID, startDate: yesterday, endDate: currentDate}))
        setSelectedDate([formatDateToDisplay(yesterday), formatDateToDisplay(currentDate)])
      }
    }
    else if(flag === "week"){
      setSelectedFilterDate(3)
      if(selectedFilterDate !== 3){
        let week = subWeeks(new Date(), 1);
        week = format(week, "dd/MM/yy")
        dispatch(userInqueriesAPI({user_id: userID, startDate: week, endDate: currentDate}))
        setSelectedDate([formatDateToDisplay(week), formatDateToDisplay(currentDate)])
      }
    }
    else if(flag === "month"){
      setSelectedFilterDate(4)
      if(selectedFilterDate !== 4){
        let oneMonthAgo = subMonths(new Date(), 1);
        oneMonthAgo = format(oneMonthAgo, "dd/MM/yy")

        dispatch(userInqueriesAPI({user_id: userID, startDate: oneMonthAgo, endDate: currentDate}))
        
        setSelectedDate([formatDateToDisplay(oneMonthAgo), formatDateToDisplay(currentDate)])
      }
    }
    else{
      setSelectedFilterDate(5)
      if(selectedFilterDate !== 4){
        let year = subYears(new Date(), 1);
        year = format(year, "dd/MM/yy")

        dispatch(userInqueriesAPI({user_id: userID, startDate: year, endDate: currentDate}))
        
        setSelectedDate([formatDateToDisplay(year), formatDateToDisplay(currentDate)])
      }
    }

    setFilterClickedStatus(false)
    setCurrentActiveFilter(0)
    setSortByFilterStatus(false)
  }


  const [inputValue, setInputValue] = useState("");
  console.log(inputValue)
  

 

  return (
    <div className='userinqueries__main'>
      {
        !userIDData ?
      
      <div className="userinqueries__main--box">

        <div className="userinqueries__userdetail__box">
          <div className="userinqueries__userdetail__element1">
              <button className='no__button' onClick={() => navigate("/admin/dashboard")}>
                <img style={{width: "1rem"}} src={leftArrow} alt="arrow" />
              </button>
              <div className='userinqueries__userdetails'>
                  <div className="userinqueries__userdetails--elem">
                    <span>User: </span>
                    {
                      userName === null ?
                      <Skeleton width={180} height={30} className="userinqueries__username" animation="wave" variant="text" />
                      :
                      <span className='userinqueries__username'>{userName}</span>
                    }
                  </div>
                  <div className="userinqueries__userdetails--elem">
                    <span>USER ID: </span>
                    <span className='userinqueries__userid'>{userID}</span>
                  </div>
              </div>
          </div>
        </div>

        <div className="userstatistics__filter__box">

          <div className='userinqueries__filter__box__leftelem'>
              <div className="userinquires__filter">

                <div className="userinqueries__leftelem">
                  <button ref={menuRef1} onClick={dateFilterHandler} className='userinquires__filter__button'>
                      <img src={filterIcon} alt="filter" />
                      <div className='userinquires__filter__badge'>
                        <span>Filter:&nbsp;
                          <span className='filter--color'>
                            {
                              inputValue.length !== 0
                              ?
                              `Selected Chat ID:  ${inputValue}`
                              :
                              selectedDate.length === 0 ? 
                              "DD-MM-YY"
                              :
                              selectedDate[0] === selectedDate[1] ?
                              `${selectedDate[0]}`
                              :
                              `${selectedDate[0]} - ${selectedDate[1]}`
                            }
                          </span>
                        </span>
                        {/* <span className="badge"></span> */}
                      </div>
                  </button>

                  {
                    (selectedDate.length !== 0 || inputValue.length !== 0) &&
                    <div onClick={clearFilter} className="userstats__cancel__div">
                      <MdCancel size={20} color={"#828282"}/>
                    </div>
                  }
                </div>

                {filterClickedStatus &&
                <div className="filter__box__dropdown">
                  <div onClick={filterByDateClickHandler} className="filter__box__dropdown__elem">
                    <span className="filter__box__dropdown__elem__span">Filter by Date</span>
                    <IoIosArrowForward size={20}/>
                  </div>

                  <div onClick={filterByChatClickHandler} className="filter__box__dropdown__elem">
                    <span className="filter__box__dropdown__elem__span">Filter by Chat ID</span>
                    <IoIosArrowForward size={20}/>
                  </div>
                </div>
                }

              </div> 

              {
                filterClickedStatus &&

                <div className="filter__box__content1">

                  {currentActiveFilter === 1 &&

                    <div className="filter__date__box">


                    <div className="filter__date__elem1">
                      <div className="filter__date__font__heading">Filter by Date</div>
                      <div className={selectedFilterDate===1 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                        <button onClick={() => customDateHandler("today")} className="no__button filter__date__font">
                          Today
                          {selectedFilterDate===1 && <img src={selectedIcon} alt="select" />}
                        </button>
                      </div>
                      <div className={selectedFilterDate===2 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                        <button onClick={() => customDateHandler("yesterday")} className="no__button filter__date__font">
                          Yesterday
                          {selectedFilterDate===2 && <img src={selectedIcon} alt="select" />}
                        </button>
                      </div>
                      <div className={selectedFilterDate===3 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                        <button onClick={() => customDateHandler("week")} className="no__button filter__date__font">
                          1 Week ago
                          {selectedFilterDate===3 && <img src={selectedIcon} alt="select" />}
                        </button>
                      </div>
                      <div className={selectedFilterDate===4 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                        <button onClick={() => customDateHandler("month")} className="no__button filter__date__font">
                          1 Month ago
                          {selectedFilterDate===4 && <img src={selectedIcon} alt="select" />}
                        </button>
                      </div>
                      <div className={selectedFilterDate===5 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                        <button onClick={() => customDateHandler("year")} className="no__button filter__date__font">
                          1 Year ago
                          {selectedFilterDate===5 && <img src={selectedIcon} alt="select" />}
                        </button>
                      </div>
                    </div>

                      <div className="filter__date__horizontal__line"></div>

                      <div className="filter__date__elem2">
                        <span className="filter__date__elem2__text">Custom Date Range</span>

                        <div className="filter__date__specific__date">
                          <DatePicker.RangePicker placeholder={["Start", "End"]}
                            className="antd__datepicker"
                            format={"DD-MM-YY"}
                            minDate={dayjs(start_date)}
                            maxDate={dayjs(new Date())}
                            // needConfirm={true} 
                            onChange={dateRangeSelectorFunction}
                          />
                        </div>

                      </div>

                    </div> 
                  }

                  {currentActiveFilter === 2 &&

                  <div className='filter__chat__box'>
                    <span className='filter__chat__span'>Filter by Chat ID</span>
                    <div className='filter__chat__input__box'>
                      <input size="30" type="text" 
                      className="filter__chat__input" 
                      placeholder="Enter Chat ID" 
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      />
                      {
                        inputValue.length>0 &&
                        <button onClick={() => setInputValue("")} className='no__button'>
                          <RxCross1 />
                        </button>
                      }
                    </div>
                  </div>
                  }

                </div>

              }


              <div ref={menuRef2}  className="userinquires__filter">
                <div className="userinqueries__leftelem">
                  <button onClick={() => setSortByFilterStatus(!sortByFilterStatus)} className='userinquires__filter__button'>
                      <img src={sortIcon} alt="filter" />
                      <div className='userinquires__filter__badge'>
                        <span>Sort: &nbsp;
                          <span className='filter--color'>
                            {
                              selectedIndexOfSort === 1 ?
                              "Newest first"
                              :
                              selectedIndexOfSort === 2 ?
                              "Oldest first"
                              :
                              "Sort Elements"
                            }
                            
                          </span>
                        </span>
                        {/* <span className="badge"></span> */}
                      </div>
                  </button>
                  {
                    selectedIndexOfSort !== 0 &&
                    <div onClick={clearSortFilter} className="userstats__cancel__div">
                      <MdCancel size={20} color={"#828282"}/>
                    </div>
                  }
                </div>
              </div>

              {
                sortByFilterStatus &&

                <div className='sortBy__filter__box'>
                  <button onClick={() => sortingClickHandler("new")} className={selectedIndexOfSort === 1 ? "no__button sortBy__text sortBy__text--active" : "no__button sortBy__text" }>
                    Newest First
                  </button>
                  {/* <button onClick={() => sortingClickHandler("old")} className='no__button sortBy__text sortBy__text--active'> */}
                  <button onClick={() => sortingClickHandler("old")} className={selectedIndexOfSort === 2 ? "no__button sortBy__text sortBy__text--active" : "no__button sortBy__text" }>
                    Oldest First
                  </button>
                </div>
              }

          </div>

          <div className='userinqueries__number__box'>   
            <div className='userinqueries__number__box__elem1'>
              <span className='userinqueries__showing--color'>Showing: </span>
              {
                totalChats === -1 ?
                <Skeleton width={50} height={30} className="userinqueries--count" animation="wave" variant="text" />
                :
                <span className='userinqueries--count'>
                  {totalChats} &nbsp;
                  <Tooltip placement="top" title={"The session is simply a conversation/chat in the chat history on the left side of the chat interface."}>
                    Sessions
                  </Tooltip>
                </span>
              }
              
            </div>
            <div className='userinqueries__number__box__elem1'>
              <span className='userinqueries__showing--color'>Total: </span>
              {
                totalQueries === -1 ?
                <Skeleton width={50} height={30} className="userinqueries--count" animation="wave" variant="text" />
                :
                <span className='userinqueries--count'>
                   {totalQueries} &nbsp;
                  <Tooltip placement="topLeft" title={"The total number of queries represents the count of questions users ask the bot. Billing is based on this criterion."}>
                    Queries
                  </Tooltip>
                </span>
              }
            </div> 
          </div>
      </div>

        <div className="userinqueries__userchat__box">
          <div className="userinqueries__userchat__box--main">

            <div className="userinqueries__userchat__heading">
              <span className='userinqueries__userchat__elem1'>CHAT ID</span>
              <span className='userinqueries__userchat__q__elem2'>Chat Title</span>
              <span className='userinqueries__userchat__q__elem3'>Total Queries</span>
              <span className='userinqueries__userchat__elem4'></span>
            </div>

            <div className="userinqueries__userchat__chat__box">
              <div className="userinqueries__userchat__chat__box--scroll">


                {
                  userChatArr.map((data, i) => (

                    chatList.length ===  0
                    
                    ?

                    <div key={i} className="userinqueries__userchat__chats">
                      <Skeleton height={40} className="user_id--color" animation="wave" variant="text" />
                        
                      <div className="userinqueries__userchat__elem2">
                          <div className='userinqueries__userchat__elem2--question'>
                            <div className='userinqueries__userchat__ques'>
                              <Skeleton height={42} className="" animation="wave" variant="text" />
                            </div>
                            <div className='userinquiries__chat__date__time__loader__box'>
                              <Skeleton height={28} className="userinqueries__userchat__ques--time" animation="wave" variant="text" />
                              <Skeleton height={28} className="userinqueries__userchat__ques--time" animation="wave" variant="text" />
                            </div>
                          </div>
                      </div>

                      <div className="userinqueries__userchat__elem3">
                        <Skeleton height={40} className="" animation="wave" variant="text" />
                      </div>

                      <div className='userinqueries__userchat__elem4'></div>

                    </div>

                    :

                    <div key={i} 
                    // style={(data.chatID) && { display: (data.chatID === Number(inputValue) && inputValue!==-1) ? '' : 'none' }}
                    style={data.chatID && { display: data.chatID.toString().toUpperCase().includes(inputValue.toUpperCase()) ? '' : 'none' }}
                    className="userinqueries__userchat__chats">
                      <span className='user_id--color'>{data.chatID}</span>
                        
                      <div className="userinqueries__userchat__elem2">
                          <div className='userinqueries__userchat__elem2--question'>
                            <div className='userinqueries__userchat__ques'>
                              {data.chatTitle}
                            </div>
                            <span>{data.date}
                            {/* <span className='userinqueries__userchat__ques--time'>{data.date}</span> */}
                            </span>
                          </div>
                      </div>

                      <div className="userinqueries__userchat__elem3">
                        {data.totalQueries}
                      </div>

                      <div className='userinqueries__userchat__elem4'>
                        <button className='no__button expand__chat__btn' onClick={() => navigate(`/userquery/?user=${userID}&chat=${data.chatID}`)}>
                          <IoIosArrowForward size={20}/>
                        </button>
                      </div>

                    </div>
                    
                    
                  ))
                }


              </div>
            </div>

          </div>
        </div>

      </div>
      :
      <div className="userinqueries__main--box">
        Not Valid User
      </div>
      }
    </div>
  )
}

export default UserInqueries