// import React, { useState } from "react";
// import Chart from "react-apexcharts";

// const UserStatGraphPlot = ({labelX, labelY, xAxis, yAxis, title}) => {
//   const [options] = useState({
//     chart: {
//       id: "basic-bar",
//       parentHeightOffset: 0
//     },
//     xaxis: {
//       categories: xAxis,
//       title: {
//         text: labelX
//       }
//     },
//     yaxis: {
//         title: {
//           text: labelY
//         }
//       },
//     title: {
//         // text: title,
//     },
//   });

//   const [series] = useState([
//     {
//       name: "Time Taken",
//       data: yAxis
//     }
//   ]);

//   return (
//           <Chart
//             options={options}
//             series={series}
//             type="bar"
//             width="100%"
//             height="100%"

//           />
//   );
// }

// export default UserStatGraphPlot;

// import React, { useState } from 'react';
// import ReactApexChart from 'react-apexcharts';

// const UserStatGraphPlot = () => {
//   const [chartData, setChartData] = useState({
//     series: [{
//       name: 'PRODUCT A',
//       data: [44, 55, 41, 67, 22, 43]
//     }, {
//       name: 'PRODUCT B',
//       data: [13, 23, 20, 8, 13, 27]
//     }, {
//       name: 'PRODUCT C',
//       data: [11, 17, 15, 15, 21, 14]
//     }, {
//       name: 'PRODUCT D',
//       data: [21, 7, 25, 13, 22, 8]
//     }],
//     options: {
//       chart: {
//         type: 'bar',
//         height: 450,
//         stacked: true,
//         toolbar: {
//           show: true
//         },
//         zoom: {
//           enabled: true
//         }
//       },
//       responsive: [{
//         breakpoint: 480,
//         options: {
//           legend: {
//             position: 'bottom',
//             offsetX: -10,
//             offsetY: 0
//           }
//         }
//       }],
//       // plotOptions: {
//       //   bar: {
//       //     horizontal: false,
//       //     borderRadius: 10,
//       //     borderRadiusApplication: 'end',
//       //     borderRadiusWhenStacked: 'last',
//       //     dataLabels: {
//       //       total: {
//       //         enabled: true,
//       //         style: {
//       //           fontSize: '13px',
//       //           fontWeight: 900
//       //         }
//       //       }
//       //     }
//       //   },
//       // },
//       xaxis: {
//         type: 'datetime',
//         categories: ['01/01/2011 GMT', '01/02/2011 GMT', '01/03/2011 GMT', '01/04/2011 GMT',
//           '01/05/2011 GMT', '01/06/2011 GMT'
//         ],
//       },
//       legend: {
//         position: 'right',
//         offsetY: 40
//       },
//       fill: {
//         opacity: 1
//       }
//     }
//   });

//   return (
//     // <div>
//     //   <div id="chart">
//         <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350} />
//     //   </div>
//     //   <div id="html-dist"></div>
//     // </div>
//   );
// };

// export default UserStatGraphPlot;

// import React, { useState, useEffect } from "react";
// import ReactApexChart from "react-apexcharts";

// function UserStatGraphPlot({chartData, chartLabel}) {
//   console.log("chartData: ", chartData)
//   // const [chartData, setChartData] = useState([
//   //   {
//   //     name: "PRODUCT A",
//   //     data: [44, 55, 41, 67, 22, 43, 21, 49],
//   //   },
//   //   {
//   //     name: "PRODUCT B",
//   //     data: [13, 23, 20, 8, 13, 27, 33, 12],
//   //   },
//   //   {
//   //     name: "PRODUCT C",
//   //     data: [11, 17, 15, 15, 21, 14, 15, 13],
//   //   },
//   // ]);
//   // const [chartLabel, setChartLabel] = useState([
//   //   "2011 Q1",
//   //   "2011 Q2",
//   //   "2011 Q3",
//   //   "2011 Q4",
//   //   "2012 Q1",
//   //   "2012 Q2",
//   //   "2012 Q3",
//   //   "2012 Q4",
//   // ])

//   const data = {
//     series: chartData!==undefined ? chartData : [],
//     // series: [],
//     options: {
//       chart: {
//         type: "bar",
//         height: 450,
//         stacked: true,
//         // stackType: '100%',
//         toolbar: {
//           show: true,
//         },
//         zoom: {
//           enabled: true,
//         },
//       },
//       plotOptions: {
//         bar: {
//           horizontal: false,
//           borderRadius: 10,
//           borderRadiusApplication: "end",
//           borderRadiusWhenStacked: "last",
//           dataLabels: {
//             total: {
//               enabled: true,
//               style: {
//                 fontSize: "13px",
//                 fontWeight: 900,
//               },
//             },
//           },
//         },
//       },
//       responsive: [
//         {
//           breakpoint: 480,
//           options: {
//             legend: {
//               position: "bottom",
//               offsetX: -10,
//               offsetY: 0,
//             },
//           },
//         },
//       ],
//       xaxis: {
//         // categories: [],
//         categories: chartLabel!==undefined ? chartLabel : [],
//       },
//       fill: {
//         opacity: 1,
//       },
//       legend: {
//         position: "right",
//         offsetX: 0,
//         offsetY: 50,
//       },
//     },
//   };

//   return (
//     <div>
//       <div id="chart">
//         <ReactApexChart
//           options={data.options}
//           series={data.series}
//           type="bar"
//           height={450}
//         />
//       </div>
//       <div id="html-dist"></div>
//     </div>
//   );
// }

// export default UserStatGraphPlot;

import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const UserStatGraphPlot = ({ chartData, chartLabel }) => {

  const data = {
    series: chartData === undefined ? [] : chartData,
    // series: chartData===undefined ? [] : [{ name: "Aditi Maini", data: [0, 0] },
    // { name: "Arushi Verma", data: [1, 623] },
    // { name: "Aryan Jain", data: [10, 91] },
    // { name: "Ashish Sharma", data: [30, 0] },
    // { name: "Gremener Team", data: [1, 70] },],
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        // stackType: '100%'
        zoom: {
          enabled: true,
        },
        toolbar: {
          show: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      xaxis: {
        categories: chartLabel === undefined ? [] : chartLabel,
        // categories: chartLabel===undefined ? [] : ["2",
        // "12",],
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "right",
        offsetX: 0,
        offsetY: 50,
      },
    },
  };

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={data.options}
          series={data.series}
          type="bar"
          height={450}
        />
      </div>
    </div>
  );
};

export default UserStatGraphPlot;
