import React, { useState, useEffect } from "react";
import {
  checkingServerStatus,
  startingSession,
  startChat,
  chatResponse,
  olderChats,
  bhasiniTextToEnglishConversionAPI,
  restoreOlderChat,
  bhasiniTextToAudioConversionAPI, bhasiniTextToAudioOnlyConversionAPI
} from "../../API/BackendAPI";
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import TechnicalAdvisor from "../../components/TechnicalAdvisor/TechnicalAdvisor";

const TechnicalAdvisorPage = () => {
  const [sideBarClickedStatus, setSideBarClickedStatus] = useState(true);

  // Here we will track whether the older chats received or not
  const [isOlderChatsReceived, setIsOlderChatsreceived] = useState(null);

  const [messageHistory, setMessageHistory] = useState([]);
  const [messages, setMessages] = useState([]);

  const [choosenLanguage, setChoosenLanguage] = useState("en")
  const [choosenTranslationAPI, setChoosenTranslationAPI] = useState("bh")



  const extractingPdfNameAndLinks = (input_text) => {
      let links_arr = []

      if (input_text.includes("$$$LINK$$$")) {
        const urlRegex = /(https?:\/\/\S+)/g;

        const parts = input_text.split(urlRegex);
        // const new_text = parts[0].replace("$$$LINK$$$", "")
        // user_messages[i]["text"] = new_text.replace("\n", "")


        for (let j = 1; j < parts.length; j++) {

          // Extracting the Pdf name and pdf number
          // const pattern = /\/([^\/]+\.pdf#page=\d+)/;
          
          if (parts[j].includes("https")) {
            const pattern = /https:\/\/storage.googleapis.com\/niti_ayog_bucket_phase1\/(.+)/;
            const match = parts[j].match(pattern);
            if (match) {
              let new_pdf_name_result = match[1]

              if (new_pdf_name_result.includes("#")) {
                new_pdf_name_result = new_pdf_name_result.replace(/#/g, ", ")
              }

              if (new_pdf_name_result.includes("%")) {
                new_pdf_name_result = new_pdf_name_result.replace(/%20/g, "-")
              }

              links_arr.push({ "pdf_name": new_pdf_name_result, "pdf_link": parts[j] })
            }
            else if (parts[j].includes("https")) {
              const pattern = /https:\/\/storage.googleapis.com\/niti-aayog-3000-pdfs-ocr\/(.+)/;
              const match = parts[j].match(pattern);
              if (match) {
                let new_pdf_name_result = match[1]
    
                if (new_pdf_name_result.includes("#")) {
                  new_pdf_name_result = new_pdf_name_result.replace(/#/g, ", ")
                }
    
                if (new_pdf_name_result.includes("%")) {
                  new_pdf_name_result = new_pdf_name_result.replace(/%20/g, "-")
                }
    
                links_arr.push({ "pdf_name": new_pdf_name_result, "pdf_link": parts[j] })
              }
            }
            else{
              links_arr.push({ "pdf_name": parts[j], "pdf_link": parts[j] })
            }

          }
        }

        return links_arr
      }
      return links_arr
  }

  const extractingRequiredText = (input_text) => {
    if(input_text.includes("$$$LINK$$$")){
      // const urlRegex = /(https?:\/\/\S+)/g;
      const parts = input_text.split("\n\n$$$LINK$$$");
      // console.log(parts)
      // console.log(parts[0])
      return parts[0]

      // const new_text = parts[0].replace("\n\n$$$LINK$$$", "")
    }
    return input_text
  }

  // function to make text bold
  const makingTextBold = (input_text) => {
    input_text = input_text.trim()
    let new_textWithBold = ""
    if (input_text !== undefined) {
      // ** Text **
      new_textWithBold = input_text.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");
      // * * Text * *
      new_textWithBold = new_textWithBold.replace(/\* \*(.*?)\* \*/g, "<b>$1</b>");
      // \n- Text.
      // new_textWithBold = new_textWithBold.replace(/\n- (.*?)\./g, "\n&bull;&ensp;$1.");
      // \n- Text:
      // new_textWithBold = new_textWithBold.replace(/\n- (.*?)\:/g, "\n&bull;&ensp;$1:");
    

    // Adding bullet points
    if(new_textWithBold.includes("-")){
      // \n followed by any spaces *
      // new_textWithBold = new_textWithBold.replace(/(?<=\n\s*)-/g, "&bull;&ensp;") 

      // regular expression  with any amount of white space in between. 
      // match function calculates the number of spaces in the matched string & replaces it with the same number of non-breaking spaces
      new_textWithBold = new_textWithBold.replace(/\n\s*- /g, match => `\n${'&nbsp;'.repeat(match.length - 2)}&bull;&ensp;`) 
      // &emsp; - Four spaces
      // &ensp; - Two Spaces
    }
    }
    return new_textWithBold
  }



  // On Loading the page
  useEffect(() => {
    const onPageLoad = async () => {
      // Checking Status of Older Chat Received or not (For spinner)
      setIsOlderChatsreceived(false);

      const res = await checkingServerStatus();
      // console.log(res.message)
      if (res.status) {
        // If user not started new chat
        if (sessionStorage.getItem("new_chat") == null) {
          // Correct it...
          const start_session = await startingSession();
          if (start_session.status) {
            console.log(
              start_session.message.response,
              "for",
              start_session.message.user_id
            );

            // Older Chats
            const olderChatsData = await olderChats();
            //  console.log(olderChatsData)
            if (olderChatsData.status) {
              // Checking Status of Older Chat Received or not...
              setIsOlderChatsreceived(true);
              const older_chat_data = olderChatsData.message.doc_list;
              // Arranging in descending order
              let sortedOlderChats = older_chat_data.sort((r1, r2) =>
                r1.ucid > r2.ucid ? -1 : r1.ucid < r2.ucid ? 1 : 0
              );
              // console.log(sortedOlderChats);
              // export sortedOlderChats

              const dateWise__sorted_arr =arrangingOlderChats_dateWise(sortedOlderChats);
              // console.log(dateWise__sorted_arr);

              setMessageHistory(dateWise__sorted_arr);
            }
          } 
          else {
          }
        } 
        else {
          if (sessionStorage.getItem("chat_id") != null) {
            // Calling the function for restoring Older Chat
            const responseChats = await restoringChats(Number(sessionStorage.getItem("chat_id")))
            setMessages(responseChats);

            // const res = await restoreOlderChat(Number(sessionStorage.getItem("chat_id")));
            // console.log(res)
            // if (res.status) {
            //   const received_chat_history = res.message.message_history;
            //   let chat_history = [];

            //   received_chat_history.map((data) => {
            //     let new_textWithBold = ""
                
            //     // Extracting Pdf name and links
            //     if(data["author"] === "bot"){
            //       let links_arr = extractingPdfNameAndLinks(data["content"])
            //       data["links_arr"] = links_arr
            //     }

            //     // Extracting the required text and making text bold
            //     if(data.hasOwnProperty("translated_content") && data["translated_content"] !=="None"){
            //       let text = ""
            //       if(data["author"] === "bot"){
            //         text = extractingRequiredText(data["translated_content"])
            //       }
            //       else{
            //         text = data["translated_content"]
            //       }
            //       // making Text Bold
            //       new_textWithBold = makingTextBold(text)
            //     }
            //     else{
            //       let text = ""
            //       if(data["author"] === "bot"){
            //         text = extractingRequiredText(data["content"])
            //       }
            //       else{
            //         text = data["content"]
            //       }
            //       // making Text Bold
            //       new_textWithBold = makingTextBold(text)
            //     }
            //     data["new_textWithBold"] = new_textWithBold
                

            //     return chat_history.push(data);
            //   });
            //   // console.log(chat_history)
            //   setMessages(chat_history);
            // }
          }

          // Older Chats
          const olderChatsData = await olderChats();
          // console.log(olderChatsData)
          if (olderChatsData.status) {
            // Checking Status of Older Chat Received or not...
            setIsOlderChatsreceived(true);
            const older_chat_data = olderChatsData.message.doc_list;
            // Arranging in descending order
            let sortedOlderChats = older_chat_data.sort((r1, r2) =>
              r1.ucid > r2.ucid ? -1 : r1.ucid < r2.ucid ? 1 : 0
            );

            const dateWise__sorted_arr = arrangingOlderChats_dateWise(sortedOlderChats);
            // console.log(dateWise__sorted_arr);

            setMessageHistory(dateWise__sorted_arr);
          }
        }
      } else {
        console.log("Server is not running.");
      }
    };

    onPageLoad();
  }, []);

  const arrangingOlderChats_dateWise = (chats_arr) => {
    const x = chats_arr;

    const current_date = new Date();
    const current_month = current_date.getMonth() + 1;
    const current_day = current_date.getDate();

    

    // const month = [  'January',  'February',  'March',  'April',  'May',  'June',  'July',  'August',  'September',  'October',  'November',  'December'];
    const month = ['April', 'March', 'February', 'January', 'December', 'November',  'October', 'September', 'August', 'July', 'June', 'May'];

    let new_arr = month.map((val) => [val, []]);
    new_arr.unshift(["Today", []]);
    // console.log(new_arr)

    for (let i = 0; i < x.length; i++) {
      // console.log(x[i])
      const r_date = x[i]["date"];

      const r_date_arr = r_date.split("/");
      const r_day = Number(r_date_arr[0]);
      const r_month = Number(r_date_arr[1]);


      // january at starting
      if (r_day === current_day && r_month === current_month) {
        new_arr[0][1].push(x[i]);

      } else if (r_month === 1) {
        new_arr[4][1].push(x[i]);
      } else if (r_month === 2) {
        new_arr[3][1].push(x[i]);
      } else if (r_month === 3) {
        new_arr[2][1].push(x[i]);
      } else if (r_month === 4) {
        new_arr[1][1].push(x[i]);

      } else if (r_month === 5) {
        new_arr[12][1].push(x[i]);
      } else if (r_month === 6) {
        new_arr[11][1].push(x[i]);
      } else if (r_month === 7) {
        new_arr[10][1].push(x[i]);
      } else if (r_month === 8) {
        new_arr[9][1].push(x[i]);
      } else if (r_month === 9) {
        new_arr[8][1].push(x[i]);
      } else if (r_month === 10) {
        new_arr[7][1].push(x[i]);

      } else if (r_month === 11) {
        new_arr[6][1].push(x[i]);
      } else if (r_month === 12) {
        new_arr[5][1].push(x[i]);
      }

    }
    // console.log(x)
    // console.log(JSON.stringify(new_arr));


    return new_arr;
  };



  const arrangingOlderChats_dateWise__prev = (chats_arr) => {
    const x = chats_arr;

    const current_date = new Date();
    const current_month = current_date.getMonth() + 1;
    const current_day = current_date.getDate();

    

    const month = [  'January',  'February',  'March',  'April',  'May',  'June',  'July',  'August',  'September',  'October',  'November',  'December'];

    let new_arr = month.map((val) => [val, []]);
    new_arr.unshift(["Today", []]);
    // console.log(new_arr)

    for (let i = 0; i < x.length; i++) {
      // console.log(x[i])
      const r_date = x[i]["date"];

      const r_date_arr = r_date.split("/");
      const r_day = Number(r_date_arr[0]);
      const r_month = Number(r_date_arr[1]);


      // january at starting
      if (r_day === current_day && r_month === current_month) {
        new_arr[0][1].push(x[i]);
      } else if (r_month === 1) {
        new_arr[1][1].push(x[i]);
      } else if (r_month === 2) {
        new_arr[2][1].push(x[i]);
      } else if (r_month === 3) {
        new_arr[3][1].push(x[i]);
      } else if (r_month === 4) {
        new_arr[4][1].push(x[i]);
      } else if (r_month === 5) {
        new_arr[5][1].push(x[i]);
      } else if (r_month === 6) {
        new_arr[6][1].push(x[i]);
      } else if (r_month === 7) {
        new_arr[7][1].push(x[i]);
      } else if (r_month === 8) {
        new_arr[8][1].push(x[i]);
      } else if (r_month === 9) {
        new_arr[9][1].push(x[i]);
      } else if (r_month === 10) {
        new_arr[10][1].push(x[i]);
      } else if (r_month === 11) {
        new_arr[11][1].push(x[i]);
      } else if (r_month === 12) {
        new_arr[12][1].push(x[i]);
      }

    }
    // console.log(x)
    // console.log(JSON.stringify(new_arr));


    return new_arr;
  };

  const sideBarStatusClickHandler = (sideBar) => {
    // console.log(sideBar);
    setSideBarClickedStatus(sideBar);
  };

  const [chatIsRunning, setChatIsRunning] = useState(false);

  // Older Chat Clicked tracking for keeping the page at top
  const [isOlderChatClicked, setIsOlderChatClicked] = useState(false);
  // Here we will store whether we received chat response or not
  const [chatResponseIsLoading, setChatResponseIsLoading] = useState(null);
  
  // Positive Feedback Array Resetter
  const [resetPositiveFeedbackArray, setResetPositiveFeedbackArray] = useState(false)

  // Negative Feedback Array Resetter
  const [resetNegativeFeedbackArray, setResetNegativeFeedbackArray] = useState(false)


  // Other Chat Clicked Status for resetting like, dislike array & resetting input value
  const [otherChatClickedStatus, setOtherChatClickedStatus] = useState(false)

  

  // Language Handler
  const currentlySelectedLanguageHandler = (received_lang) => {
    setChoosenLanguage(received_lang)
    // console.log(choosenLanguage)
  }
  
  console.log("choosenLanguage: ", choosenLanguage)

  

  // With this we will shw audio status and will apply loader functionality
  const [audioResponseStatus, setAudioResponseStatus] = useState(false)

  // Handling Send Button Click
  const handleSend = async (input) => {
    // When user is chatting...... then scroll
    setIsOlderChatClicked(false);

    // Chat running status
    setChatIsRunning(true);

    // Chat Response is Loading...
    setChatResponseIsLoading(true);

    const text = input.trim()
    // let text = input;

    let unique_chat_id = "";
    if (!sessionStorage.getItem("new_chat")) {
      // Due to it when new chat created then edit & delete buttn will shown
      // isFirstNewChatCreated(true)
      // firstNewChatStatus(firstNewChatCreated)

      const newChat = await startChat();
      if (newChat.status) {
        sessionStorage.setItem("new_chat", true);
        sessionStorage.setItem("chat_id", newChat.message.ucid);
        unique_chat_id = newChat.message.ucid;
      }
    } 
    else {
      unique_chat_id = sessionStorage.getItem("chat_id");
    }
    

    setMessages([
      ...messages,
      { content: text, author: "user", source: "None" },
    ]);

    
    let new_textWithBold = ""
    try{
      // Refactoring Selected Service
      let selected_service = selectedService
      if(selected_service === null){
        selected_service = "palm"
      }

      // Calling Response API
      const resData = await chatResponse(text, unique_chat_id, choosenLanguage, choosenTranslationAPI, selected_service);
      if (resData.status) {
        let links_arr = []
        
        // extracting Pdf names and links
        const responseLinksArr = extractingPdfNameAndLinks(resData.message[resData.message.length - 1]["content"])
        links_arr = responseLinksArr
  
  
        // Function (for extracting required text)
        if ( resData.message[resData.message.length - 1].hasOwnProperty("translated_content") && resData.message[resData.message.length - 1]["translated_content"] !=="None") {
            // Function (for extracting required text)
            new_textWithBold = extractingRequiredText(resData.message[resData.message.length - 1]["translated_content"]);
            // Making text bold
            new_textWithBold = makingTextBold(new_textWithBold)
        }
        else{
            // Function (for extracting required text)
            new_textWithBold = extractingRequiredText(resData.message[resData.message.length - 1]["content"]);
            // Making text bold
            new_textWithBold = makingTextBold(new_textWithBold)
        }
  
        //  We received chat response successfully
        setChatResponseIsLoading(false);
        let last_response = resData.message[resData.message.length - 1];
        last_response["new_textWithBold"] = new_textWithBold
        last_response["links_arr"] = links_arr
        last_response["audio"] = "loading"
  
        setMessages([
          ...messages,
          { content: text, author: "user", source: "None" },
          last_response
        ]);
  
        // Loading Audio
        setAudioResponseStatus(true)
      }
      else{
        // api is not giving response so we stop the loading symbol
        setChatResponseIsLoading(false);
        setMessages([...messages, { content: text, author: "user", source: "None" }, {content: "Something went wrong.", author: "bot"}])
      }
      
    }
    catch(err){
      console.log("Error in response api: ", err)
      // api is not giving response so we stop the loading symbol
      setChatResponseIsLoading(false);
      setMessages([...messages, { content: text, author: "user", source: "None" }, {content: "Something went wrong.", author: "bot"}])
    }


    

    // Older Chats (So when User started new chat it automatically shown in chat history i.e in SideBar)
    try{
      const olderChatsData = await olderChats();
      if (olderChatsData.status) {
        const older_chat_data = olderChatsData.message.doc_list;
        // Arranging in descending order
        let sortedOlderChats = older_chat_data.sort((r1, r2) =>
          r1.ucid > r2.ucid ? -1 : r1.ucid < r2.ucid ? 1 : 0
        );
        //  console.log(sortedOlderChats)
        const res_arr = arrangingOlderChats_dateWise(sortedOlderChats);
        setMessageHistory(res_arr);
      }
    }
    catch(err){
      console.log("Error in older chats: ", err)
    }

    // Text To Audio
    try{
      const text_response = new_textWithBold
      const audio_response = await textToAudio(text_response, choosenLanguage)
      if (audio_response !== "None"){
        // Updating the last element of an array
        setMessages((prevArray) => {
          const newArray = [...prevArray];
          let last_response = newArray[newArray.length-1]
          last_response["audio"] = audio_response
          return newArray;
        });
      }
      // Changine Audio Response State
      setAudioResponseStatus(false)
    }
    catch(err){
      console.log("Error: ", err)
      // Changine Audio Response State
      setAudioResponseStatus(false)
    }
  };

  // Text To Audio Function
  const textToAudio = async (text, target_language) => {
    try{
      let audio = ""
      let formattedInputText = text
      formattedInputText = formattedInputText.replace(/&bull;&ensp;/g, "")
      formattedInputText = formattedInputText.replace(/&nbsp;/g, "")
      formattedInputText = formattedInputText.replace(/\n/g, "")

      const resData = await bhasiniTextToAudioOnlyConversionAPI(formattedInputText, "base64", target_language);
      if (resData.status) {
        if (resData["message"]["data"]["pipelineResponse"][0]["audio"][0]["audioContent"] !== null) {
          const received_base64_audio = resData["message"]["data"]["pipelineResponse"][0]["audio"][0]["audioContent"];
          const decodedData = atob(received_base64_audio);
          // Convert binary data to Blob
          const binaryData = new Uint8Array(decodedData.length);
          for (let i = 0; i < decodedData.length; i++) {
            binaryData[i] = decodedData.charCodeAt(i);
          }
          const blob = new Blob([binaryData], { type: "audio/mp3" });

          // Create object URL from Blob
          audio = URL.createObjectURL(blob);
          return audio
        }
        return "None"
      }
      return "None"
    }
    catch(err){
      console.log("Error in audio function: ", err)
      return "None"
    }
  }


  // Handle New Chat Click Handler
  const newChatClickHandler = async () => {
    // Current chat is running or not status
    setChatIsRunning(true)

    // console.log("New Chat")
    if (sessionStorage.getItem("new_chat") != null) {
      sessionStorage.removeItem("chat_id");
      sessionStorage.removeItem("new_chat");
    }
    setMessages([])
  }


  // Handle data on Clicking Link
  const oldMessagesClickHandler = async (chat_id) => {
    // Other Chat Clicked Status for resetting like, dislike array & resetting input value
    setOtherChatClickedStatus(true)

    // When user clicked older chat then do not scroll
    setIsOlderChatClicked(true)

    // When User clicked older chats then Reset the useState of "Positive Feedback Message Array"
    setResetPositiveFeedbackArray(true)

    // When User clicked older chats then Reset the useState of "Negative Feedback Message Array"
    setResetNegativeFeedbackArray(true)


    // console.log("Current Chat", chat_id)
    if (sessionStorage.getItem("new_chat") == null) {
      sessionStorage.setItem("new_chat", true)
    }
    if (sessionStorage.getItem("chat_id") != null) {
      sessionStorage.removeItem("chat_id");
      // Setting New Chat ID
      sessionStorage.setItem("chat_id", chat_id)
    }
    else {
      sessionStorage.setItem("chat_id", chat_id)
    }

    // Restoring Older Chat
    // const res = await restoreOlderChat(chat_id)
    // if (res.status) {
    //   const received_chat_history = res.message.message_history
    //   // console.log(received_chat_history)
    //   var chat_history = []
    //   received_chat_history.map((data) => {
    //     return chat_history.push(data)
    //   })
    //   // console.log(chat_history)
    //   setMessages(chat_history)
    // }

    // Calling the function for restoring Older Chat
    const responseChats = await restoringChats(chat_id)
    setMessages(responseChats);



  }


  // Restoring the chat
  const restoringChats = async (chat_id) => {
    const res = await restoreOlderChat(chat_id);
      // console.log(res)
      if (res.status) {
              const received_chat_history = res.message.message_history;
              let chat_history = [];

              received_chat_history.map((data) => {
                let new_textWithBold = ""
                
                // Extracting Pdf name and links
                if(data["author"] === "bot"){
                  let links_arr = extractingPdfNameAndLinks(data["content"])
                  data["links_arr"] = links_arr
                }

                // Extracting the required text and making text bold
                if(data.hasOwnProperty("translated_content") && data["translated_content"] !=="None"){
                  let text = ""
                  if(data["author"] === "bot"){
                    text = extractingRequiredText(data["translated_content"])
                  }
                  else{
                    text = data["translated_content"]
                  }
                  // making Text Bold
                  new_textWithBold = makingTextBold(text)
                }
                else{
                  let text = ""
                  if(data["author"] === "bot"){
                    text = extractingRequiredText(data["content"])
                  }
                  else{
                    text = data["content"]
                  }
                  // making Text Bold
                  new_textWithBold = makingTextBold(text)
                }
                data["new_textWithBold"] = new_textWithBold
                
                
                return chat_history.push(data);
              });
              // console.log(chat_history)
              return chat_history
              
      }
  }



  // Translation API Handler
  const currentlySelectedTranslationAPIHandler = (received_translation_api) => {
    setChoosenTranslationAPI(received_translation_api)
  }

  // Reload New updated Chats when user edits chat title
  const reloadNewChatOnTitleUpdationHandler = async (status) => {
    // console.log(status)

    if (status) {
      // Older Chats
      const olderChatsData = await olderChats()
      //  console.log(olderChatsData)
      if (olderChatsData.status) {
        // Checking Status of Older Chat Received or not...
        setIsOlderChatsreceived(true)

        // console.log(olderChatsData.message)
        // console.log(olderChatsData.message.doc_list)
        const older_chat_data = olderChatsData.message.doc_list
        //  console.log(older_chat_data)
        // Arranging in descending order
        let sortedOlderChats = older_chat_data.sort((r1, r2) => (r1.ucid > r2.ucid) ? -1 : (r1.ucid < r2.ucid) ? 1 : 0);
        //  console.log(sortedOlderChats)
        const dateWise__sorted_arr = arrangingOlderChats_dateWise(sortedOlderChats);
        // export sortedOlderChats
        setMessageHistory(dateWise__sorted_arr)
        //  console.log("entered True")
      }
    }
  }

  // State for Dislike Response Modal onClick
  const [deletePageModalStatus, setDeletePageModalStatus] = useState(false)


  const [deleteChatID, setDeleteChatID] = useState(0)
  const [deleteChatTitle, setDeleteChatTitle] = useState("")

  const chatIDToDeleteHandler = (chat_id) => {
    setDeleteChatID(Number(chat_id))
  }

  const chatTitleToDeleteHandler = (chat_title) => {
    setDeleteChatTitle(chat_title)
  }

  // Selected Service ----------------------
  const [selectedService, setSelectedService] = useState(null)
  const selectedServiceHandler = (user_selection) => {
    setSelectedService(user_selection)
  }



  return (
    <div className={sideBarClickedStatus ? "technicaladvisor__page--activeNavbar" : "technicaladvisor__page"}>

      {sideBarClickedStatus && 
      <Sidebar oldMessages={messageHistory} onNewChatClick={newChatClickHandler}  chatResponseStatus={chatResponseIsLoading}
        oldMessagesClick={oldMessagesClickHandler} reloadNewChatOnTitleUpdation={reloadNewChatOnTitleUpdationHandler}
        olderChatsReceivedStatus={isOlderChatsReceived} deleteChatModalController={setDeletePageModalStatus} 
        chatIDToDelete={chatIDToDeleteHandler} chatTitleToDelete={chatTitleToDeleteHandler}/>}

      <div className={ sideBarClickedStatus ? "technicaladvisor__page__main__box--activeNavbar" : "technicaladvisor__page__main__box"}>
        <div className="technical__advisor">
          <Navbar sideBarStatus={sideBarClickedStatus} sideBarStatusClick={sideBarStatusClickHandler} 
           currentlySelectedLanguage={currentlySelectedLanguageHandler} currentLanguage={choosenLanguage}
           currentlySelectedTranslationAPI={currentlySelectedTranslationAPIHandler} currentTranslationAPI={choosenTranslationAPI}
           currentSelectedService={selectedService} handleSelectedService={selectedServiceHandler}/>

          <TechnicalAdvisor user_messages={messages} onButtonClick={handleSend} olderChatClickedStatus={isOlderChatClicked}
            chatResponseStatus={chatResponseIsLoading} currentLanguage={choosenLanguage} currentTranslationAPI={choosenTranslationAPI}
            otherChatClickedStatus={otherChatClickedStatus} changeOtherChatClickedStatus={setOtherChatClickedStatus}
            deletePageModalStatus={deletePageModalStatus} deleteChatModalController={setDeletePageModalStatus}
            deleteChatID={deleteChatID} deleteChatTitle={deleteChatTitle} audioResponseStatus={audioResponseStatus}/>
        </div>
      </div>
      
    </div>
  );
};

export default TechnicalAdvisorPage;
