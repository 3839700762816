import React from 'react'

import { deleteTitleApi } from '../../API/BackendAPI'
import crossIcon from "../svg-store/likeresponse/cross.svg"
import deleteIcon from "../svg-store/deletemessage/delete.svg"

const DeleteMessage = ({deleteChatModalController, deleteChatTitle, deleteChatID}) => {

    const deleteBtnHandler = async() => {
        // console.log("delete", deleteChatID, deleteChatTitle)
        const res = await deleteTitleApi(deleteChatID)
        if(res.status){
            sessionStorage.removeItem("new_chat")
            sessionStorage.removeItem("chat_id")
            deleteChatModalController(false)
            window.location.reload(true)
        }
    }

  return (
    <div className='delete__message__container'>
      <div className="delete__message__background">
        <div className="delete__message__box">
            <div className="close__delete__modal">
              <button onClick={() => deleteChatModalController(false)} className='no__button'>
                <img src={crossIcon} alt="delete" />
              </button>
            </div>
            <img src={deleteIcon} alt="delete" />
            
            <div className='delete__message__info'>
              <span className='delete__text__msg'>Delete Chat</span>
                <span>This will delete
                    <span> 
                        "{deleteChatTitle}"
                    </span>
                </span>
            </div>
            <div className='delete__message__buttons'>
                <button onClick={() => deleteChatModalController(false)}>Cancel</button>
                <button onClick={deleteBtnHandler}>Delete</button>
            </div>
            
        </div>
      </div>
    </div>
  )
}

export default DeleteMessage