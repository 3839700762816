import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {token as user_token} from "../../components/utility/UserToken"

export const evalMatricOverviewAPI = createAsyncThunk('evalmatric/fetch', async (params) => {
    let token = ""
    if (localStorage.getItem("auth_token") != null) {
        token = localStorage.getItem("auth_token")
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const raw = JSON.stringify({
    "year": params.year,
    "project_name": params.project_name
    });

    const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
    };

    const res = await fetch(`${process.env.REACT_APP_REAL_TIME_METRICS_URL}/eval_overview`, requestOptions);
    const data = await res.json();
    return data;
})

export const evalMatricFetchDataAPI = createAsyncThunk('evalmatric/fetch/data', async (params) => {
    let token = ""
    if (localStorage.getItem("auth_token") != null) {
        token = localStorage.getItem("auth_token")
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const raw = JSON.stringify({
    "project_name": params.project_name,
    "week": params.week
    });

    const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
    };

    const res = await fetch(`${process.env.REACT_APP_REAL_TIME_METRICS_URL}/fetch_data`, requestOptions);
    const data = await res.json();
    return data;
})

export const evalMatricFilterDataAPI = createAsyncThunk('evalmatric/filter/data', async (params) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${user_token}`)

    const raw = JSON.stringify({
    "project_name": params.project_name,
    "start_date": params.startDate,
    "end_date": params.endDate
    });

    const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
    };

    const res = await fetch(`${process.env.REACT_APP_REAL_TIME_METRICS_URL}/fetch_data`, requestOptions)
    const data = await res.json()
    return data
})

const realTimeMetricsPageSlice = createSlice({
    name: "realTimeMetrics",
    initialState: {
        realTimeMetricsSidebarStatus: false,

        evalOverviewStatus: 'idle',
        sidebarIsLoading: true,
        sideBarData: [],
        tableData: [],
        selectedProject: "niti",
        currentSelectedEvalData: "",
        currentSelectedWeek: undefined,

        evalFetchDataStatus: 'idle',
        tableDataLoading: undefined,
        evalFilterDataStatus: 'idle',

        tablePopUPStatus: false        
    },
    reducers: {
        setRealTimeMetricsSidebarStatus: (state, action) => {
            state.realTimeMetricsSidebarStatus = action.payload
        },
        setSelectedProject:(state, action)=>{
            state.selectedProject = action.payload
        },
        setCurrentSelectedWeek: (state, action) => {
            state.currentSelectedWeek = action.payload
        },
        setTablePopUPStatus: (state, action) => {
            state.tablePopUPStatus = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            // Eval Matrix API state
            .addCase(evalMatricOverviewAPI.pending, (state) => {
                state.evalOverviewStatus = 'loading'
                state.sidebarIsLoading = true
                state.sideBarData = []
                state.currentSelectedEvalData = ""
                state.tableDataLoading = true
            })
            .addCase(evalMatricOverviewAPI.fulfilled, (state, action) => {
                state.evalOverviewStatus = 'succeeded'
                state.sidebarIsLoading = false
                if(action.payload.data){
                    state.sideBarData = action.payload.data
                }
                if(action.payload.latest_result){
                    state.tableData = action.payload.latest_result
                
                    let month_week = ""
                    if(action.payload.latest_result.length>0){
                        month_week = action.payload.latest_result[0]["eval_date"] && action.payload.latest_result[0]["eval_date"]
                        month_week = month_week.replace("24_", "-")
                        state.currentSelectedEvalData = month_week
                    }
                    else{
                        state.currentSelectedEvalData = ""
                    }
                
                    state.tableDataLoading = false
                    if(action.payload.latest_result.length>0){
                        state.currentSelectedWeek = action.payload.latest_result[0]["eval_date"] && action.payload.latest_result[0]["eval_date"]
                    }
                    else{
                        state.currentSelectedWeek= undefined
                    }
                }
            })
            .addCase(evalMatricOverviewAPI.rejected, (state) => {
                state.evalOverviewStatus = 'failed'
                state.sidebarIsLoading = null
                state.sideBarData = []
                state.currentSelectedEvalData = ""
                state.tableDataLoading = null
            })

            // Fetch Data API state
            .addCase(evalMatricFetchDataAPI.pending, (state) => {
                state.evalFetchDataStatus = 'loading'
                state.tableDataLoading = true
            })
            .addCase(evalMatricFetchDataAPI.fulfilled, (state, action) => {
                state.evalOverviewStatus = 'succeeded'
                if(action.payload){
                    state.tableData = action.payload
                }
                state.tableDataLoading = false
            })
            .addCase(evalMatricFetchDataAPI.rejected, (state) => {
                state.evalFetchDataStatus = 'failed'
                state.tableDataLoading = null
            })

            // Fetch Data (Filter state)
            .addCase(evalMatricFilterDataAPI.pending, (state) => {
                state.evalFilterDataStatus = 'loading'
                state.tableDataLoading = true
            })
            .addCase(evalMatricFilterDataAPI.fulfilled, (state, action) => {
                state.evalFilterDataStatus = 'succeeded'
                if(action.payload){
                    state.tableData = action.payload
                }
                state.tableDataLoading = false
            })
            .addCase(evalMatricFilterDataAPI.rejected, (state) => {
                state.evalFilterDataStatus = 'failed'
                state.tableDataLoading = null
            })
    }
})

export const {setSelectedProject, setCurrentSelectedWeek, setTablePopUPStatus, setRealTimeMetricsSidebarStatus} = realTimeMetricsPageSlice.actions

export default realTimeMetricsPageSlice.reducer