// Checking Server Status on Loading Page
const checkingServerStatus = async () => {
  try {
    const response = await fetch(process.env.REACT_APP_MODEL_URL)
    if (response.status === 200) {
      return { "message": "Server is running.", "status": true }
    }
    else {
      return { "message": "Server is not running.", "status": false }
    }
  }
  catch (error) {
    return { "message": error.message, "status": false }
  }
}


// Starting Session
const startingSession = async (user_id) => {
  let token1 = ""
  if (localStorage.getItem("auth_token") != null) {
    token1 = localStorage.getItem("auth_token")
  }
  // console.log("Token1: ", token1)
  try {
    // const response = await fetch(`http://35.200.161.230:5002/start_session/?user_id=${user_id}`)
    // console.log("Token1 inside: ", token1)

    const response = await fetch(`${process.env.REACT_APP_MODEL_URL}/start_session/`, {
      credentials: "include",
      method: 'GET',
      headers: { Authorization: `Bearer ${token1}` }
    })
    const data = await response.json()
    // console.log(response)
    if (response.ok) {
      return { "message": data, "status": true }

    }
    else {
      return { "message": "Something went wrong", "status": true }
    }


  }
  catch (error) {
    // return {"message": error.message, "status": false, "cookie": cookieValue}
    return { "message": error.message, "status": false }
  }
}

// New Chat
const startChat = async () => {
  let token2 = ""
  if (localStorage.getItem("auth_token") != null) {
    token2 = localStorage.getItem("auth_token")
  }
  // console.log("Token2: ", token2)
  try {
    const response = await fetch(`${process.env.REACT_APP_MODEL_URL}/new_chat/`, {
      credentials: "include",
      method: 'GET',
      headers: { Authorization: `Bearer ${token2}` }
    });
    const data = await response.json();
    if (response.ok) {
      return { "message": data, "status": true }
    }
    else {
      return { "message": "Something went wrong.", "status": false }
    }

  }
  catch (error) {
    // console.error('Error:', error.message);
    return { "message": error.message, "status": false }
  }
}

 // Get Chat Response
 const chatResponse = async (text, ucid, source_lang, translationAPI, selected_service) => {
  let token3 = ""
  if (localStorage.getItem("auth_token") != null) {
    token3 = localStorage.getItem("auth_token")
  }
  // console.log("Token3: ", token3)
  try {
    const response = await fetch(`${process.env.REACT_APP_MODEL_URL}/get_response/?user_input=${text}&ucid=${ucid}&language=${source_lang}&api=${translationAPI}&service=${selected_service}`, {
      credentials: "include",
      method: 'GET',
      headers: { Authorization: `Bearer ${token3}` }
    })
    const data = await response.json()
    // console.log(data.headers)

    return { "message": data.message_history, "status": true }
  }
  catch (error) {
    return { "message": "Something went wrong.", "status": true }
  }
  // const res = await sendMsgToOpenAI(text)
  // return res
}

// Get All Older Chats
const olderChats = async () => {
  let token4 = ""
  if (localStorage.getItem("auth_token") != null) {
    token4 = localStorage.getItem("auth_token")
  }
  // console.log("Token4: ", token4)
  try {
    const response = await fetch(`${process.env.REACT_APP_MODEL_URL}/get_chats/`, {
      credentials: "include",
      method: 'GET',
      headers: { Authorization: `Bearer ${token4}` }
    })
    const data = await response.json()
    if (response.ok) {
      return { "message": data, "status": true }
    }
    else {
      return { "message": "Something went wrong.", "status": false }
    }
  }
  catch (error) {
    return { "message": error, "status": false }
  }
}

// Restore Older Chat
const restoreOlderChat = async (chat_id) => {
  let token5 = ""
  if (localStorage.getItem("auth_token") != null) {
    token5 = localStorage.getItem("auth_token")
  }
  // console.log("Token5: ", token5)
  try {
    const response = await fetch(`${process.env.REACT_APP_MODEL_URL}/restore_chat/?ucid=${chat_id}`, {
      credentials: "include",
      method: 'GET',
      headers: { Authorization: `Bearer ${token5}` }
    })
    const data = await response.json()
    // console.log(data)
    if (response.ok) {
      return { "message": data, "status": true }
    }
    else {
      return { "message": "Something went wrong.", "status": false }
    }
  }
  catch (error) {
    return { "message": error, "status": false }
  }
}

// Like Response
const likeResponse = async (chat_id, msg_id) => {
  let token = ""
  if (localStorage.getItem("auth_token") != null) {
    token = localStorage.getItem("auth_token")
  }
  // console.log("Token5: ", token5)
  try {
    const response = await fetch(`${process.env.REACT_APP_MODEL_URL}/like_response/?ucid=${chat_id}&msg_id=${msg_id}`, {
      credentials: "include",
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` }
    })
    const data = await response.json()
    // console.log(response)
    // console.log(data)
    if (response.ok) {
      return { "message": data, "status": true }
    }
    else {
      return { "message": "Something went wrong.", "status": false }
    }
  }
  catch (error) {
    return { "message": error, "status": false }
  }
}

// DisLike Response
const dislikeResponse = async (chat_id, msg_id) => {
  let token = ""
  if (localStorage.getItem("auth_token") != null) {
    token = localStorage.getItem("auth_token")
  }
  // console.log("Token5: ", token5)
  try {
    const response = await fetch(`${process.env.REACT_APP_MODEL_URL}/dislike_response/?ucid=${chat_id}&msg_id=${msg_id}`, {
      credentials: "include",
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` }
    })
    const data = await response.json()
    // console.log(response)
    // console.log(data)
    if (response.ok) {
      return { "message": data, "status": true }
    }
    else {
      return { "message": "Something went wrong.", "status": false }
    }
  }
  catch (error) {
    return { "message": error, "status": false }
  }
}


// Comment
const comment = async (chat_id, msg_id, commentText) => {
  let token = ""
  if (localStorage.getItem("auth_token") != null) {
    token = localStorage.getItem("auth_token")
  }
  // console.log("Token5: ", token5)
  try {
    const response = await fetch(`${process.env.REACT_APP_MODEL_URL}/comment/?ucid=${chat_id}&msg_id=${msg_id}&comment=${commentText}`, {
      credentials: "include",
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` }
    })
    const data = await response.json()
    // console.log(response)
    // console.log(data)
    if (response.ok) {
      return { "message": data, "status": true }
    }
    else {
      return { "message": "Something went wrong.", "status": false }
    }
  }
  catch (error) {
    return { "message": error, "status": false }
  }
}

// Suggestions
const suggestion = async (chat_id, msg_id, suggestedText) => {
  let token = ""
  if (localStorage.getItem("auth_token") != null) {
    token = localStorage.getItem("auth_token")
  }
  try {
    const response = await fetch(`${process.env.REACT_APP_MODEL_URL}/suggested_response/?ucid=${chat_id}&msg_id=${msg_id}&suggested_response=${suggestedText}`, {
      credentials: "include",
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` }
    })
    const data = await response.json()
    // console.log(response)
    // console.log(data)
    if (response.ok) {
      return { "message": data, "status": true }
    }
    else {
      return { "message": "Something went wrong.", "status": false }
    }
  }
  catch (error) {
    return { "message": error, "status": false }
  }
}

// Edit Title
const editTitleApi = async (chat_id, newTitle) => {
  let token = ""
  if (localStorage.getItem("auth_token") != null) {
    token = localStorage.getItem("auth_token")
  }
  // console.log("Token5: ", token5)
  try {
    const response = await fetch(`${process.env.REACT_APP_MODEL_URL}/edit_title/?ucid=${chat_id}&title=${newTitle}`, {
      credentials: "include",
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` }
    })
    const data = await response.json()
    // console.log(response)
    // console.log(data)
    if (response.ok) {
      return { "message": data, "status": true }
    }
    else {
      return { "message": "Something went wrong.", "status": false }
    }
  }
  catch (error) {
    return { "message": error, "status": false }
  }
}

// Delete Title
const deleteTitleApi = async (chat_id) => {
  let token = ""
  if (localStorage.getItem("auth_token") != null) {
    token = localStorage.getItem("auth_token")
  }
  // console.log("Token5: ", token5)
  try {
    const response = await fetch(`${process.env.REACT_APP_MODEL_URL}/delete_chat/?ucid=${chat_id}`, {
      credentials: "include",
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` }
    })
    const data = await response.json()
    // console.log(response)
    // console.log(data)
    if (response.ok) {
      return { "message": data, "status": true }
    }
    else {
      return { "message": "Something went wrong.", "status": false }
    }
  }
  catch (error) {
    return { "message": error, "status": false }
  }
}


// Admin Home
const adminHomeAPI = async (startDate, endDate) => {
  let token = ""
  if (localStorage.getItem("auth_token") != null) {
    token = localStorage.getItem("auth_token")
  }
  const myHeaders = new Headers();
  myHeaders.append(`Authorization", "Bearer ${token}"`)

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {

    const response = await fetch(`${process.env.REACT_APP_MODEL_URL}/admin_home/?start=${startDate}&end=${endDate}`, requestOptions)
    const data = await response.json()
    // console.log(response)
    // console.log(data)
    if (response.ok) {
      return { "message": data, "status": true }
    }
    else {
      return { "message": "Something went wrong.", "status": false }
    }
  }
  catch (error) {
    return { "message": error, "status": false }
  }
}





// text To Audio (Bhasini)  --- 
const bhasiniTextToAudioConversionAPI = async (text, response_format, source_lang, target_lang) => {
  try {
    const requestBody = {
      text: text,
      response_format: response_format,
      source_language: source_lang,
      target_language: target_lang
    };
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    };
    // console.log(text_data)

    const response = await fetch(`${process.env.REACT_APP_Language_Conversion_URL}/api/text_to_audio/`, options)
    const data = await response.json()
    // console.log(response)
    // console.log(data)
    if (response.ok) {
      return { "message": data, "status": true }
    }
    else {
      return { "message": "Something went wrong.", "status": false }
    }
  }
  catch (error) {
    return { "message": error, "status": false }
  }
}

 // text To Audio (Bhasini)  --- 
 const bhasiniTextToAudioOnlyConversionAPI = async (text, response_format, source_lang) => {
  try {
    const requestBody = {
      text: text,
      response_format: response_format,
      source_language: source_lang
    };
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    };
    // console.log(text_data)

    const response = await fetch(`${process.env.REACT_APP_Language_Conversion_URL}/api/text_to_audio_only/`, options)
    const data = await response.json()
    // console.log(response)
    // console.log(data)
    if (response.ok) {
      return { "message": data, "status": true }
    }
    else {
      return { "message": "Something went wrong.", "status": false }
    }
  }
  catch (error) {
    return { "message": error, "status": false }
  }
}

// Text Translation (Bhasini)  --- 
const bhasiniTextTranslationAPI = async (text, source_lang, target_lang) => {
  try {
    const requestBody = {
      text: text,
      source_language: source_lang,
      target_language: target_lang
    };
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    };
    // console.log(text_data)

    const response = await fetch(`${process.env.REACT_APP_Language_Conversion_URL}/api/text_translation/`, options)
    const data = await response.json()
    // console.log(response)
    // console.log(data)
    if (response.ok) {
      return { "message": data, "status": true }
    }
    else {
      return { "message": "Something went wrong.", "status": false }
    }
  }
  catch (error) {
    return { "message": error, "status": false }
  }
}

 // Audio To Text Translation Only (Bhasini)  --- 
 const bhasiniAudioToTextConversionOnlyAPI = async (source_lang, file) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('source_language', source_lang);


    // const response = await fetch(`${process.env.REACT_APP_Language_Conversion_URL}api/text_to_audio/`, options)
    const response = await fetch(`${process.env.REACT_APP_Language_Conversion_URL}/api/audio_to_text_only/`, {
        method: 'POST',
        body: formData
      });
    const data = await response.json()
    // console.log(response)
    // console.log(data)
    if (response.ok) {
      return { "message": data, "status": true }
    }
    else {
      return { "message": "Something went wrong.", "status": false }
    }
  }
  catch (error) {
    return { "message": error, "status": false }
  }
}

// text To English (Bhasini)  ---
const bhasiniTextToEnglishConversionAPI = async (text, response_format, source_lang) => {
  try {
    const requestBody = {
      text: text,
      response_format: response_format,
      source_language: source_lang
    };
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    };
    // console.log(text_data)

    const response = await fetch(`${process.env.REACT_APP_Language_Conversion_URL}/api/text_to_audio_english/`, options)
    const data = await response.json()
    // console.log(response)
    // console.log(data)
    if (response.ok) {
      return { "message": data, "status": true }
    }
    else {
      return { "message": "Something went wrong.", "status": false }
    }
  }
  catch (error) {
    return { "message": error, "status": false }
  }
}



// Audio To Text Conversion API (Bhasini) ---
const bhasiniAudioToTextConversionAPI = async (source_lang, target_lang, file) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('target_language', source_lang);
    formData.append('source_language', target_lang);


    // const response = await fetch(`${process.env.REACT_APP_Language_Conversion_URL}api/text_to_audio/`, options)
    const response = await fetch(`${process.env.REACT_APP_Language_Conversion_URL}/api/audio_to_text/`, {
        method: 'POST',
        body: formData
      });
    const data = await response.json()
    // console.log(response)
    // console.log(data)
    if (response.ok) {
      return { "message": data, "status": true }
    }
    else {
      return { "message": "Something went wrong.", "status": false }
    }
  }
  catch (error) {
    return { "message": error, "status": false }
  }
}


// Audio To Text Conversion API (Google) ---
const googleAudioToTextConversionAPI = async (source_lang, target_lang, file) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('target_language', source_lang);
    formData.append('source_language', target_lang);


    // const response = await fetch(`http://127.0.0.1:8000/api/google_audio_to_text/`, {
      const response = await fetch(`${process.env.REACT_APP_Language_Conversion_URL}/api/google_audio_to_text/`, {
        method: 'POST',
        body: formData
      });
    const data = await response.json()
    // console.log(response)
    // console.log(data)
    if (response.ok) {
      return { "message": data, "status": true }
    }
    else {
      return { "message": "Something went wrong.", "status": false }
    }
  }
  catch (error) {
    return { "message": error, "status": false }
  }
}





export {
  checkingServerStatus, startingSession, startChat,
  chatResponse, olderChats, restoreOlderChat, likeResponse,
  dislikeResponse, comment, suggestion, editTitleApi, deleteTitleApi,
  bhasiniAudioToTextConversionAPI, bhasiniTextToAudioConversionAPI, bhasiniTextToEnglishConversionAPI,
  googleAudioToTextConversionAPI, bhasiniTextTranslationAPI, bhasiniAudioToTextConversionOnlyAPI, bhasiniTextToAudioOnlyConversionAPI,
  adminHomeAPI
}