import React from 'react'
import Navbar from '../../components/Navbar/Navbar'
import AdminSidebar from '../../components/AdminSidebar/AdminSidebar'
import QueryMetrics from "../../components/QueryMetrics/QueryMetrics"
import QueryMetricsTablePopUp from "../../components/QueryMetricsTablePopUp/QueryMetricsTablePopUp"

// React Redux
import { useSelector } from 'react-redux'

const QueryMetricsPage = () => {
  const {queryMetricsTablePopUPStatus} = useSelector((state) => state.querymetrics)

  return (
    <main className='admin__dashboard__main__page'>
      {queryMetricsTablePopUPStatus && <QueryMetricsTablePopUp />}
        <div className='admin__dashboard__box'>
          <Navbar />
          <div className='admin__dashboard__elems'>
            <AdminSidebar />
            <QueryMetrics/>
          </div>

        </div>
    </main>
  )
}

export default QueryMetricsPage
