import React from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router";

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <main className="admin__dashboard__main__page">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        className="admin__dashboard__box"
      >
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={<Button onClick={() => navigate("/technicaladvisor")} type="primary">Back Home</Button>}
        />
      </div>
    </main>
  );
}

export default NotFoundPage;
