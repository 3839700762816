import React from "react";
import { useLocation } from 'react-router-dom';

import Navbar from "../../components/Navbar/Navbar";
import AdminSidebar from "../../components/AdminSidebar/AdminSidebar";
import UserChat from "../../components/UserChat/UserChat";

const UserChatPage = () => {
  // Accessing User ID from URL
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  let user_id = params.get('user');
  console.log(user_id)

  return (
    <main className="admin__dashboard__main__page">
      <div className="admin__dashboard__box">
        <Navbar />
        <div className='admin__dashboard__elems'>
            <AdminSidebar />
            <UserChat/>
          </div>
      </div>
    </main>
  );
}

export default UserChatPage;
