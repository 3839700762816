import React from 'react'
import Navbar from '../../components/Navbar/Navbar'
import AdminSidebar from '../../components/AdminSidebar/AdminSidebar'
import RealTimeMetrics from '../../components/RealTimeMetrics/RealTimeMetrics'
import RealTimeMetricsTablePopUp from '../../components/RealTimeMetrics/RealTimeMetricsTablePopUp'

import { useSelector } from 'react-redux'

function RealTimeMetricsPage() {
    // Redux
  const {tablePopUPStatus} = useSelector((state) => state.realtimemetrics)

  return (
    <main className='admin__dashboard__main__page'>
        {tablePopUPStatus && <RealTimeMetricsTablePopUp />}
        <div className='admin__dashboard__box'>
          <Navbar />
          <div className='admin__dashboard__elems'>
            <AdminSidebar />
            <RealTimeMetrics/>
          </div>

        </div>
    </main>
  )
}

export default RealTimeMetricsPage
