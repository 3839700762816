import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { token as userAuthToken } from "../../Utility/Token";


export const evalRunAPI = createAsyncThunk('evalrun/fetch', async () => {
    let userAuthToken = ""
    if (localStorage.getItem("auth_token") != null) {
        userAuthToken = localStorage.getItem("auth_token")
    }

    const res = await fetch(`${process.env.REACT_APP_MODEL_URL}/get_eval_runs/`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${userAuthToken}` }
    });
    const data = await res.json();
    return data;
})

export const evalDataAPI = createAsyncThunk('evaldata/fetch', async (params) => {
    let userAuthToken = ""
    if (localStorage.getItem("auth_token") != null) {
        userAuthToken = localStorage.getItem("auth_token")
    }

    const res = await fetch(`${process.env.REACT_APP_MODEL_URL}/get_eval_data/?id=${params.id}`, 
    {
        headers: { Authorization: `Bearer ${userAuthToken}` },
        method: "GET",
        redirect: "follow"
    });
    let data = await res.text();
    data = data.replace(/"search_score":NaN/g, `"search_score": "NaN"`);
    data = JSON.parse(data)
    return data;
})


const queryMetricsPageSlice = createSlice({
    name: "queryMetrics",
    initialState: {
        queryMetricSidebarStatus: false,
        queryMetricsTablePopUPStatus: false,
        defaultQueryMetricsRunID: -1,
        defaultQueryMetricsCallStatus: false,
        // Eval Metrics Component State
        selectedEvalMetricsComponent: -1,
        // Eval Run
        evalRunStatus: 'idle',
        evalRunSidebarData: [],
        // Eval Data
        evalDataStatus: 'idle',
        evalChartData: {},
        evalTableData: {},
        evalPositiveChanges: [],
        evalNegativeChanges: []
    },
    extraReducers: (builder) => {
        builder
        // Eval Run
        .addCase(evalRunAPI.pending, (state) => {
            state.evalRunStatus = 'loading';
            state.evalRunSidebarData = []
        })
        .addCase(evalRunAPI.fulfilled, (state, action) => {
            state.evalRunStatus = 'succeeded';
            state.evalRunSidebarData = action.payload["doc_list"]
            state.defaultQueryMetricsRunID = action.payload["latest"]
        })
        .addCase(evalRunAPI.rejected, (state) => {
            state.evalRunStatus = 'failed';
            state.evalRunSidebarData = []
        })
        // Eval Data
        .addCase(evalDataAPI.pending, (state) => {
            state.evalDataStatus = 'loading'
            state.evalChartData = {}
            state.evalTableData = {}
            state.evalPositiveChanges = []
            state.evalNegativeChanges = []
        })
        .addCase(evalDataAPI.fulfilled, (state, action) => {
            state.evalDataStatus = 'succeeded'
            state.evalChartData = action.payload["averages"]
            state.evalTableData = action.payload["results"]
            state.evalPositiveChanges = action.payload["positive changes"]
            state.evalNegativeChanges = action.payload["negative changes"]
        })
        .addCase(evalDataAPI.rejected, (state, action) => {
            state.evalDataStatus = 'failed'
            state.evalChartData = {}
            state.evalTableData = {}
            state.evalPositiveChanges = []
            state.evalNegativeChanges = []
        })
    },
    reducers: {
        setQueryMetricSidebarStatus: (state, action) => {
            state.queryMetricSidebarStatus = action.payload
        },
        setQueryMetricsTablePopUPStatus: (state, action) => {
            state.queryMetricsTablePopUPStatus = action.payload
        },
        setDefaultQueryMetricsCallStatus: (state, action) => {
            state.defaultQueryMetricsCallStatus = action.payload
        },
        // Eval Metrics Component State
        setSelectedEvalMetricsComponent: (state, action) => {
            console.log("Payload: ", action.payload)
            state.selectedEvalMetricsComponent = action.payload
        }
    }
})

// Reducer Variables
export const {setQueryMetricSidebarStatus, setQueryMetricsTablePopUPStatus, setDefaultQueryMetricsCallStatus, setSelectedEvalMetricsComponent} = queryMetricsPageSlice.actions

export default queryMetricsPageSlice.reducer