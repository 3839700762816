import React,{useState, useRef, useEffect} from 'react'

import { Link } from 'react-router-dom'
import SyncLoader from "react-spinners/SyncLoader";

import { bhasiniTextToAudioOnlyConversionAPI, bhasiniAudioToTextConversionAPI, googleAudioToTextConversionAPI, bhasiniAudioToTextConversionOnlyAPI } from '../../API/BackendAPI';


import botImg from "../svg-store/technicaladvisor/bot-message.svg"
import questionImg from "../svg-store/technicaladvisor/questions.svg"
import questionImgArrow from "../svg-store/technicaladvisor/quesArrow.svg"
import mic from "../svg-store/technicaladvisor/mic.svg"
import send from "../svg-store/technicaladvisor/send.svg"
import bot from "../svg-store/technicaladvisor/bot.svg"
import user from "../image/technicaladvisor/user.png"
// import user from "../svg-store/technicaladvisor/user.svg"
import copyClipboard from "../svg-store/technicaladvisor/copyClipboard.svg"
import like1 from "../svg-store/technicaladvisor/like1.svg"
import dislike1 from "../svg-store/technicaladvisor/dislike1.svg"
import playIcon from "../svg-store/technicaladvisor/play.svg"
import pauseIcon from "../svg-store/technicaladvisor/pause.svg"



import LikeMessage from '../Modal/LikeMessage';
import DislikeMessage from '../Modal/DislikeMessage';
import { BiSolidDislike } from "react-icons/bi";
import { AiFillLike } from "react-icons/ai";

import DeleteMessage from '../Modal/DeleteMessage';



import { BsRecordCircle } from "react-icons/bs";
import ScaleLoader from "react-spinners/ScaleLoader";
import MoonLoader from "react-spinners/MoonLoader";
import Loader from '../../Loader/Loader';

import qala from "../music/music.mp3"

import useSound from "use-sound"; // for handling the sound

const TechnicalAdvisor = ({onButtonClick, user_messages, olderChatClickedStatus, chatResponseStatus, currentLanguage,
                            otherChatClickedStatus, changeOtherChatClickedStatus, currentTranslationAPI,
                            deletePageModalStatus, deleteChatModalController, deleteChatID, deleteChatTitle,
                            audioResponseStatus}) => {


  
  

  const msgEnd = useRef(null)
    
  const [input, setInput] = useState("")

  // Positive Feedback State
  const [likedResponseMessageArray, setLikedResponseMessageArray] = useState([]);

  // Negative Feedback State
  const [dislikedResponseMessageArray, setDislikedResponseMessageArray] = useState([]);
  

  useEffect(() => {
    if(otherChatClickedStatus){
      setLikedResponseMessageArray([])
      setDislikedResponseMessageArray([])
      setInput("")
      setClipboardCopiedMessageArray([])
    }
    changeOtherChatClickedStatus(false)
    // console.log(olderChatClickedStatus)
  }, [otherChatClickedStatus, changeOtherChatClickedStatus])
  


   // Handling Enter pressed button
   const handleEnter = async (event) => {
    if(chatResponseStatus){
      return
    }
    if (event.key === 'Enter') {
      handleSend()
    }
  }

  // Handling Send Button Click
  const handleSend = async () => {
    // onButtonClick(input, choosenLanguage, translationAPI)
    if(chatResponseStatus){
      return
    }
    onButtonClick(input)
    setInput("")
  }

  // For Scrollbar
  useEffect(() => {
    if(user_messages.length>0){
      msgEnd.current.scrollIntoView()
    }
  }, [user_messages])




  // Audio, text Handler -------------------
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioSource, setAudioSource] = useState(null)

  const [play, { pause, duration, sound }] = useSound(audioSource);
  
  // Audio Playing Handler
  const playingButton = (index) => {
    // console.log(index)
    console.log(user_messages[index]["audio"])
    const aud = user_messages[index]["audio"]

    // const blob = new Blob([user_messages[index]["audio"]], {
    //   'type': 'audio/mp3'
    // });
    // setAudioSource(blob);
    setAudioSource(aud)
    console.log(audioSource)

    // setAudioSource(user_messages[index]["audio"])
    // const [play, { pause, duration, sound }] = useSound(qala);
    if (isPlaying) {
      pause(); // this will pause the audio
      setIsPlaying(false);
    } else {
      play(); // this will play the audio
      setIsPlaying(true);
    }
  };


  const MicRecorder = require('mic-recorder-to-mp3');

  const [recorder, setRecorder] = useState(new MicRecorder({ bitRate: 64 }));

  const [isBlocked, setIsBlocked] = useState(false);

  const [isRecording, setIsRecording] = useState(false)

  const [recordingStatus, setRecordingStatus] = useState(false)

  const startRecording = async () => {
    if (isBlocked) {
      console.log('Permission Denied');
    }
    else {
      try {
        // Start recording. Browser will request permission to use your microphone.
        await recorder.start();
        setIsRecording(true)
        setRecordingStatus(true)

        setInput("")
      }
      catch (error) {
        console.error('Error initializing MicRecorder:', error);
        setRecordingStatus(false)
      }

    }
  };


  const stopRecording = async () => {
    try {
      await recorder.stop();
      const [buffer, blob] = await recorder.getMp3();

      // Create a mp3 file and play
      const file = new File(buffer, 'recording.mp3', {
        type: blob.type,
        lastModified: Date.now()
      });
      setIsRecording(false)
      // callBhasiniSpeechToTextAPI(file)

      // Calling Bhasini API
      if (currentTranslationAPI === "bh") {
        callBhasiniSpeechToTextAPI(file)
      }
      else {
        callGoogleSpeechToTextAPI(file)
      }
    }
    catch (error) {
      console.error('Error closing MicRecorder:', error);
      setRecordingStatus(false)
    }

    // console.log("recording:", isRecording)


  };

  // calling Bhasini API
  const callBhasiniSpeechToTextAPI = async (file) => {
    // const res = await bhasiniAudioToTextConversionAPI(currentLanguage, currentLanguage, file)
    try{
      const res = await bhasiniAudioToTextConversionOnlyAPI(currentLanguage, file)
      // console.log(res)
      if (res.status) {
        // console.log(res["message"]["data"]["pipelineResponse"][0]["output"][0]["source"])
        setInput((prevInput) => {
          const newInput = res["message"]["data"]["pipelineResponse"][0]["output"][0]["source"]
          return newInput;
        });
        setRecordingStatus(false)
      }
      else {
        setRecordingStatus(false)
        setInput("Something went wrong")
      }
    }
    catch(err) {
      // console.log("Error", err)
      setRecordingStatus(false)
      setInput("Something went wrong")
    }
  }

  // calling Google API
  const callGoogleSpeechToTextAPI = async (file) => {
    try {
      const res = await googleAudioToTextConversionAPI(currentLanguage, currentLanguage, file)
      // console.log(res)
      if (res.status) {
        // console.log(res)
        setInput((prevInput) => {
          const newInput = res["message"]["data"]["input"]
          return newInput;
        });
        setRecordingStatus(false)
      }
      else {
        setRecordingStatus(false)
        setInput("Something went wrong")
      }
    }
    catch(err){
      setRecordingStatus(false)
      setInput("Something went wrong")
    }
  }


  // Like, Dislike Response Handler -----------

  

  
  

  // State for Like Response Modal onClick
  const [likedPageModalStatus, setLikedPageModalStatus] = useState(false)

  const [messageID, setMessageID] = useState(0)
  // Positive Feedback State

  const likedMsgArrayHandler = (msg_id) => {
    setLikedResponseMessageArray(prevValues => [...prevValues, msg_id])
  }

  const likeBtnClickHandler = (msg_id) => {
    // console.log("msg_id", msg_id)
    setMessageID(msg_id)
    setLikedPageModalStatus(true)
  }


  

  
  
  

  // State for Dislike Response Modal onClick
  const [dislikedPageModalStatus, setDisikedPageModalStatus] = useState(false)

  const dislikedMsgArrayHandler = (msg_id) => {
    setDislikedResponseMessageArray(prevValues => [...prevValues, msg_id])
  }
  

  const dislikeBtnClickHandler = (msg_id) => {
    // console.log("msg_id", msg_id)
    setMessageID(msg_id)
    setDisikedPageModalStatus(true)
  }

  // console.log(dislikedResponseMessageArray)

  const suggestionQuesClickHandler = (ques) => {
    setInput("")
    setInput(ques)
  }

  // Array containing the index of messages whose text copied to clipboard
  const [clipboardCopiedMessageArray, setClipboardCopiedMessageArray] = useState([])

  const copyToClipboardHandler = (message, i) => {

    if(!clipboardCopiedMessageArray.includes(i)){
      setClipboardCopiedMessageArray(prevValues => [...prevValues, i])
    }
    

    if( message.hasOwnProperty('new_textWithBold') && message.translated_content !== "new_textWithBold"){
      let formatting_text = message.new_textWithBold
      formatting_text = formatting_text.replace(/<b>/g, "")
      formatting_text = formatting_text.replace(/<\/b>/g, "")
      formatting_text = formatting_text.replace(/&nbsp;/g, " ")
      formatting_text = formatting_text.replace(/&bull;/g, "•")
      formatting_text = formatting_text.replace(/&ensp;/g, "  ")
      navigator.clipboard.writeText(formatting_text);
    }
    else{
      navigator.clipboard.writeText(message.content);
    }

  }

  // console.log(user_messages)


  return (
    <div className='technicaladvisor__main__box'>

      { likedPageModalStatus && <LikeMessage likedModalStatus={setLikedPageModalStatus} msgID={messageID} likedMsgArray={likedMsgArrayHandler} /> }
      { dislikedPageModalStatus && <DislikeMessage dislikedModalStatus={setDisikedPageModalStatus} msgID={messageID} dislikedMsgArray={dislikedMsgArrayHandler}/> }
      {deletePageModalStatus && <DeleteMessage deleteChatModalController={deleteChatModalController} deleteChatID={deleteChatID} deleteChatTitle={deleteChatTitle}/>}

            <div className='technicaladvisor__upper'>

                {user_messages.length === 0  ? 
                 <div className='technicaladvisor__initial'>
                    <div className="technicaladvisor__top">
                        <div className="technicaladvisor__intro">
                            <img src={botImg} alt="botImg" />
                            <span>Hi, Ask me anything; I can answer questions with respect to all sectors.</span>
                        </div>
                    </div>
                    <div className="technicaladvisor__middle">
                        <div className="technicaladvisor__boxes">
                            <div className="technicaladvisor__box">
                                <div className="technicaladvisor__box__toplines">
                                    <img src={questionImg} alt="questions" />
                                    <span>Suggested Questions</span>
                                </div>
                                <div className="technicaladvisor__quesBox">
                                    <button onClick={() => suggestionQuesClickHandler("What is the literacy rate in Santhabommali Mandal, located in the Srikakulam District of Andhra Pradesh?")}>
                                        <span>“ What is the literacy rate in Santhabommali Mandal, located in the Srikakulam District of Andhra Pradesh? ” <img src={questionImgArrow} alt="ques" /> </span>
                                    </button>
                                    <button onClick={() => suggestionQuesClickHandler("What is platform economy?")}>
                                        <span>“ What is platform economy? ” <img src={questionImgArrow} alt="ques" /> </span>
                                    </button>
                                    <button onClick={() => suggestionQuesClickHandler("What are some best practices that integrate technology with goat farming in India?")}>
                                        <span>“ What are some best practices that integrate technology with goat farming in India? ” <img src={questionImgArrow} alt="ques" /> </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                :
                <div className='technicaladvisor__message__main'>
                  
                  {/* Scroll into top of chat */}
                  {olderChatClickedStatus && <div ref={msgEnd}></div>}

                  <div>
                    <div className='technicaladvisor__message__boxes'>
                          {
                          user_messages.map((message, i) => (
                              <div key={i} className={message.author === "bot" ? 'technicaladvisor__bot__box' : 'technicaladvisor__user__box'}>
                                  <img className='technicaladvisor__bot__user__img' src={message.author === "bot" ? bot : user} alt="bot" />
                                  <div className={message.author === "bot" ? 'technicaladvisor__bot__message' : 'technicaladvisor__user__message'}>
                                  {/* <p className="txt" dangerouslySetInnerHTML={{ __html: message.text }} /> */}
                                  {message.hasOwnProperty('new_textWithBold') ? <span dangerouslySetInnerHTML={{ __html: message.new_textWithBold }} /> : <span>{message.content}</span>}
                                      {/* <span>
                                        {
                                          message.hasOwnProperty('translated_content') ?
                                          message.translated_content === "None" ? message.content : message.translated_content
                                          :
                                          message.content
                                        }
                                      </span> */}

                                      {message.hasOwnProperty('links_arr') && message["links_arr"].length>0 && 
                                          <div className='technicaladvisor__links__box'>
                                              <span>Refer to following links for more information:</span>
                                              <div className='technicaladvisor__links'>
                                                  {message.links_arr.map((links, i) => (
                                                      <li key={i}>
                                                          <a href={links.pdf_link} target='_blank' rel="noreferrer" > <span>{links.pdf_name}</span> </a>
                                                      </li>
                                                      ))
                                                  }
                                              </div>
                                          </div>
                                      }

                                      {message.author === "bot" &&
                                          <div className='technicaladvisor__messages__bottom'>
                                              <span>Feel free to ask more specific questions, and I'll do my best to assist you!</span>
                                              <div className='technicaladvisor__messages__bottom__buttons'>
                                                  <div className='technicaladvisor__messages__bottom__buttons--top'>
                                                    <button className={clipboardCopiedMessageArray.includes(i) ? 'copy__clipboard' : ''} onClick={() => copyToClipboardHandler(message, i)}><img src={copyClipboard} alt="copy" /></button>

                                                    {likedResponseMessageArray.includes(i) ? <AiFillLike size={22}/> : 
                                                    dislikedResponseMessageArray.includes(i) ? <BiSolidDislike size={22}/> :
                                                    <div className='like__dislike__buttons'>
                                                      <button onClick={() => likeBtnClickHandler(i)}><img src={like1} alt="like" /></button>
                                                      <button onClick={() => dislikeBtnClickHandler(i)}><img src={dislike1} alt="dislike" /></button>
                                                    </div>
                                                    
                                                    }
                                                  </div>

                                                  {/* Audio Playing functionality */}
                                                  
                                                  {
                                                    message.hasOwnProperty("audio") && message.audio !== "None" &&
                                                    <div className='technicaladvisor__messages__audio__box'>
                                                      {
                                                        audioResponseStatus && message.audio === "loading" ?
                                                          <MoonLoader color="#070707" size={25}/>
                                                          :
                                                          <audio controls src={message.audio}></audio>
                                                      }
                                                    </div>
                                                      
                                                  }


                                                  {/* Audio Button a/cc to UI */}
                                                    {/* <div className='technicaladvisor__messages__audio__box'>
                                                      {
                                                        !isPlaying ?
                                                          <button onClick={() => playingButton(i)}>
                                                            <img src={playIcon} alt="play" />
                                                            <span>Play{i}</span>
                                                          </button>
                                                        :
                                                          <button onClick={() => playingButton(i)}>
                                                            <img src={pauseIcon} alt="pause" />
                                                            <span>Pause</span>
                                                          </button>
                                                      }
                                                    </div>  */}


                                              </div>
                                          </div>
                                      }
                                    

                                  </div>
                              </div>
                          ))
                          }

                          {/* Scroll into Last chat */}
                          {!olderChatClickedStatus && <div ref={msgEnd}></div>}

                          {/* Spinner appears while getting data */}
                          {chatResponseStatus &&
                            <div className='response__loader'>
                              <SyncLoader color="#333333" />
                            </div>
                            // <Loader/>
                          }
                          {/* <Loader/> */}
                          
                    </div>
                  </div>
                </div>
                }

            </div>

        <div className="technicaladvisor__bottom">
            <div className='technicaladvisor__footer'>

                {isRecording ?
                  <button disabled={!isRecording}>
                      <BsRecordCircle color='red' size={28} onClick={stopRecording} />
                  </button>
                  :
                  <button onClick={startRecording} disabled={isRecording}>
                      <img src={mic} alt="mic" />
                  </button>
                }

                {recordingStatus &&<ScaleLoader height="25" color="#7C7C7C" />}

                <textarea value={input} rows="2"
                onChange={(e) => { setInput(e.target.value) }} onKeyDown={handleEnter} onKeyPress={e => { if (e.key === 'Enter') e.preventDefault() }}
                placeholder={recordingStatus ? "" : 'Ask your query here'}
                />
                <button onClick={handleSend}>
                    <img src={send} alt="send" />
                </button>
            </div>

            <div className='technicaladvisor__footer__valiance'>
              <span>&#169; {new Date().getFullYear()},
              powered by &nbsp;
              <a className='technicaladvisor__footer--name' href="https://valiancesolutions.com/" target="_blank" rel="noreferrer" >
                Valiance Solutions
              </a>
              </span>
            </div>
        </div>

    </div>
  )
}

export default TechnicalAdvisor