import React, { useEffect, useState } from 'react'

import QueryMetricsRadarChart from './QueryMetricsRadarChart'
import QueryMetricsTable from './QueryMetricsTable'
import QueryMetricsList from './QueryMetricsList'

import { GiExpand } from "react-icons/gi";

// React Redux
import { useDispatch, useSelector } from 'react-redux';
import { setQueryMetricsTablePopUPStatus, evalRunAPI, evalDataAPI, setQueryMetricSidebarStatus, setDefaultQueryMetricsCallStatus, setSelectedEvalMetricsComponent } from '../../features/QueryMetricsPage/QueryMetricsPageSlice';


const QueryMetrics = () => {
  // React Redux
  const dispatch = useDispatch()
  const {evalRunStatus, defaultQueryMetricsRunID, defaultQueryMetricsCallStatus} = useSelector((state) => state.querymetrics)

  // On Page Load
  useEffect(() => {
    dispatch(setQueryMetricSidebarStatus(true))
    dispatch(setSelectedEvalMetricsComponent(0))
  }, [])


  useEffect(() => {
    if(evalRunStatus === 'idle'){
      dispatch(evalRunAPI())
    }
  }, [evalRunStatus])


  useEffect(() => {
    // Call only first time
    if(defaultQueryMetricsRunID !== -1 && defaultQueryMetricsCallStatus===false){
      dispatch(evalDataAPI({id: defaultQueryMetricsRunID}))
      dispatch(setDefaultQueryMetricsCallStatus(true))
    }
  }, [defaultQueryMetricsRunID, defaultQueryMetricsCallStatus])

  return (
    <div className='query__metrics__main'>
      <div className="query__metrics__box">

        <div className='query__metrics__chart'>
          <QueryMetricsRadarChart />
        </div>

        <div className='query__metrics__tabel__box'>
          <div className='query__metrics__tabel__expand__box'>
            <div onClick={() => dispatch(setQueryMetricsTablePopUPStatus(true))} className='query__metrics__tabel__expand'>
              <GiExpand size={26}/>
            </div>
          </div>
          <div className='query__metrics__table'>
            <QueryMetricsTable />
          </div>
        </div>

        <div className='query__metrics__list__box__main'>
          <QueryMetricsList />
        </div>

      </div>
    </div>
  )
}

export default QueryMetrics
