import { useState } from 'react'

import { useNavigate } from 'react-router-dom'

import valianceLogo from '../../components/image/login/niti-aayog/Valiance.png'
import ClipLoader from "react-spinners/ClipLoader";

import loginImg from '../../components/image/login/niti-aayog/Niti aayog login page.png';

// import loginPage from "../../assets/KM Login Page.png"

const LoginPage = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    // If user does not enters Valid Credentials
    const [credentialErrorMessage, setCredentialErrorMessage] = useState(null)
    // User is Valid or not
    const [isValidUser, setIsValidUser] = useState(null)
    // Spinner on Login
    const [loginStatus, setLoginStatus] = useState(false)

    const navigate = useNavigate()

    // Handle Enter Pressed Button
    const  handleLoginEnter = async (event) => {
        if(event.key === 'Enter'){
          // await handleSend()
          clickHandler()
        }
    }

    // Handle when Login Button pressed
    const clickHandler = async () => {
        if(email.length === 0 && password.length === 0){
            setCredentialErrorMessage("Please enter email & password.")
            return 
        }
        else if(password.length === 0){
            setCredentialErrorMessage("Please enter password.")
            return
        }
        else if(email.length === 0){
            setCredentialErrorMessage("Please enter email.")
            return
        }

        const data = await loginApi(email, password)
        if(data.status){
            localStorage.setItem("auth_token", data.message.message.token)
            if(data.message.message.is_admin){
                localStorage.setItem("is_admin", data.message.message.is_admin)
            }
            navigate("/sector")
        }
        else if(data.data){  
            setCredentialErrorMessage(data.data.message)
        }
        else if(!data.status){
            setCredentialErrorMessage("Can't proccess your request now.")
        }

    }

    // Login API
    const loginApi = async (user_email, user_password) => {
        setLoginStatus(true)
        try{
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: user_email, password: user_password})
            };

            const response = await fetch(`${process.env.REACT_APP_LOGIN_URL}/api/login_dummy`, requestOptions)
            // const response = await fetch(`${process.env.REACT_APP_LOGIN_URL}/api/login`, requestOptions)
            const data = await response.json()
            if(response.status === 200){
                setLoginStatus(false)
                return {"message": data, "status": true}
            }
            else if(response.status === 401){
                setLoginStatus(false)
                return {"message": "Invalid Credentials.", "data": data, "status": false}
            }
            else{
                setLoginStatus(false)
                return {"message": "Something went wrong.", "status": false}
            }
        }
        catch(error){
            setLoginStatus(false)
            return {"message": error, "status": false}
        }
    }
    
    return (
        
        <div className='index-parent'>
        <div className='index'>
            <div className="split left">
                <img src={loginImg} alt="login Page" />
            </div>
            <div className="split right">
                <div className="comp-header">
                    <div className='company-logo'>
                        <img src={valianceLogo} alt="Valiance Logo"></img>
                    </div>
                    <div className='company-name'>
                        <span>Valiance Solutions</span>
                    </div>
                </div>
                <div className='right-content-main'>
                    <div className='left-content-heading'>
                        <span>NITI Aayog E-Mitra</span>
                    </div>
                    <div className='left-content-desc'>
                        <span>Sector-Specific Insights with GEN-AI Help Desk</span>
                    </div>
                </div>
                <div className="form">
                    <div className='form-heading'>
                        <span>Login</span>
                    </div>
                    <div className='form-content'>
                        <div className='form-content-elements'>
                            <label>Username</label>
                            <input type="email" value={email} onChange={(e) => {setEmail(e.target.value)}} onKeyDown={handleLoginEnter}></input>
                        </div>
                        <div className='form-content-elements'>
                            <label className='pwd-label'>Password</label>
                            <input className='pwd-input' value={password} type="password" onChange={(e) => {setPassword(e.target.value)}} onKeyDown={handleLoginEnter}></input>
                        </div>

                        {   !isValidUser &&
                            <div className='form-content-elements'>
                                <label className='errorMessage'>{credentialErrorMessage}</label>
                            </div>      
                            
                        }

                        {loginStatus ?
                        <button className='btn' style={{cursor: "no-drop"}}>
                            <ClipLoader color="#fcffff" />
                        </button>
                        :
                        <button className='btn' onClick={clickHandler}>
                            Submit
                        </button>
                        }
                    </div>
                </div>

                

                <div className='login-footer'>
                    <div className='horizontalLine'>
                    </div>
                    <span>&#169; {new Date().getFullYear()} <a href="https://valiancesolutions.com/" target="_blank" rel="noreferrer" >Valiance Solutions.</a></span>
                </div>
            </div>

        </div>

        {/* <div className='login-footer'>
            <span>&#169; 2023 <a href="https://valiancesolutions.com/" target="_blank" rel="noreferrer" >Valiance Solutions.</a></span>
        </div> */}

        </div>
    )
}

export default LoginPage


