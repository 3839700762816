import React, { useEffect, useState } from 'react';
import { Table, Tooltip } from 'antd';

import { Result, Tag } from 'antd';
import { FrownTwoTone } from '@ant-design/icons';

// Backend APIs
import { useSelector } from "react-redux";



const RealTimeMetricsTable = () => {

  const tableScrollableColumnWidth = 300

  const columns = [
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'question',
      fixed: 'left',
      width: 240,
    },
    {
      title: 'Answer',
      dataIndex: 'answer',
      key: 'answer',
      fixed: 'left',
      width: 240,
    },
    {
      title: 'Context',
      dataIndex: 'context',
      key: 'context',
      width: tableScrollableColumnWidth,
      onCell: (record) => ({
        style: {
          fontWeight: record.key === 1000 && 'bold' ,
          fontSize: record.key === 1000 ? '18px' : null,
        },
      }),
    },
    {
      title: 
          <Tooltip placement="top" title={"Detects if the input provided by the user tricks the model into revealing its credentials, by-passing the system prompt to answer based on the prompt given by the user, etc."}>
            Prompt Injection
          </Tooltip>,
      dataIndex: 'promptInjection',
      key: 'promptInjection',
      width: tableScrollableColumnWidth,

      onCell: (record) => {
        let val = ""
        if(typeof record.promptInjection === 'object'){
          val = record.promptInjection.props.children
          val = val.slice(0,-1)
          val = Number(val)
          if(typeof val !== NaN){
            if(val>=0 && val<=10){
              val = avgPercentageText.safe
            }
            else if(val>=11 && val<=20){
              val = avgPercentageText.caution
            }
            else{
              val = avgPercentageText.danger
            }
          }
        }

        return {
          style: {
            color: typeof record.promptInjection === 'object' ? val===avgPercentageText.safe ? "green" : val===avgPercentageText.caution ? "yellow" : "red" : null,
            fontWeight: record.key === 1000 ? 'bold' : null,
            fontSize: record.key === 1000 ? '18px' : null,
          },
          
        };
      },
    },
    
    {
      title: 
          <Tooltip placement="top" title={"Evaluates if every subpart of the question was answered or not"}>
            Response Completeness 
          </Tooltip>,
      dataIndex: 'responseCompleteness',
      key: 'responseCompleteness',
      width: tableScrollableColumnWidth,
      onCell: (record) => {
        let val = ""
        if(typeof record.responseCompleteness === 'object'){
          val = record.responseCompleteness.props.children
          val = val.slice(0,-1)
          val = Number(val)
          if(typeof val !== NaN){
            if(val>=0 && val<=20){
              val = avgPercentageText.danger
            }
            else if(val>=21 && val<=79){
              val = avgPercentageText.caution
            }
            else{
              val = avgPercentageText.safe
            }
          }
        }

        return {
          style: {
            color: typeof record.responseCompleteness === 'object' ? val===avgPercentageText.safe ? "green" : val===avgPercentageText.caution ? "yellow" : "red" : null,
            fontWeight: record.key === 1000 ? 'bold' : null,
            fontSize: record.key === 1000 ? '18px' : null,
          },
          
        };
      },
    },
    {
      title: 
          <Tooltip placement="top" title={"Checks if the chunks retrieved are related to the user query or not"}>
            Response Relevance    
          </Tooltip>,
      dataIndex: 'responseRelevance',
      key: 'responseRelevance',
      width: tableScrollableColumnWidth,
      onCell: (record) => {
        let val = ""
        if(typeof record.responseRelevance === 'object'){
          val = record.responseRelevance.props.children
          val = val.slice(0,-1)
          val = Number(val)
          if(typeof val !== NaN){
            if(val>=0 && val<=20){
              val = avgPercentageText.danger
            }
            else if(val>=21 && val<=79){
              val = avgPercentageText.caution
            }
            else{
              val = avgPercentageText.safe
            }
          }
        }

        return {
          style: {
            color: typeof record.responseRelevance === 'object' ? val===avgPercentageText.safe ? "green" : val===avgPercentageText.caution ? "yellow" : "red" : null,
            fontWeight: record.key === 1000 ? 'bold' : null,
            fontSize: record.key === 1000 ? '18px' : null,
          },
          
        };
      },
    },
    {
      title: 
          <Tooltip placement="top" title={"Context Relevance checks if the contexts retrieved are related to the user query or not"}>
            Context Relevance
          </Tooltip>,
      dataIndex: 'contextRelevance',
      key: 'contextRelevance',
      width: tableScrollableColumnWidth,

      onCell: (record) => {
        let val = ""
        if(typeof record.contextRelevance === 'object'){
          val = record.contextRelevance.props.children
          val = val.slice(0,-1)
          val = Number(val)
          if(typeof val !== NaN){
            if(val>=0 && val<=20){
              val = avgPercentageText.danger
            }
            else if(val>=21 && val<=79){
              val = avgPercentageText.caution
            }
            else{
              val = avgPercentageText.safe
            }
          }
        }

        return {
          style: {
            color: typeof record.contextRelevance === 'object' ? val===avgPercentageText.safe ? "green" : val===avgPercentageText.caution ? "yellow" : "red" : null,
            fontWeight: record.key === 1000 ? 'bold' : null,
            fontSize: record.key === 1000 ? '18px' : null,
          },
          
        };
      },
    },
    {
      title: 
            <Tooltip placement="top" title={"Factual Accuracy or hallucination metric determines whether your LLM generates factually correct information by comparing the actual_output to the provided context"}>
              Factual Accuracy
            </Tooltip>,
      dataIndex: 'factualAccuracy',
      key: 'factualAccuracy',
      width: tableScrollableColumnWidth,
      onCell: (record) => ({
        style: {
          fontWeight: record.key === 1000 && 'bold' ,
          fontSize: record.key === 1000 ? '18px' : null,
        },
      }),
    },
    {
      title: 
          <Tooltip placement="top" title={" Checks the generated response for toxicity"}>
            Toxicity
          </Tooltip>,
      dataIndex: 'toxicity',
      key: 'toxicity',
      width: tableScrollableColumnWidth,

      onCell: (record) => {
        let val = ""
        if(typeof record.toxicity === 'object'){
          val = record.toxicity.props.children
          val = val.slice(0,-1)
          val = Number(val)
          if(typeof val !== NaN){
            if(val>=0 && val<=10){
              val = avgPercentageText.safe
            }
            else if(val>=11 && val<=20){
              val = avgPercentageText.caution
            }
            else{
              val = avgPercentageText.danger
            }
          }
        }

        return {
          style: {
            color: typeof record.toxicity === 'object' ? val===avgPercentageText.safe ? "green" : val===avgPercentageText.caution ? "yellow" : "red" : null,
            fontWeight: record.key === 1000 ? 'bold' : null,
            fontSize: record.key === 1000 ? '18px' : null,
          },
          
        };
      },
    },
    
    {
      title: 
            <Tooltip placement="top" title={"Grades whether the user's prompt is an attempt to jailbreak (i.e. generate illegal or harmful responses)"}>
              Jailbreak Attempt
            </Tooltip>,
      dataIndex: 'jailbreak_attempt',
      key: 'jailbreak_attempt',
      width: tableScrollableColumnWidth,
      onCell: (record) => {
        let val = ""
        if(typeof record.jailbreak_attempt === 'object'){
          val = record.jailbreak_attempt.props.children
          val = val.slice(0,-1)
          val = Number(val)
          if(typeof val !== NaN){
            if(val>=0 && val<=10){
              val = avgPercentageText.safe
            }
            else if(val>=11 && val<=20){
              val = avgPercentageText.caution
            }
            else{
              val = avgPercentageText.danger
            }
          }
        }

        return {
          style: {
            color: typeof record.jailbreak_attempt === 'object' ? val===avgPercentageText.safe ? "green" : val===avgPercentageText.caution ? "yellow" : "red" : null,
            fontWeight: record.key === 1000 ? 'bold' : null,
            fontSize: record.key === 1000 ? '18px' : null,
          },
          
        };
      },
      
    }

    
  ];


  


  const [tableRowData, setTableRowData] = useState([])

  // Redux
  // const dispatch = useDispatch()
  const { tableData, tableDataLoading, tablePopUPStatus} = useSelector((state) => state.realtimemetrics)

  const tableColumnName = {question: "question", 
  response: "response", 
  context: "context", 
  prompt_injection: {score: "score_prompt_injection", explanation: "explanation_prompt_injection"}, 
  response_completeness: {score: "score_response_completeness", explanation: "explanation_response_completeness"}, 
  response_relevance: {score: "score_response_relevance", explanation: "explanation_response_relevance"}, 
  context_relevance: {score: "score_context_relevance", explanation: "explanation_context_relevance"}, 
  factual_accuracy: {score: "score_factual_accuracy", explanation: "explanation_factual_accuracy"}, 
  toxicity: {score: "score_toxicity", explanation: "explanation_toxicity"},
  jailbreak_attempt: {score: "score_jailbreak_attempted", explanation: "explanation_jailbreak_attempted"}
}

function formatNumberWithOneDecimal(num) {
  // Convert number to string
  var numStr = num.toString();

  // Split the string at the decimal point
  var parts = numStr.split('.');

  // If there is only one part (no decimal point)
  if (parts.length === 1) {
      return Number(parts[0]); // Return the number as is
  } else {
      // Concatenate the first part with the first character of the second part
      return Number(parts[0] + '.' + parts[1].charAt(0));
  }
}

const avgPercentageText = {safe: "Safe", caution: "Caution", danger: "Danger"}


  useEffect(() => {
    let arr = []
      let responseCompletenessAvg = 0
      let promptInjectionAvg = 0
      let responseRelevanceAvg = 0
      let contextRelevanceAvg = 0
      let factualAccuracyAvg = 0
      let toxicityAvg = 0
      let jailBreakAttemptAvg = 0
      let count = 0



      if(tableData.length>0){
          tableData.forEach((value, i) => {
            count += 1
            responseCompletenessAvg = responseCompletenessAvg + (value.data[tableColumnName.response_completeness.score] ? formatNumberWithOneDecimal(value.data[tableColumnName.response_completeness.score]) : value.data[tableColumnName.response_completeness.score]===0 ? formatNumberWithOneDecimal(value.data[tableColumnName.response_completeness.score]) : 0)
            promptInjectionAvg = promptInjectionAvg + (value.data[tableColumnName.prompt_injection.score] ? formatNumberWithOneDecimal(value.data[tableColumnName.prompt_injection.score]) : value.data[tableColumnName.prompt_injection.score]===0 ? formatNumberWithOneDecimal(value.data[tableColumnName.prompt_injection.score]) : 0)
            responseRelevanceAvg = responseRelevanceAvg + (value.data[tableColumnName.response_relevance.score] ? formatNumberWithOneDecimal(value.data[tableColumnName.response_relevance.score]) : value.data[tableColumnName.response_relevance.score]===0 ? formatNumberWithOneDecimal(value.data[tableColumnName.response_relevance.score]) : 0)
            contextRelevanceAvg = contextRelevanceAvg + (value.data[tableColumnName.context_relevance.score] ? formatNumberWithOneDecimal(value.data[tableColumnName.context_relevance.score]) : value.data[tableColumnName.context_relevance.score]===0 ? formatNumberWithOneDecimal(value.data[tableColumnName.context_relevance.score]) : 0)
            factualAccuracyAvg = factualAccuracyAvg + (value.data[tableColumnName.factual_accuracy.score] ? formatNumberWithOneDecimal(value.data[tableColumnName.factual_accuracy.score]) : value.data[tableColumnName.factual_accuracy.score]===0 ? formatNumberWithOneDecimal(value.data[tableColumnName.factual_accuracy.score]) : 0)
            toxicityAvg = toxicityAvg + (value.data[tableColumnName.toxicity.score] ? formatNumberWithOneDecimal(value.data[tableColumnName.toxicity.score]) : value.data[tableColumnName.toxicity.score]===0 ? formatNumberWithOneDecimal(value.data[tableColumnName.toxicity.score]) : 0)
            jailBreakAttemptAvg = jailBreakAttemptAvg + (value.data[tableColumnName.jailbreak_attempt.score] ? formatNumberWithOneDecimal(value.data[tableColumnName.jailbreak_attempt.score]) : value.data[tableColumnName.jailbreak_attempt.score]===0 ? formatNumberWithOneDecimal(value.data[tableColumnName.jailbreak_attempt.score]) : 0)


            let ques = "" 
            let ques1 = ""
            let ques2 = ""
            if(value[tableColumnName.question]){
              ques = value[tableColumnName.question].split(/\s+/);
              if(ques.length>0){
                ques1 = ques.slice(0, 10).join(" ");
                ques2 = ques.slice(10).join(" ");
              }
            }

            let ans = "" 
            let ans1 = ""
            let ans2 = ""
            if(value[tableColumnName.response]){
              ans = value[tableColumnName.response].split(/\s+/);
              if(ans.length>0){
                ans1 = ans.slice(0, 10).join(" ");
                ans2 = ans.slice(10).join(" ");
              }
            }

            let contxt = "" 
            let contxt1 = ""
            let contxt2 = ""
            if(value[tableColumnName.context]){
              contxt = value[tableColumnName.context].split(/\s+/);
              if(contxt.length>0){
                contxt1 = contxt.slice(0, 10).join(" ");
                contxt2 = contxt.slice(10).join(" ");
              }
            }

            arr.push({
              key: i.toString(),
              question: value[tableColumnName.question] ? value[tableColumnName.question] : "--",
              answer: value[tableColumnName.response] ? ans1 : "--",
              context: value[tableColumnName.context] ? contxt1 : "--",
              factualAccuracy: value.data[tableColumnName.factual_accuracy.score] ? formatNumberWithOneDecimal(value.data[tableColumnName.factual_accuracy.score]) : value.data[tableColumnName.factual_accuracy.score]===0 ? formatNumberWithOneDecimal(value.data[tableColumnName.factual_accuracy.score]) : "--",
              responseRelevance: value.data[tableColumnName.response_relevance.score] ? formatNumberWithOneDecimal(value.data[tableColumnName.response_relevance.score]) : value.data[tableColumnName.response_relevance.score]===0 ? formatNumberWithOneDecimal(value.data[tableColumnName.response_relevance.score]) : "--",

              responseCompleteness: value.data[tableColumnName.response_completeness.score] ? formatNumberWithOneDecimal(value.data[tableColumnName.response_completeness.score]) : value.data[tableColumnName.response_completeness.score]===0 ? formatNumberWithOneDecimal(value.data[tableColumnName.response_completeness.score]) : "--",
              contextRelevance: value.data[tableColumnName.context_relevance.score] ? formatNumberWithOneDecimal(value.data[tableColumnName.context_relevance.score]) : value.data[tableColumnName.context_relevance.score]===0 ? formatNumberWithOneDecimal(value.data[tableColumnName.context_relevance.score]) : "--",
              promptInjection: value.data[tableColumnName.prompt_injection.score] ? formatNumberWithOneDecimal(value.data[tableColumnName.prompt_injection.score]) : value.data[tableColumnName.prompt_injection.score]===0 ? formatNumberWithOneDecimal(value.data[tableColumnName.prompt_injection.score]) : "--",
              toxicity: value.data[tableColumnName.toxicity.score] ? formatNumberWithOneDecimal(value.data[tableColumnName.toxicity.score]) : value.data[tableColumnName.toxicity.score]===0 ? formatNumberWithOneDecimal(value.data[tableColumnName.toxicity.score]) : "--",
              jailbreak_attempt: value.data[tableColumnName.jailbreak_attempt.score] ? formatNumberWithOneDecimal(value.data[tableColumnName.jailbreak_attempt.score]) : value.data[tableColumnName.jailbreak_attempt.score]===0 ? formatNumberWithOneDecimal(value.data[tableColumnName.jailbreak_attempt.score]) : "--",
              children: [
                {
                  key: i.toString() + i.toString(),
                  question: value[tableColumnName.question] ? "" : "",
                  answer: value[tableColumnName.response] ? ans2 : "--",
                  context: value[tableColumnName.context] ? contxt2 : "--",

                  promptInjection: value.data[tableColumnName.prompt_injection.explanation] ? value.data[tableColumnName.prompt_injection.explanation] : "--",
                  responseCompleteness: value.data[tableColumnName.response_completeness.explanation] ? value.data[tableColumnName.response_completeness.explanation] : "--",
                  responseRelevance: value.data[tableColumnName.response_relevance.explanation] ? value.data[tableColumnName.response_relevance.explanation] : "--",
                  contextRelevance: value.data[tableColumnName.context_relevance.explanation] ? value.data[tableColumnName.context_relevance.explanation] : "--",
                  factualAccuracy: value.data[tableColumnName.factual_accuracy.explanation] ? value.data[tableColumnName.factual_accuracy.explanation] : "--",
                  toxicity: value.data[tableColumnName.toxicity.explanation] ? value.data[tableColumnName.toxicity.explanation] : "--",
                  jailbreak_attempt: value.data[tableColumnName.jailbreak_attempt.explanation] ?  value.data[tableColumnName.jailbreak_attempt.explanation] : "--",
                }
              ]

            })

          });

          factualAccuracyAvg = formatNumberWithOneDecimal((factualAccuracyAvg/count) * 100)
          responseRelevanceAvg = formatNumberWithOneDecimal((responseRelevanceAvg/count) * 100) 
          responseCompletenessAvg = formatNumberWithOneDecimal((responseCompletenessAvg/count) * 100)
          contextRelevanceAvg = formatNumberWithOneDecimal((contextRelevanceAvg/count) * 100)
          promptInjectionAvg = formatNumberWithOneDecimal((promptInjectionAvg/count) * 100)
          toxicityAvg = formatNumberWithOneDecimal((toxicityAvg/count) * 100)
          jailBreakAttemptAvg = String(formatNumberWithOneDecimal((jailBreakAttemptAvg/count) * 100))

          // console.log(responseCompletenessAvg)

          arr.push({
              key: 1000,
              question: "",
              answer: "",
              context: "",
              factualAccuracy: factualAccuracyAvg + "%" ,
              responseCompleteness: 
                <Tooltip placement="top" title={(responseCompletenessAvg>=0 && responseCompletenessAvg<=20) ? avgPercentageText.danger : (responseCompletenessAvg>=21 && responseCompletenessAvg<=79) ? avgPercentageText.caution : avgPercentageText.safe}>
                  {responseCompletenessAvg + "%" }
                </Tooltip>,
              contextRelevance: 
                <Tooltip placement="top" title={(contextRelevanceAvg>=0 && contextRelevanceAvg<=20) ? avgPercentageText.danger : (contextRelevanceAvg>=21 && contextRelevanceAvg<=79) ? avgPercentageText.caution : avgPercentageText.safe}>
                  {contextRelevanceAvg + "%" }
                </Tooltip>,
              responseRelevance: 
                <Tooltip placement="top" title={(responseRelevanceAvg>=0 && responseRelevanceAvg<=20) ? avgPercentageText.danger : (responseRelevanceAvg>=21 && responseRelevanceAvg<=79) ? avgPercentageText.caution : avgPercentageText.safe}>
                  {responseRelevanceAvg + "%" }
                </Tooltip>,
              // responseRelevance: responseRelevanceAvg + "%" ,

              


              toxicity:  
                <Tooltip placement="top" title={(toxicityAvg>=0 && toxicityAvg<=10) ? avgPercentageText.safe : (toxicityAvg>=11 && toxicityAvg<=20) ? avgPercentageText.caution : avgPercentageText.danger}>
                  {toxicityAvg + "%" }
                </Tooltip>,
              jailbreak_attempt: 
                <Tooltip placement="top" title={(jailBreakAttemptAvg>=0 && jailBreakAttemptAvg<=10) ? avgPercentageText.safe : (jailBreakAttemptAvg>=11 && jailBreakAttemptAvg<=20) ? avgPercentageText.caution : avgPercentageText.danger}>
                  
                  {jailBreakAttemptAvg + "%"}
                </Tooltip>,
              promptInjection: 
                <Tooltip placement="top" title={(promptInjectionAvg>=0 && promptInjectionAvg<=10) ? avgPercentageText.safe : (promptInjectionAvg>=11 && promptInjectionAvg<=20) ? avgPercentageText.caution : avgPercentageText.danger}>
                  {promptInjectionAvg + "%" }
                </Tooltip>,
              
          })

        setTableRowData(arr)
      }
      else{
        setTableRowData([])
      }
  }, [tableData])


  // const scrollX = 
  
  

  return (
    <>
    {
      tableDataLoading === null 
      ? 
      <div>
         <Result
          // status="error"
          icon={<FrownTwoTone />}
          title="Failed to fecth data"
          subTitle="Sorry, we are still working on it"
        />
      </div>
      :

      <Table
        columns={columns}
        dataSource={tableRowData}
        bordered={true}
        size={"small"}
        rowClassName="e__matrix__table__row"

        scroll={tablePopUPStatus ? 
          {
          x: 1900,
          y: 530
          }
        :
          {
            x: 1500,
            y: 500,
          }
        }


        loading={tableDataLoading ? true : false}
      />

    }
    </>
  );
};
export default RealTimeMetricsTable;

