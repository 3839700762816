
import { Outlet, Navigate } from "react-router-dom"


const ProtectedRoutes = () => {
    if (localStorage.getItem("auth_token") != null) {
        return <Outlet />
    } else {
        return <Navigate to={"/"} />
    }
}

export default ProtectedRoutes