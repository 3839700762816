import React from "react";
import { IoCloseOutline } from "react-icons/io5";
// import EvaluationTable from "../EvaluationTable/EvaluationTable";
import QueryMetricsTable from "../QueryMetrics/QueryMetricsTable";

import { useDispatch } from "react-redux";
import { setQueryMetricsTablePopUPStatus } from "../../features/QueryMetricsPage/QueryMetricsPageSlice";

const EvaluationMatrixPopUp = () => {
    const dispatch = useDispatch()

  return (
  <div class="table__popup__main" >
    <div className="table__content">
        <div className="table__popup__close__box">
            <div onClick={() => dispatch(setQueryMetricsTablePopUPStatus(false))} className="table__popup__close">
                <IoCloseOutline size={20}/>
            </div>
        </div>
        <div>
            <QueryMetricsTable />
        </div>
    </div>
  </div>
  )
};

export default EvaluationMatrixPopUp;
