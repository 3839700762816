import React from "react";
import { IoCloseOutline } from "react-icons/io5";
import { BiCollapse } from "react-icons/bi";
import RealTimeMetricsTable from "./RealTimeMetricsTable";

import { useDispatch } from "react-redux";
import { setTablePopUPStatus } from "../../features/RealTimeMetricsPage/RealTimeMetricsPageSlice";


const RealTimeMetricsTablePopUp = () => {
    const dispatch = useDispatch()

  return (
  <div class="table__popup__main" >
    <div className="table__content">
        <div className="table__popup__close__box">
            <div className="table__popup__close" onClick={() => dispatch(setTablePopUPStatus(false))}>
                <IoCloseOutline size={20}/>
            </div>
        </div>
        <div>
            <RealTimeMetricsTable />
        </div>
    </div>
  </div>
  )
};

export default RealTimeMetricsTablePopUp;
