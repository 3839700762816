import React from "react";

import Navbar from "../../components/Navbar/Navbar";
import Sector from "../../components/Sector/Sector";

const SectorPage = () => {
  return (
    <div className="sector__page__main__box">
      <div className="sector__page__content__box">
        <Navbar />
        <Sector />
      </div>
    </div>
  );
};

export default SectorPage;
