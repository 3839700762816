import React, {useState, useEffect} from 'react'

import {Link} from 'react-router-dom'

import { editTitleApi } from '../../API/BackendAPI'
import { Tooltip } from 'antd';

import newChat from "../svg-store/sidebar/newChat.svg"
import selectorInfo from "../svg-store/sidebar/selectorInfo.svg"
import downArrow from "../svg-store/sidebar/downArrow.svg"
import upArrow from "../svg-store/sidebar/upArrow.svg"
import chatIcon from "../svg-store/sidebar/chatIcon.svg"
import checkSign from "../svg-store/sidebar/checkSign.svg"
import editTitle from "../svg-store/technicaladvisor/editTitle.svg"
import deleteTitle from "../svg-store/technicaladvisor/deleteTitle.svg"

// Yes Tick Icon
import { BiCheck } from "react-icons/bi";
// Cancel Tick Icon
import { AiOutlineClose } from "react-icons/ai";


// Loading Spinner
import { RotatingLines } from  'react-loader-spinner'

const Sidebar = ({oldMessages, onNewChatClick, oldMessagesClick, reloadNewChatOnTitleUpdation, 
    chatResponseStatus, olderChatsReceivedStatus, deleteChatModalController, chatIDToDelete, chatTitleToDelete}) => {
    const [sectorBoxStatus, setSectorBoxStatus] = useState(false)
    // Current Active Chat ID
    const [currentActiveChatID, setCurrentActiveChatID] = useState(null)

    useEffect(() => {
        setCurrentActiveChatID(Number(sessionStorage.getItem("chat_id")))
    }, [])

    // Edit Title Input
    const [editTitleInput, setEditTitleInput] = useState("")

    // Edit Button Click Status (For showing Yes and No Tick)
    const [isEditButtonClicked, setIsEditButtonClicked] = useState(false)

    // When Title is updated then update the State
    const [reloadNewChatOnTitleUpdationStatus, setReloadNewChatOnTitleUpdationStatus] = useState(true)

    // console.log(oldMessages)

    // Confirm Button Click Handler
    const confirmBtnClickHandler = async(ucid) => {
        const new_title = editTitleInput.trim()
        setEditTitleInput(new_title)

        const res = await editTitleApi(ucid, new_title)
        if(res.status){
            // Reload the older chat on Navbar
            setReloadNewChatOnTitleUpdationStatus(true)
            // Sending this value to "TechnicalAdvisorUI"
            reloadNewChatOnTitleUpdation(reloadNewChatOnTitleUpdationStatus)
            setReloadNewChatOnTitleUpdationStatus(true)

            setIsEditButtonClicked(false)
        }
    }

    // Cancel Button Click Handler
    const cancelBtnClickHandler = () => {
        // Close the edit Button
        setIsEditButtonClicked(false)
    }

    const extractingTitleFromProvidedChatArray = (message_array, chat_id) => {
        let flag = 0
        for(let i=0; i<message_array.length; i++){
            if(message_array[i][1].length > 0){
                for(let j=0; j<message_array[i][1].length; j++){
                    if(Number(message_array[i][1][j].ucid) === Number(chat_id)){
                        flag = 1
                        return message_array[i][1][j]["title"]
                    }
                }
            }
        }
        if(flag === 0){
            return "Something went wrong"
        }
    }

    // Edit Button Click Handler
    const editBtnClickHandler = () => {
        // Accessing the current chat's title
        let rec_title = extractingTitleFromProvidedChatArray(oldMessages, currentActiveChatID)
        setEditTitleInput(rec_title)

        // Edit Button Status
        setIsEditButtonClicked(true)
    }

    // Handling New Chat Button Click
    const newChatHandler = async () => {
        onNewChatClick()
        setCurrentActiveChatID(null)

        
        // let x = 0
        // const makeAPICall = async () => {
        //     const myHeaders = new Headers();
        //     myHeaders.append("Content-Type", "application/json");

        //     const raw = JSON.stringify({
        //     "email": "km@gmail.com",
        //     "password": "valiance@121"
        //     });

        //     const requestOptions = {
        //     method: "POST",
        //     headers: myHeaders,
        //     body: raw,
        //     redirect: "follow"
        //     };

        //     const res = await fetch("https://km-backend-newdb-til3ep3nhq-el.a.run.app/api/login_dummy", requestOptions)
        //     const data = await res.json();
        //     if(res.ok){
        //         x += 1
        //         console.log(data.message.email, x)
        //     }
        // }


        // const MAX_CALLS_PER_MINUTE = 2;
        // const INTERVAL = 4000; // 10 seconds in milliseconds
        // const dataArr = ["dummy", "dummy", "dummy", "dummy", "dummy", "dummy", "dummy", "dummy", "dummy"]
        // const TOTAL_API_CALLS = dataArr.length;

        // let callCount = 0;
        // let totalCalls = 0;
        // let lastCallTime = 0;




        // function parentFun() {
        //     const now = Date.now();

        //     // Check if 1 minute has elapsed since the last call
        //     if (now - lastCallTime >= INTERVAL) {
        //         // Reset callCount and lastCallTime if 1 minute has passed
        //         callCount = 0;
        //         lastCallTime = now;
        //     }

        //     // Check if the maximum number of calls per minute has been reached
        //     if (callCount >= MAX_CALLS_PER_MINUTE) {
        //         // Wait until the next minute before making the API call
        //         const timeUntilNextMinute = INTERVAL - (now - lastCallTime);
        //         setTimeout(parentFun, timeUntilNextMinute);
        //         console.log(timeUntilNextMinute)
        //         return;
        //     }

        //     // Make the API call
        //     // Replace this with your actual API call code
        //     console.log("Calling API...", totalCalls, callCount);
        //     makeAPICall(dataArr[totalCalls])

        //     // Increment callCount and totalCalls
        //     callCount++;
        //     totalCalls++;

        //     // Check if total API calls reached the limit
        //     if (totalCalls >= TOTAL_API_CALLS) {
        //         console.log("Total API calls reached. Terminating...");
        //         return;
        //     }

        //     // Call the API again if not at the maximum limit and total calls limit not reached
            
        //     if (totalCalls < TOTAL_API_CALLS) {
        //         parentFun();
        //     }
        // }

        // // Start calling the API
        // parentFun();

    }

     // Handle data on Clicking Link
     const linkClickHandler = async (chat_id) => {

        let current_chat = 0
        current_chat = Number(sessionStorage.getItem("chat_id"))
        // console.log(current_chat, chat_id)

        // If current chat and clicked chat id is same then do not restore older chats
        if(Number(current_chat) !== Number(chat_id)){
            // console.log("hello", currentActiveChatID, chat_id)
            oldMessagesClick(chat_id)
            setCurrentActiveChatID(chat_id)
        }
        // If we are editing the chat title then do not restore chat
        else if(!isEditButtonClicked && Number(current_chat) !== Number(chat_id)){
            oldMessagesClick(chat_id)
            setCurrentActiveChatID(chat_id)
        }

        

        // let rec_title = extractingTitleFromProvidedChatArray(oldMessages, currentActiveChatID)
        // setEditTitleInput(rec_title)

    }

    // console.log(olderChatsReceivedStatus)



    // Delete Modal Status
    // const [chatIDTitleToDelete, setchatIDTitleToDelete] = useState("")
    // Delete Chat ID
    // const [deleteChatID, setDeleteChatID] = useState()

    // Delete Button Click Handler
    const deleteBtnClickHandler = (chat_id, chat_title) => {
        // console.log("Delete", chat_id, chat_title)
        // setDeleteChatID(delete_chatid)
        // setDeleteModalStatus(true)
        // setchatIDTitleToDelete(chat_title)
        // setDeleteChatID(chat_id)

        chatIDToDelete(Number(chat_id))
        chatTitleToDelete(chat_title)

        deleteChatModalController(true)
    }


    const sectorButtonClickHandler = () => {
        setSectorBoxStatus(!sectorBoxStatus)
    }

    // console.log(oldMessages)

    

    // Handling Enter pressed button on "input" Element
    const confirmBtnEnterHandler = async (event) => {
        if (event.key === 'Enter') {
        confirmBtnClickHandler(Number(sessionStorage.getItem("chat_id")))
        }
    }

    const [showTooltip, setShowTooltip] = useState(false);
    
    function showTooltip1() {
        console.log("hello")
        setShowTooltip(true)
        // document.getElementById("tooltipText").style.visibility = "visible";
    }
    
    function hideTooltip1() {
        console.log("out")
        setShowTooltip(false)
        // document.getElementById("tooltipText").style.visibility = "hidden";
    }
    

    


    return (
        <div className='sidebar__main__box'>
        <div className="sidebar__boxes">

            <div className="sidebar__top">
                <div className='sidebar__newChat'>
                    <button onClick={newChatHandler}>
                        <img src={newChat} alt="new chat" />
                        <span>New Chat</span>
                    </button>
                </div>
                <div className='sidebar__sector'>
                    <div className='sidebar__sector__content'>
                        <span>Select Sector</span>
                        <img src={selectorInfo} alt="info" />
                    </div>
                    <div className='sidebar__sector__dropdown'>
                        <button onClick={sectorButtonClickHandler}>
                            <span>2 Sector selected</span>
                            <img src={sectorBoxStatus ? upArrow : downArrow} alt="down" />
                        </button>

                        <div className={sectorBoxStatus ? "sidebar__sector__dropdown-content" : "sidebar__sector__dropdown--Nonedisplay"} >
                            <Link href="#">
                                <span className='sidebar__sector__dropdown-content--active'>Agriculture & Allied</span>
                                <img src={checkSign} alt="check" />
                            </Link>
                            <Link href="#">
                                <span className='sidebar__sector__dropdown-content--active'>Skill Development, Labour & Employment</span>
                                <img src={checkSign} alt="check" />
                            </Link>
                        </div>

                    </div>
                </div>
                <div className='horizontal__line'></div>
            </div>
            
            <div className="sidebar__bottom">
                <div className='sidebar__chat__boxes'>
                    {
                    !olderChatsReceivedStatus 
                    ? 
                        <div className='sidebar__loading__spinner'>
                            <RotatingLines
                                strokeColor="white"
                                strokeWidth="3"
                                animationDuration="1.2"
                                width="70"
                                visible={true}
                            />
                        </div>
                    :
                        oldMessages.map((elem, i) => (
                            elem[1].length > 0 &&
                            <div key={i} className='sidebar__chat__box'>
                                <span>{elem[0]}</span>
                                {
                                    elem[1].map((obj, j) => (
                                        <button className={currentActiveChatID===Number(obj.ucid) ? "sidebar__chat__box--activebutton" : ""}
                                            onClick={() => linkClickHandler(obj.ucid)} key={j}>
                                            <img src={chatIcon} alt="chat" />
                                            <div className={currentActiveChatID===Number(obj.ucid) ? 
                                                isEditButtonClicked ? "sidebar__chat__title--active--editting" : "sidebar__chat__title--active"
                                                : "sidebar__chat__title"}>

                                                {currentActiveChatID===Number(obj.ucid) && isEditButtonClicked ? 
                                                <input type="text" value={editTitleInput}  className='titleInput'
                                                onChange={(e) => {setEditTitleInput( e.target.value)}} onKeyDown={confirmBtnEnterHandler}/>
                                                :   
                                                obj.title
                                                }

                                            </div>
                                            {currentActiveChatID===Number(obj.ucid) &&
                                                <div className='edit__delete__btns'>
                                                    {isEditButtonClicked ? 
                                                    <>
                                                        {/* Confirm and cancel buttons */}
                                                        <div className='edit__delete__btns--tag'>
                                                            <BiCheck size={24} color="#FFFFFF" onClick={() => {confirmBtnClickHandler(obj.ucid)}}/>
                                                        </div>
                                                        <div className='edit__delete__btns--tag'>
                                                            <AiOutlineClose size={18} color="#FFFFFF" onClick={cancelBtnClickHandler}/>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        {/* Edit and Delete buttons */}
                                                        <div onMouseEnter={showTooltip1} onMouseLeave={hideTooltip1} onClick={editBtnClickHandler} className='edit__delete__btns--tag  edit__button__icon'>
                                                            <Tooltip placement="top" title={"Rename"}>
                                                                <img src={editTitle} alt="edit"/>
                                                            </Tooltip>
                                                            {/* <img src={editTitle} alt="edit"/>
                                                            {showTooltip && <span className="tooltip ">Rename</span>} */}
                                                        </div>
                                                        <div onClick={() => {deleteBtnClickHandler(obj.ucid, obj.title)}} className='edit__delete__btns--tag'>
                                                            <img src={deleteTitle} alt="delete"/>
                                                        </div>
                                                    </>
                                                    }
                                                </div>
                                            }
                                        </button>
                                    ))
                                }
                        </div>
                        ))
                    }
                    
                </div>
            </div>
        </div>
        </div>
    )
    }

export default Sidebar