import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { token as userToken } from "../../Utility/Token";




export const adminDashboardAPI = createAsyncThunk('products/fetch', async (date) => {
    let token = ""
    if (localStorage.getItem("auth_token") != null) {
        token = localStorage.getItem("auth_token")
    }
    const res = await fetch(`${process.env.REACT_APP_MODEL_URL}/admin_home/?start=${date.startDate}&end=${date.endDate}`, {
        credentials: "include",
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` }
    });
    const data = await res.json();
    return data;
});


export const userAccessAPI = createAsyncThunk('useraccess', async (params) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    "uuid": `${params.userID}`
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };
  const res = await fetch(`${process.env.REACT_APP_LOGIN_URL}/api/grant_revoke_user_access`, requestOptions);
  const data = await res.json();
  return data;
})

const adminPageSlice = createSlice({
    name:"adminDashboard",
    
    initialState: {
      status: 'idle',
      userList: [],
      totalChats: -1,
      totalQueries: -1,
      totalUsers: -1,
      userAccessAPIStatus: 'idle',
      unauthorize: false

    },

    reducers: {
    //   setUserList:(state,action)=>{
    //     state.selectedNewsData=action.payload
    //   },
    //   selectedNewsRoomID:(state,action)=>{
    //     state.selectedNewsID=action.payload
    //   }
    },
    extraReducers: (builder) => {
      builder
        // Admin Dashboard
        .addCase(adminDashboardAPI.pending, (state) => {
          state.status = 'loading';
          state.userList=[]
          state.totalChats = -1
          state.totalQueries = -1
          state.totalUsers = -1
        })
        .addCase(adminDashboardAPI.fulfilled, (state, action) => {
          state.status = 'succeeded';
          console.log("action.payload: ", action.payload)
          if(action.payload.user_data){
            let new_arr = action.payload.user_data
            new_arr.sort((a, b) => {
              return a.user_id - b.user_id;
            });
            state.userList = new_arr;
            state.totalChats = action.payload.total_chats
            state.totalQueries = action.payload.total_queries/2
            state.totalUsers = new_arr.length
          }
          else{
            state.unauthorize = true
          }
        })
        .addCase(adminDashboardAPI.rejected, (state) => {
          state.status = 'failed';
          state.userList=[]
          state.totalChats = -1
          state.totalQueries = -1
          state.totalUsers = -1
        })

        // User Access
        .addCase(userAccessAPI.pending, (state) => {
          state.userAccessAPIStatus = 'loading';
        })
        .addCase(userAccessAPI.fulfilled, (state, action) => {
          state.userAccessAPIStatus = 'succeeded';
        })
        .addCase(userAccessAPI.rejected, (state) => {
          state.userAccessAPIStatus = 'failed';
        })
        
    },
})

// Reducer variables
// export const {setSelectedNewsData, selectedNewsRoomID} = adminPageSlice.actions;

export default adminPageSlice.reducer;