import React, {useState} from "react";

import unTicked from "../svg-store/sector/ellipseUnticked.svg";
import ticked from "../svg-store/sector/ellipseTicked.svg";
import tickedBtn from "../image/sector/ticked.png";
import continueBtnWhite from "../svg-store/sector/continueBtnWhite.svg";
import continueBtnNonWhite from "../svg-store/sector/continueBtnNonWhite.svg";

// import agriculture from "../svg-store/sector/sectors-svg/agriculture.svg";
// import education from "../svg-store/sector/sectors-svg/education.svg";
// import employement from "../svg-store/sector/sectors-svg/employment.svg";
// import health from "../svg-store/sector/sectors-svg/health.svg";
// import manufacturing from "../svg-store/sector/sectors-svg/manufacturing.svg";
// import msme from "../svg-store/sector/sectors-svg/msme.svg";
// import tourism from "../svg-store/sector/sectors-svg/tourism.svg";
// import energy from "../svg-store/sector/sectors-svg/energy.svg";
// import urbanization from "../svg-store/sector/sectors-svg/urbanization.svg";
// import waterSanitization from "../svg-store/sector/sectors-svg/waterSanitization.svg";

import agriculture from "../image/sector/sectors/agriculture.png"
import skillDevelopment from "../image/sector/sectors/skill-development.png"

import { useNavigate } from "react-router";

const sectorsList = [
  { title: "Agriculture", image: agriculture },
  { title: "Skill Development, Labour & Employment", image: skillDevelopment },
];

const Sector = () => {

  const navigate = useNavigate()
  const [selectedSectorIndex, setSelectedSectorIndex] = useState([])

  const sectorButtonClickHandler = (selectedIndex) => {
    // setSelectedSectorIndex(selectedIndex)
    if(selectedSectorIndex.includes(selectedIndex)){
      setSelectedSectorIndex(selectedSectorIndex.filter(i => i !== selectedIndex))
    }
    else{
      setSelectedSectorIndex(prevArray => [...prevArray, selectedIndex])
    }
  }

  const allSectorClickHandler = () => {
    // setSelectedSectorIndex(50)
    if(selectedSectorIndex.includes(50)){
      setSelectedSectorIndex(selectedSectorIndex.filter(i => i !== 50))
    }
    else{
      setSelectedSectorIndex(prevArray => [...prevArray, 50])
    }
  }

  const continueBtnClickHandler = () => {
      navigate("/technicaladvisor")
  }

  return (
    <div className="sector__main__box">
      <div className="sector__top">
        <div className="sector__welcome__msg">
          <span>{String.fromCodePoint("0x1f44B")}</span>
          <span>Hello Akshat</span>
        </div>
        <span>
          I'm NITI Aayog E-Mitra, here to assist you with sharing best practices and policies in key sectors such as Skilling, Livelihood, Labor & Welfare, and Agriculture.
        </span>
      </div>

      <div className="sector__bottom">
        <div className="sector__boxes">

         

          {
            sectorsList.map((sector, i) => (
                <div key={i} className="sector__box sector__box--activeColor">
                <button onClick={() => sectorButtonClickHandler(i)}>
                    <div className="sector__box__leftContent">
                      <div className="sector__box__leftContent--image">
                        <img src={sector.image} alt={sector.title} />
                      </div>
                      <span>{sector.title}</span>
                    </div>
                    <img src={ticked} alt={sector.title} />
                    {/* <img src={selectedSectorIndex.includes(i)  ? tickedBtn : unTicked} alt={sector.title} /> */}
                </button>
                </div>
            ))
          }

          
          
        </div>

          <div className="sector__box__continueBtn">
            <div className="sector__page__continue">
              <button onClick={continueBtnClickHandler} className="sector__box__btn">
                <span >Continue</span>
                <img src={continueBtnWhite} alt="continue" />
              </button>
            </div>
          </div>


      </div>

      <div className="page__fixed__footer__bottom">
        <span>&#169; {new Date().getFullYear()},
              powered by &nbsp;
              <a className='technicaladvisor__footer--name' href="https://valiancesolutions.com/" target="_blank" rel="noreferrer" >
                Valiance Solutions
              </a>
        </span>
      </div>
      
    </div>
  );
};

export default Sector;
