import React, {useRef, useState, useEffect} from 'react'
import { BarChart } from '@mui/x-charts/BarChart';
import filterIcon from "../svg-store/userInquires/filter.svg"
import { MdCancel } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import selectedIcon from "../svg-store/userstatistics/selected.svg"
import { SmileOutlined } from '@ant-design/icons';
import { Button, Result } from 'antd';

import { RotatingTriangles, FidgetSpinner } from 'react-loader-spinner'
import RotateLoader from "react-spinners/RotateLoader";

// Date Calendra
import { DatePicker, ConfigProvider, Tooltip, message } from 'antd';
// import { DatePicker, ConfigProvider, message } from 'antd';
import dayjs from 'dayjs';

import UserStatGraphPlot from './UserStatGraphPlot';

// Time ago
import {subMonths, format, subDays, subWeeks, subYears } from 'date-fns'

// Backend APIs
import { userStatAPI } from "../../features/UserStatPage/UserStatPageSlice";
import { useDispatch, useSelector } from "react-redux";

const UserStatGraph = () => {
  const start_date = "1/1/21"
  const curr_date = new Date();
  let currentDate = curr_date.getDate().toString() + "/" + (curr_date.getMonth() + 1).toString() + "/" + curr_date.getFullYear().toString().slice(2)

  const queryCountDefaultValue = "quarter_" + curr_date.getFullYear().toString()

  // Redux (for Query Count)
  const {queryCountAPIStatus, chartData, chartLabel} = useSelector((state)=>state.userstats)
  useEffect(() => {
    if(queryCountAPIStatus === 'idle'){
      dispatch(userStatAPI({filter: queryCountDefaultValue}))
    }

    if(queryCountAPIStatus === 'failed'){
      message.error("Failed to fetch data!!", [4]);
    }
  }, [queryCountAPIStatus])



  const [queriesCountData, setQueriesCountData] = useState([2, 2, 2])
  const [userNameData, setUserNameData] = useState([2, 3, 4])

  // useEffect(() => {
  //   if(userDataList !== undefined && userDataList.length>0){
  //     let sessionArr = []
  //     let queryArr = []
  //     let userArr = []

  //     let flag_val = 1

  //     userDataList.forEach((elem) => {
        
  //       let userName = elem.user_name
  //       if(userName.length === 0){
  //         userName = `Guest User ${flag_val}`
  //         flag_val++
  //       }
  //       sessionArr.push(elem.chat_count)
  //       queryArr.push(elem.query_count)
  //       userArr.push(userName)
  //     })

  //     setQueriesCountData(queryArr)
  //     setUserNameData(userArr)
  //   }

  // }, [userDataList])




  const dispatch = useDispatch()

  
  
  // Filter useState
  // 1st Filter
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [filterClickedStatus, setFilterClickedStatus] = useState(false)
  const [subFilterClickedStatus, setSubFilterClickedStatus] = useState(false)
  const [selectedDate, setSelectedDate] = useState([])
  const [selectedFilterDate, setSelectedFilterDate] = useState(0)

  // 2nd Filter
  const [isDatePickerOpen2, setIsDatePickerOpen2] = useState(false);
  const [filterClickedStatus2, setFilterClickedStatus2] = useState(false)
  const [subFilterClickedStatus2, setSubFilterClickedStatus2] = useState(false)
  const [selectedDate2, setSelectedDate2] = useState([])
  const [selectedFilterDate2, setSelectedFilterDate2] = useState(0)

  // 3rd Filter
  const [isDatePickerOpen3, setIsDatePickerOpen3] = useState(false);
  const [filterClickedStatus3, setFilterClickedStatus3] = useState(false)
  const [subFilterClickedStatus3, setSubFilterClickedStatus3] = useState(false)
  const [selectedDate3, setSelectedDate3] = useState([])
  const [selectedFilterDate3, setSelectedFilterDate3] = useState(0)

  // useRef
  let menuRef1 = useRef();
  let menuRef2 = useRef();
  let menuRef3 = useRef();


  const filterClickHandler = (flag) => {
    if(flag === "queryUsageGraph"){
      if(filterClickedStatus){
        setSubFilterClickedStatus(false)
      }
      // setFilterClickedStatus(!filterClickedStatus)
      setSubFilterClickedStatus(!subFilterClickedStatus)
    }
    else if(flag === "latencyMonthGraph"){
      if(filterClickedStatus2){
        setSubFilterClickedStatus2(false)
      }
      // setFilterClickedStatus2(!filterClickedStatus2)
      setSubFilterClickedStatus2(!subFilterClickedStatus2)
    }
    else if(flag === "tokenPerMonthGraph"){
      if(filterClickedStatus3){
        setSubFilterClickedStatus3(false)
      }
      // setFilterClickedStatus2(!filterClickedStatus2)
      setSubFilterClickedStatus3(!subFilterClickedStatus3)
    }
  }

  const clearFilter = (element) => {
    if(element === "queryUsageGraph"){
      setSelectedDate([])
      dispatch(userStatAPI({startDate: start_date, endDate: currentDate}))
      setSubFilterClickedStatus(false)
      setFilterClickedStatus(false)
      setSelectedFilterDate(0)
    }
    else if(element === "latencyMonthGraph"){
      setSelectedDate2([])
      dispatch(userStatAPI({startDate: start_date, endDate: currentDate}))
      setSubFilterClickedStatus2(false)
      setFilterClickedStatus2(false)
      setSelectedFilterDate2(0)
    }
    else if(element === "tokenPerMonthGraph"){
      setSelectedDate3([])
      dispatch(userStatAPI({startDate: start_date, endDate: currentDate}))
      setSubFilterClickedStatus3(false)
      setFilterClickedStatus3(false)
      setSelectedFilterDate3(0)
    }
  }

  const clearGraphFilter = (element) => {
    if(element === "queryUsageGraph"){
      setSelectedDate([])
      setSubFilterClickedStatus(false)
      setFilterClickedStatus(false)
      setSelectedFilterDate(0)
      dispatch(userStatAPI({filter: queryCountDefaultValue}))
    }
  }

  // Time Ago
  const customDateHandler = (flag, elem) => {
    if(elem === "queryUsageGraph"){
      if(flag === "today"){
        setSelectedFilterDate(1)
        if(selectedFilterDate !== 1){
          dispatch(userStatAPI({startDate: currentDate, endDate: currentDate}))
          setSelectedDate([formatDateToDisplay(currentDate), formatDateToDisplay(currentDate)])
        }
      }
      else if(flag === "yesterday"){
        setSelectedFilterDate(2)
        if(selectedFilterDate !== 2){
          let yesterday = subDays(new Date(), 1);
          yesterday = format(yesterday, "dd/MM/yy")
          dispatch(userStatAPI({startDate: yesterday, endDate: currentDate}))
          setSelectedDate([formatDateToDisplay(yesterday), formatDateToDisplay(currentDate)])
        }
      }
      else if(flag === "week"){
        setSelectedFilterDate(3)
        if(selectedFilterDate !== 3){
          let week = subWeeks(new Date(), 1);
          week = format(week, "dd/MM/yy")
          dispatch(userStatAPI({startDate: week, endDate: currentDate}))
          setSelectedDate([formatDateToDisplay(week), formatDateToDisplay(currentDate)])
        }
      }
      else if(flag === "month"){
        setSelectedFilterDate(4)
        if(selectedFilterDate !== 4){
          let oneMonthAgo = subMonths(new Date(), 1);
          oneMonthAgo = format(oneMonthAgo, "dd/MM/yy")

          dispatch(userStatAPI({startDate: oneMonthAgo, endDate: currentDate}))
          
          setSelectedDate([formatDateToDisplay(oneMonthAgo), formatDateToDisplay(currentDate)])
        }
      }
      else{
        setSelectedFilterDate(5)
        if(selectedFilterDate !== 4){
          let year = subYears(new Date(), 1);
          year = format(year, "dd/MM/yy")

          dispatch(userStatAPI({startDate: year, endDate: currentDate}))
          
          setSelectedDate([formatDateToDisplay(year), formatDateToDisplay(currentDate)])
        }
      }

      setFilterClickedStatus(false)
      setSubFilterClickedStatus(false)
    }
    else if(elem === "latencyMonthGraph"){
      if(flag === "today"){
        setSelectedFilterDate2(1)
        if(selectedFilterDate2 !== 1){
          dispatch(userStatAPI({startDate: currentDate, endDate: currentDate}))
          setSelectedDate2([formatDateToDisplay(currentDate), formatDateToDisplay(currentDate)])
        }
      }
      else if(flag === "yesterday"){
        setSelectedFilterDate2(2)
        if(selectedFilterDate2 !== 2){
          let yesterday = subDays(new Date(), 1);
          yesterday = format(yesterday, "dd/MM/yy")
          dispatch(userStatAPI({startDate: yesterday, endDate: currentDate}))
          setSelectedDate2([formatDateToDisplay(yesterday), formatDateToDisplay(currentDate)])
        }
      }
      else if(flag === "week"){
        setSelectedFilterDate2(3)
        if(selectedFilterDate2 !== 3){
          let week = subWeeks(new Date(), 1);
          week = format(week, "dd/MM/yy")
          dispatch(userStatAPI({startDate: week, endDate: currentDate}))
          setSelectedDate2([formatDateToDisplay(week), formatDateToDisplay(currentDate)])
        }
      }
      else if(flag === "month"){
        setSelectedFilterDate2(4)
        if(selectedFilterDate2 !== 4){
          let oneMonthAgo = subMonths(new Date(), 1);
          oneMonthAgo = format(oneMonthAgo, "dd/MM/yy")

          dispatch(userStatAPI({startDate: oneMonthAgo, endDate: currentDate}))
          
          setSelectedDate2([formatDateToDisplay(oneMonthAgo), formatDateToDisplay(currentDate)])
        }
      }
      else{
        setSelectedFilterDate2(5)
        if(selectedFilterDate2 !== 4){
          let year = subYears(new Date(), 1);
          year = format(year, "dd/MM/yy")

          dispatch(userStatAPI({startDate: year, endDate: currentDate}))
          
          setSelectedDate2([formatDateToDisplay(year), formatDateToDisplay(currentDate)])
        }
      }

      setFilterClickedStatus2(false)
      setSubFilterClickedStatus2(false)
    }
    else if(elem === "tokenPerMonthGraph"){
      if(flag === "today"){
        setSelectedFilterDate3(1)
        if(selectedFilterDate3 !== 1){
          dispatch(userStatAPI({startDate: currentDate, endDate: currentDate}))
          setSelectedDate3([formatDateToDisplay(currentDate), formatDateToDisplay(currentDate)])
        }
      }
      else if(flag === "yesterday"){
        setSelectedFilterDate3(2)
        if(selectedFilterDate3 !== 2){
          let yesterday = subDays(new Date(), 1);
          yesterday = format(yesterday, "dd/MM/yy")
          dispatch(userStatAPI({startDate: yesterday, endDate: currentDate}))
          setSelectedDate3([formatDateToDisplay(yesterday), formatDateToDisplay(currentDate)])
        }
      }
      else if(flag === "week"){
        setSelectedFilterDate3(3)
        if(selectedFilterDate3 !== 3){
          let week = subWeeks(new Date(), 1);
          week = format(week, "dd/MM/yy")
          dispatch(userStatAPI({startDate: week, endDate: currentDate}))
          setSelectedDate3([formatDateToDisplay(week), formatDateToDisplay(currentDate)])
        }
      }
      else if(flag === "month"){
        setSelectedFilterDate3(4)
        if(selectedFilterDate3 !== 4){
          let oneMonthAgo = subMonths(new Date(), 1);
          oneMonthAgo = format(oneMonthAgo, "dd/MM/yy")

          dispatch(userStatAPI({startDate: oneMonthAgo, endDate: currentDate}))
          
          setSelectedDate3([formatDateToDisplay(oneMonthAgo), formatDateToDisplay(currentDate)])
        }
      }
      else{
        setSelectedFilterDate3(5)
        if(selectedFilterDate3 !== 4){
          let year = subYears(new Date(), 1);
          year = format(year, "dd/MM/yy")

          dispatch(userStatAPI({startDate: year, endDate: currentDate}))
          
          setSelectedDate3([formatDateToDisplay(year), formatDateToDisplay(currentDate)])
        }
      }

      setFilterClickedStatus3(false)
      setSubFilterClickedStatus3(false)
    }
  }


  // Format Date
  const formatDateToDisplay = (input_date) => {
    let dateStr = input_date

    // Split the date string into day, month, and year
    var parts = dateStr.split('/');

    // Convert the string parts into integers
    var day = parseInt(parts[0], 10);
    var month = parseInt(parts[1], 10);
    var year = parseInt(parts[2], 10);


    // Define month names
    var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    // Format the date string as "DD Mon YY"
    var formattedDate = ('0' + day).slice(-2) + ' ' + monthNames[month - 1] + ' ' + ('' + year).slice(-2);

    return formattedDate
  }

  // When selecting date from DateTimePicker
  const dateRangeSelectorFunction = (date_data, element) => {
    let st_date = null
    let ed_date = null

    if(element === "queryUsageGraph"){
      if(date_data !== null){
        let year = date_data[0]["$y"].toString().slice(2)
        st_date = date_data[0]["$D"].toString() + "/" + (date_data[0]["$M"] + 1).toString() + "/" +year


        year = date_data[1]["$y"].toString().slice(2)
        ed_date = date_data[1]["$D"].toString() + "/" + (date_data[1]["$M"] + 1).toString() + "/" +year


        setSelectedDate([formatDateToDisplay(st_date), formatDateToDisplay(ed_date)])

        setFilterClickedStatus(false)
        setSubFilterClickedStatus(false)

        dispatch(userStatAPI({startDate: st_date, endDate: ed_date}))
      }
    }
    else if(element === "latencyMonthGraph"){
      if(date_data !== null){
        let year = date_data[0]["$y"].toString().slice(2)
        st_date = date_data[0]["$D"].toString() + "/" + (date_data[0]["$M"] + 1).toString() + "/" +year


        year = date_data[1]["$y"].toString().slice(2)
        ed_date = date_data[1]["$D"].toString() + "/" + (date_data[1]["$M"] + 1).toString() + "/" +year


        setSelectedDate2([formatDateToDisplay(st_date), formatDateToDisplay(ed_date)])

        setFilterClickedStatus2(false)
        setSubFilterClickedStatus2(false)

        dispatch(userStatAPI({startDate: st_date, endDate: ed_date}))
      }
    }
    else if(element === "tokenPerMonthGraph"){
      if(date_data !== null){
        let year = date_data[0]["$y"].toString().slice(2)
        st_date = date_data[0]["$D"].toString() + "/" + (date_data[0]["$M"] + 1).toString() + "/" +year


        year = date_data[1]["$y"].toString().slice(2)
        ed_date = date_data[1]["$D"].toString() + "/" + (date_data[1]["$M"] + 1).toString() + "/" +year


        setSelectedDate3([formatDateToDisplay(st_date), formatDateToDisplay(ed_date)])

        setFilterClickedStatus3(false)
        setSubFilterClickedStatus3(false)

        dispatch(userStatAPI({startDate: st_date, endDate: ed_date}))
      }
    }
  }

  // Date Filter Box Close Handler
  useEffect(() => {

    let handler1 = (e)=>{
      if(subFilterClickedStatus){
        if(!document.getElementsByClassName("filter__box__content1")[0].contains(e.target) 
        && 
        !menuRef1.current.contains(e.target)
        ){
          if (isDatePickerOpen) {
            return;
          }
            setFilterClickedStatus(false);
            setSubFilterClickedStatus(false);
        }    
      }  
    };

    let handler2 = (e)=>{
      if(subFilterClickedStatus2){
        if(!document.getElementsByClassName("filter__box__content2")[0].contains(e.target) 
        && 
        !menuRef2.current.contains(e.target)
        ){
          if (isDatePickerOpen2) {
            return;
          }
            setFilterClickedStatus2(false);
            setSubFilterClickedStatus2(false);
        }    
      }  
    };

    let handler3 = (e)=>{
      if(subFilterClickedStatus3){
        if(!document.getElementsByClassName("filter__box__content3")[0].contains(e.target) 
        && 
        !menuRef3.current.contains(e.target)
        ){
          if (isDatePickerOpen3) {
            return;
          }
            setFilterClickedStatus3(false);
            setSubFilterClickedStatus3(false);
        }    
      }  
    };


    // document.addEventListener("mousedown", handler1);
    document.addEventListener("mousedown", handler2);
    document.addEventListener("mousedown", handler3);
    

    return() =>{
      // document.removeEventListener("mousedown", handler1);
      document.removeEventListener("mousedown", handler2);
      document.removeEventListener("mousedown", handler3);
    }

  });

  // Logic so that user can select (30 days only in DatePicker)
  const disabledDate = (current, { from }) => {
    if (from) {
      return Math.abs(current.diff(from, 'days')) >= 30;
    }
    return false;
  };

  // Date Filter Box Close Handler
  const dateClickHandler = (date_data, element, filter) => {

    if(date_data !== null){

      if(element === "queryUsageGraph"){

        if(filterArr.month === filter){
          const month = month_name_arr[date_data["$M"]]
          const year = date_data["$y"]
          const filterParam = month + "_" + year
          console.log("filterParam: ", filterParam)
          dispatch(userStatAPI({filter: filterParam}))


          const dataToDisplay = month + " " + date_data["$y"]
          setSelectedDate([dataToDisplay, dataToDisplay])
        }
        else if(filterArr.quarter === filter){
          const year = date_data["$y"]
          const filterParam = "quarter_" + year
          console.log("filterParam: ", filterParam)
          dispatch(userStatAPI({filter: filterParam}))

          const dataToDisplay = "Quarterly " + year
          setSelectedDate([dataToDisplay, dataToDisplay])
        }
        else if(filterArr.halfYear === filter){
          const year = date_data["$y"]
          const filterParam = "halfYear_" + year
          console.log("filterParam: ", filterParam)
          dispatch(userStatAPI({filter: filterParam}))

          const dataToDisplay = "Half Yearly " + year
          setSelectedDate([dataToDisplay, dataToDisplay])
        }
        else if(filterArr.year === filter){
          const year = date_data["$y"]
          const filterParam = "year_" + year
          console.log("filterParam: ", filterParam)
          dispatch(userStatAPI({filter: filterParam}))

          const dataToDisplay = "Yearly " + year
          setSelectedDate([dataToDisplay, dataToDisplay])
        }
        else{
          let st_date = null
          let ed_date = null
          let year = date_data[0]["$y"].toString()
          st_date = date_data[0]["$D"].toString() + "/" + (date_data[0]["$M"] + 1).toString() + "/" +year


          year = date_data[1]["$y"].toString()
          ed_date = date_data[1]["$D"].toString() + "/" + (date_data[1]["$M"] + 1).toString() + "/" +year
          const filterParam = st_date + "_" + ed_date
          dispatch(userStatAPI({filter: filterParam}))

          console.log(st_date + "_" +ed_date)
          setSelectedDate([formatDateToDisplay(st_date), formatDateToDisplay(ed_date)])

          
        }

      }

      setFilterClickedStatus(false)
      setSubFilterClickedStatus(false)

    }

  }

  const month_name_arr = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"]
  const filterArr = {month: "Monthly", quarter: "Quarterly", halfYear: "Half Yearly", year: "Yearly"}



 // Map Data
 const labelX1 = "Months"
 const labelY1 = "Latency"
 const xAxis1 = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"]
 const yAxis1 = [2, 3, 8, 3, 7, 9]
 const title1 = "Latency vs Months"

 const labelX2 = "Months"
  const labelY2 = "Token"
  const xAxis2 = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"]
  const yAxis2 = [80, 100, 120, 300, 150, 250]
  const title2 = "Tokens used per month"

  

 

  return (
    <div className='user__statg__main'>
      <div className="user__statg__main__box">

        <div className="user__stat__grap__box">
          <div className='user__stat__graph__heading'>Query Usage Graph</div>
          <div className="user_statg__filter__box__main userinqueries__filter__box--changes">

              <div className="userinquires__filter">
                        
                        <div className="userstats__leftelem">
                        <button ref={menuRef1} onClick={() => filterClickHandler("queryUsageGraph")} className='userinquires__filter__button'>
                            <img src={filterIcon} alt="filter" />
                            <div className='userinquires__filter__badge'>
                                <span>Filter:&nbsp;
                                <span className='filter--color'>
                                    {
                                    selectedDate.length === 0 ?
                                    "DD-MM-YY"
                                    :
                                    selectedDate[0] === selectedDate[1]
                                    ?
                                    `${selectedDate[0]}`
                                    :
                                    `${selectedDate[0]} - ${selectedDate[1]}`
                                    }
                                    
                                </span>
                                </span>
                            </div>
                        </button>
                        
                        {
                            selectedDate.length !== 0 &&
                            <div onClick={() => clearGraphFilter("queryUsageGraph")} className="userstats__cancel__div">
                              <MdCancel size={20} color={"#828282"}/>
                            </div>
                        }
                        </div>


                        {filterClickedStatus && 
                        <div onClick={() => setSubFilterClickedStatus(!subFilterClickedStatus)} className="filter__box__dropdown1">
                            <div className="filter__box__dropdown__elem">
                            <span className="filter__box__dropdown__elem__span">Filter by Date</span>
                            <IoIosArrowForward size={20}/>
                            </div>

                        </div>
                        }
              </div>

              {subFilterClickedStatus &&
                <div className="filter__box__content filter__box__content--changes filter__box__content1">
                        <div className="filter__date__box">

                            <div className="filter__date__elem1">
                            <div className="filter__date__font__heading">Filter by Query Date</div>

                            <div className='filter__date__child__box1 filter__date__font'>
                              Monthly
                              <DatePicker className="antd__datepicker" 
                              onChange={(value) => dateClickHandler(value, "queryUsageGraph", filterArr.month)}
                              minDate={dayjs(start_date)}
                              maxDate={dayjs(new Date())}
                              picker="month" />
                            </div>

                            <div className='filter__date__child__box1 filter__date__font'>
                              Select Quarterly
                              <DatePicker className="antd__datepicker"  
                              onChange={(value) => dateClickHandler(value, "queryUsageGraph", filterArr.quarter)}
                              minDate={dayjs(start_date)}
                              maxDate={dayjs(new Date())}
                              picker="year" />
                            </div>

                            <div className='filter__date__child__box1 filter__date__font'>
                              Half Yearly
                              <DatePicker className="antd__datepicker" 
                              onChange={(value) => dateClickHandler(value, "queryUsageGraph", filterArr.halfYear)} 
                              minDate={dayjs(start_date)}
                              maxDate={dayjs(new Date())}
                              picker="year" />
                            </div>

                            <div className='filter__date__child__box1 filter__date__font'>
                              Yearly
                              <DatePicker className="antd__datepicker" 
                              onChange={(value) => dateClickHandler(value, "queryUsageGraph", filterArr.year)} 
                              minDate={dayjs(start_date)}
                              maxDate={dayjs(new Date())}
                              picker="year" />
                            </div>

                            {/* <div className={selectedFilterDate===1 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                                <button onClick={() => customDateHandler("today", "queryUsageGraph")} className="no__button filter__date__font">
                                Today
                                {selectedFilterDate===1 && <img src={selectedIcon} alt="select" />}
                                </button>
                            </div>
                            <div className={selectedFilterDate===2 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                                <button onClick={() => customDateHandler("yesterday", "queryUsageGraph")} className="no__button filter__date__font">
                                Yesterday
                                {selectedFilterDate===2 && <img src={selectedIcon} alt="select" />}
                                </button>
                            </div>
                            <div className={selectedFilterDate===3 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                                <button onClick={() => customDateHandler("week", "queryUsageGraph")} className="no__button filter__date__font">
                                1 Week ago
                                {selectedFilterDate===3 && <img src={selectedIcon} alt="select" />}
                                </button>
                            </div>
                            <div className={selectedFilterDate===4 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                                <button onClick={() => customDateHandler("month", "queryUsageGraph")} className="no__button filter__date__font">
                                1 Month ago
                                {selectedFilterDate===4 && <img src={selectedIcon} alt="select" />}
                                </button>
                            </div>
                            <div className={selectedFilterDate===5 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                                <button onClick={() => customDateHandler("year", "queryUsageGraph")} className="no__button filter__date__font">
                                1 Year ago
                                {selectedFilterDate===5 && <img src={selectedIcon} alt="select" />}
                                </button>
                            </div> */}

                            </div>

                            <div className="filter__date__horizontal__line"></div>

                            <div className="filter__date__elem2">
                            <span className="filter__date__elem2__text">
                              <Tooltip title="Maximum Date Range is 30 days">
                                Custom Date Range 
                                <span style={{fontSize: 12, marginLeft: 6}}>
                                  (30 Days Max)
                                </span>
                              </Tooltip>
                            </span>

                            <div className="filter__date__specific__date">
                                <DatePicker.RangePicker placeholder={["Start", "End"]}
                                className="antd__datepicker"
                                format={"DD-MM-YY"}
                                minDate={dayjs(start_date)}
                                maxDate={dayjs(new Date())}
                                // onChange={(value) => dateRangeSelectorFunction(value, "queryUsageGraph")}
                                onChange={(value) => dateClickHandler(value, "queryUsageGraph", "custom")} 
                                onOpenChange={(open) => setIsDatePickerOpen(open)}
                                disabledDate={disabledDate}
                                />
                            </div>

                            </div>
                        </div>
                </div>
              }


              {/* {subFilterClickedStatus &&
                <div className="filter__box__content filter__box__content--changes filter__box__content1">
                        <div className="filter__date__box">

                            <div className="filter__date__elem1">
                            <div className="filter__date__font__heading">Filter by Query Date</div>
                            <div className={selectedFilterDate===1 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                                <button onClick={() => customDateHandler("today", "queryUsageGraph")} className="no__button filter__date__font">
                                Today
                                {selectedFilterDate===1 && <img src={selectedIcon} alt="select" />}
                                </button>
                            </div>
                            <div className={selectedFilterDate===2 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                                <button onClick={() => customDateHandler("yesterday", "queryUsageGraph")} className="no__button filter__date__font">
                                Yesterday
                                {selectedFilterDate===2 && <img src={selectedIcon} alt="select" />}
                                </button>
                            </div>
                            <div className={selectedFilterDate===3 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                                <button onClick={() => customDateHandler("week", "queryUsageGraph")} className="no__button filter__date__font">
                                1 Week ago
                                {selectedFilterDate===3 && <img src={selectedIcon} alt="select" />}
                                </button>
                            </div>
                            <div className={selectedFilterDate===4 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                                <button onClick={() => customDateHandler("month", "queryUsageGraph")} className="no__button filter__date__font">
                                1 Month ago
                                {selectedFilterDate===4 && <img src={selectedIcon} alt="select" />}
                                </button>
                            </div>
                            <div className={selectedFilterDate===5 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                                <button onClick={() => customDateHandler("year", "queryUsageGraph")} className="no__button filter__date__font">
                                1 Year ago
                                {selectedFilterDate===5 && <img src={selectedIcon} alt="select" />}
                                </button>
                            </div>
                            </div>

                            <div className="filter__date__horizontal__line"></div>

                            <div className="filter__date__elem2">
                            <span className="filter__date__elem2__text">Custom Date Range</span>

                            <div className="filter__date__specific__date">
                                <DatePicker.RangePicker placeholder={["Start", "End"]}
                                className="antd__datepicker"
                                format={"DD-MM-YY"}
                                minDate={dayjs(start_date)}
                                maxDate={dayjs(new Date())}
                                onChange={(value) => dateRangeSelectorFunction(value, "queryUsageGraph")}
                                onOpenChange={(open) => setIsDatePickerOpen(open)}
                                />
                            </div>

                            </div>
                        </div>
                </div>
              } */}

          </div>

          <div className="user__stat__graph__box__main">
          {
            queryCountAPIStatus==='loading'
            ?
            <div className='user__stat__graph__box__main__loader'>
              <SmileOutlined size={40}  style={{ fontSize: '50px', color: '#686c76' }}/>
              <FidgetSpinner
                visible={true}
                backgroundColor="#757576"
                height="80"
                width="80"
                ariaLabel="fidget-spinner-loading"
                wrapperStyle={{}}
                wrapperClass="fidget-spinner-wrapper"
              />
              Loading...
            </div>
            :
            queryCountAPIStatus==='failed'
            ?
            <Result
              icon={<SmileOutlined />}
              title="Failed to fetch data! We are still working on it."
              // extra={<Button type="primary">Next</Button>}
            />
            :
            <UserStatGraphPlot chartData={chartData} chartLabel={chartLabel}/>
          }
          </div>
        </div>

        <div className="user__stat__grap__box">
          <div className='user__stat__graph__heading'>Latency vs Month Graph</div>

          <div className="user_statg__filter__box__main userinqueries__filter__box--changes">

              <div className="userinquires__filter">
                        
                        <div className="userstats__leftelem">
                        <button ref={menuRef2} onClick={() => filterClickHandler("latencyMonthGraph")} className='userinquires__filter__button'>
                            <img src={filterIcon} alt="filter" />
                            <div className='userinquires__filter__badge'>
                                <span>Filter:&nbsp;
                                <span className='filter--color'>
                                    {
                                    selectedDate2.length === 0 ?
                                    "DD-MM-YY"
                                    :
                                    selectedDate2[0] === selectedDate2[1]
                                    ?
                                    `${selectedDate2[0]}`
                                    :
                                    `${selectedDate2[0]} - ${selectedDate2[1]}`
                                    }
                                    
                                </span>
                                </span>
                            </div>
                        </button>
                        
                        {
                            selectedDate2.length !== 0 &&
                            <div onClick={() => clearFilter("latencyMonthGraph")} className="userstats__cancel__div">
                              <MdCancel size={20} color={"#828282"}/>
                            </div>
                        }
                        </div>


                        {
                        filterClickedStatus2 && 
                        <div onClick={() => setSubFilterClickedStatus2(!subFilterClickedStatus2)} className="filter__box__dropdown1">
                            <div className="filter__box__dropdown__elem">
                              <span className="filter__box__dropdown__elem__span">Filter by Date</span>
                              <IoIosArrowForward size={20}/>
                            </div>

                        </div>
                        }
              </div>

              {subFilterClickedStatus2 &&
                <div className="filter__box__content filter__box__content--changes filter__box__content2">
                        <div className="filter__date__box">

                            <div className="filter__date__elem1">
                            <div className="filter__date__font__heading">Filter by Query Date</div>
                            <div className={selectedFilterDate2===1 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                                <button onClick={() => customDateHandler("today", "latencyMonthGraph")} className="no__button filter__date__font">
                                Today
                                {selectedFilterDate2===1 && <img src={selectedIcon} alt="select" />}
                                </button>
                            </div>
                            <div className={selectedFilterDate2===2 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                                <button onClick={() => customDateHandler("yesterday", "latencyMonthGraph")} className="no__button filter__date__font">
                                Yesterday
                                {selectedFilterDate2===2 && <img src={selectedIcon} alt="select" />}
                                </button>
                            </div>
                            <div className={selectedFilterDate2===3 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                                <button onClick={() => customDateHandler("week", "latencyMonthGraph")} className="no__button filter__date__font">
                                1 Week ago
                                {selectedFilterDate2===3 && <img src={selectedIcon} alt="select" />}
                                </button>
                            </div>
                            <div className={selectedFilterDate2===4 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                                <button onClick={() => customDateHandler("month", "latencyMonthGraph")} className="no__button filter__date__font">
                                1 Month ago
                                {selectedFilterDate2===4 && <img src={selectedIcon} alt="select" />}
                                </button>
                            </div>
                            <div className={selectedFilterDate2===5 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                                <button onClick={() => customDateHandler("year", "latencyMonthGraph")} className="no__button filter__date__font">
                                1 Year ago
                                {selectedFilterDate2===5 && <img src={selectedIcon} alt="select" />}
                                </button>
                            </div>
                            </div>

                            <div className="filter__date__horizontal__line"></div>

                            <div className="filter__date__elem2">
                            <span className="filter__date__elem2__text">Custom Date Range</span>

                            <div className="filter__date__specific__date">
                                <DatePicker.RangePicker placeholder={["Start", "End"]}
                                className="antd__datepicker"
                                format={"DD-MM-YY"}
                                minDate={dayjs(start_date)}
                                maxDate={dayjs(new Date())}
                                onChange={(value) => dateRangeSelectorFunction(value, "latencyMonthGraph")}
                                onOpenChange={(open) => setIsDatePickerOpen2(open)}
                                />
                            </div>

                            </div>
                        </div>
                </div>
              }
          </div>

          <div className='user__stat__graph__plot'>
          <BarChart
              series={[
                { data: queriesCountData, label: 'Queries', id: 'uvId' },
              ]}
              xAxis={[{ scaleType: 'band', data:  userNameData, categoryGapRatio: 0.1, barGapRatio: 0.2, labelFontSize: "7px", position: "top" }]}
            />
          </div>

          <div className='user__stat__sumup__container'>
            <div className="user__stat__sumup__box">
              <span className='user__stat__sumup--label'>Average Latency: </span>
              <span className='user__stat__sumup--value'>8</span>
            </div>
          </div>
        </div>

        <div className="user__stat__grap__box">
          <div className='user__stat__graph__heading'>Tokens used per month Graph</div>

          <div className="user_statg__filter__box__main userinqueries__filter__box--changes">

              <div className="userinquires__filter">
                        
                        <div className="userstats__leftelem">
                        <button ref={menuRef3} onClick={() => filterClickHandler("tokenPerMonthGraph")} className='userinquires__filter__button'>
                            <img src={filterIcon} alt="filter" />
                            <div className='userinquires__filter__badge'>
                                <span>Filter:&nbsp;
                                <span className='filter--color'>
                                    {
                                    selectedDate3.length === 0 ?
                                    "DD-MM-YY"
                                    :
                                    selectedDate3[0] === selectedDate3[1]
                                    ?
                                    `${selectedDate3[0]}`
                                    :
                                    `${selectedDate3[0]} - ${selectedDate3[1]}`
                                    }
                                    
                                </span>
                                </span>
                            </div>
                        </button>
                        
                        {
                            selectedDate3.length !== 0 &&
                            <div onClick={() => clearFilter("tokenPerMonthGraph")} className="userstats__cancel__div">
                              <MdCancel size={20} color={"#828282"}/>
                            </div>
                        }
                        </div>


                        {filterClickedStatus3 && 
                        <div onClick={() => setSubFilterClickedStatus3(!subFilterClickedStatus3)} className="filter__box__dropdown1">
                            <div className="filter__box__dropdown__elem">
                            <span className="filter__box__dropdown__elem__span">Filter by Date</span>
                            <IoIosArrowForward size={20}/>
                            </div>

                        </div>
                        }
              </div>

              {subFilterClickedStatus3 &&
                <div className="filter__box__content filter__box__content--changes filter__box__content3">
                        <div className="filter__date__box">

                            <div className="filter__date__elem1">
                            <div className="filter__date__font__heading">Filter by Query Date</div>
                            <div className={selectedFilterDate3===1 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                                <button onClick={() => customDateHandler("today", "tokenPerMonthGraph")} className="no__button filter__date__font">
                                Today
                                {selectedFilterDate3===1 && <img src={selectedIcon} alt="select" />}
                                </button>
                            </div>
                            <div className={selectedFilterDate3===2 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                                <button onClick={() => customDateHandler("yesterday", "tokenPerMonthGraph")} className="no__button filter__date__font">
                                Yesterday
                                {selectedFilterDate3===2 && <img src={selectedIcon} alt="select" />}
                                </button>
                            </div>
                            <div className={selectedFilterDate3===3 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                                <button onClick={() => customDateHandler("week", "tokenPerMonthGraph")} className="no__button filter__date__font">
                                1 Week ago
                                {selectedFilterDate3===3 && <img src={selectedIcon} alt="select" />}
                                </button>
                            </div>
                            <div className={selectedFilterDate3===4 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                                <button onClick={() => customDateHandler("month", "tokenPerMonthGraph")} className="no__button filter__date__font">
                                1 Month ago
                                {selectedFilterDate3===4 && <img src={selectedIcon} alt="select" />}
                                </button>
                            </div>
                            <div className={selectedFilterDate3===5 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                                <button onClick={() => customDateHandler("year", "tokenPerMonthGraph")} className="no__button filter__date__font">
                                1 Year ago
                                {selectedFilterDate3===5 && <img src={selectedIcon} alt="select" />}
                                </button>
                            </div>
                            </div>

                            <div className="filter__date__horizontal__line"></div>

                            <div className="filter__date__elem2">
                            <span className="filter__date__elem2__text">Custom Date Range</span>

                            <div className="filter__date__specific__date">
                                <DatePicker.RangePicker placeholder={["Start", "End"]}
                                className="antd__datepicker"
                                format={"DD-MM-YY"}
                                minDate={dayjs(start_date)}
                                maxDate={dayjs(new Date())}
                                onChange={(value) => dateRangeSelectorFunction(value, "tokenPerMonthGraph")}
                                onOpenChange={(open) => setIsDatePickerOpen3(open)}
                                />
                            </div>

                            </div>
                        </div>
                </div>
              }
          </div>

          <div className='user__stat__graph__plot'>
          <BarChart
              series={[
                { data: queriesCountData, label: 'Queries', id: 'uvId' },
              ]}
              xAxis={[{ scaleType: 'band', data:  userNameData, categoryGapRatio: 0.1, barGapRatio: 0.2, labelFontSize: "7px", position: "top" }]}
            />
          </div>

          <div className='user__stat__sumup__container'>
            <div className="user__stat__sumup__box">
              <span className='user__stat__sumup--label'>Total Tokens: </span>
              <span className='user__stat__sumup--value'>8</span>
            </div>
            <div className="user__stat__sumup__box">
              <span className='user__stat__sumup--label'>Total Cost: </span>
              <span className='user__stat__sumup--value'>250</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default UserStatGraph
