import React from 'react'
import Navbar from '../../components/Navbar/Navbar'
import UserStatGraph from '../../components/UserStatGraph/UserStatGraph'
import AdminSidebar from '../../components/AdminSidebar/AdminSidebar'

const UserStatGraphPage = () => {
  return (
    <main className="admin__dashboard__main__page">
      <div className="admin__dashboard__box">
        <Navbar />
        <div className='admin__dashboard__elems'>
          <AdminSidebar />
          <UserStatGraph />
        </div>
      </div>
    </main>
  )
}

export default UserStatGraphPage
