import React, {useState, useEffect, useRef} from "react";

import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';

// Date Calendra
import { DatePicker, ConfigProvider, message } from 'antd';
// import { Button, Form, Input, Checkbox, message } from "antd";
import dayjs from 'dayjs';

import { Spin, Space, Tooltip } from 'antd';
import {Switch} from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import Skeleton from '@mui/material/Skeleton';

// import Switch from '@mui/material/Switch';
import ClipLoader from "react-spinners/ClipLoader";

// Icons
import rightArrow from "../svg-store/userstatistics/right_arrow.svg"
import switchActive from "../svg-store/userstatistics/switch_active.svg"
import switchInactive from "../svg-store/userstatistics/switch_inactive.svg"
import filterIcon from "../svg-store/userInquires/filter.svg"
import sortIcon from "../svg-store/userInquires/sort.svg"
import { IoSearchSharp } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";
import { CiCalendar } from "react-icons/ci";
import { RxCross1 } from "react-icons/rx";
import selectedIcon from "../svg-store/userstatistics/selected.svg"
import { MdCancel } from "react-icons/md";

// Backend APIs
// import { adminHomeAPI } from "../../API/BackendAPI"
import { adminDashboardAPI, userAccessAPI } from "../../features/AdminPage/AdminPageSlice";
import { useDispatch, useSelector } from "react-redux";


// React Router DOM
import { useNavigate } from "react-router";

import Logout from "../../Utility/Logout";

// Time ago
import {subMonths, format, subDays, subWeeks, subYears } from 'date-fns'

const UsersStatistics = () => {
  // const [totalUsers, setTotalUsers] = useState(-1)
  // Redux
  const {status, userList, totalChats, totalQueries, totalUsers, userAccessAPIStatus, unauthorize} = useSelector((state)=>state.admindashboard)

  const dispatch = useDispatch()

  const curr_date = new Date();
  let currentDate = curr_date.getDate().toString() + "/" + (curr_date.getMonth() + 1).toString() + "/" + curr_date.getFullYear().toString()

  const start_date = "1/1/2021"

  useEffect(() => {
      if(status === 'idle'){
        dispatch(adminDashboardAPI({startDate: start_date, endDate: currentDate}))
      }
    }, [])

    

  useEffect(() => {
      if(userList.length>0){
      }
  }, [userList])

  

  useEffect(() => {
    if (unauthorize) {
      // localStorage.removeItem("auth_token");
      // localStorage.removeItem("is_admin");
      // sessionStorage.clear();
      // message.error("Unauthorize Access!!", [4]);
      // navigate("/");
    }
  }, [unauthorize]); 

  
  // User Input in input text
  const [inputValue, setInputValue] = useState('');

  const [cleared, setCleared] = useState(false);

  useEffect(() => {
    if (cleared) {
      const timeout = setTimeout(() => {
        setCleared(false);
      }, 1500);

      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [cleared]);


  const navigate = useNavigate()

  
  // Filter useState
  const [filterClickedStatus, setFilterClickedStatus] = useState(false)
  const [subFilterClickedStatus, setSubFilterClickedStatus] = useState(false)

  const userDetailDummyArray = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]
  
  const [userDetailArray, setUserDetailArray]  = useState(userDetailDummyArray)
  const [userDetailArray2, setUserDetailArray2]  = useState([])

  useEffect(() => {
  if(userList.length!==0){
    let newUserDetailArray = []

    userList.forEach((value, index) => {
      let name = "Guest User"
      
      if(value.hasOwnProperty("user_name")){
        name = value.user_name
        if(value.user_name.length === 0){
          name = "Guest User"
        }
      }
      
      newUserDetailArray.push({userID: value.user_id, 
      userName: name, 
      totalChats: value.chat_count, 
      totalQueries: value.query_count, 
      isActive: value.is_active, 
      isAdmin: value.is_admin})

      // console.log(userDetailArray)
      setUserDetailArray(newUserDetailArray)
      setUserDetailArray2(newUserDetailArray)
    })
  }
  }, [userList])


  // const arr = [{userName: "Akshay", id: 4}, {userName: "Amit", id: 5}, {userName: "Ankit", id: 6}]



  


  const filterClickHandler = () => {
    if(filterClickedStatus){
      setSubFilterClickedStatus(false)
    }
    setFilterClickedStatus(!filterClickedStatus)
  }

  // useRef
  let menuRef1 = useRef();

  // useEffect(() => {

  //   let handler1 = (e)=>{
  //     if(filterClickedStatus){
  //       if(!document.getElementsByClassName("filter__box__dropdown")[0].contains(e.target) && 
  //       !menuRef1.current.contains(e.target)){
  //         if(subFilterClickedStatus){
  //           console.log(e.target)
  //           if(!document.getElementsByClassName("filter__box__content")[0].contains(e.target) && 
  //           !document.getElementsByClassName("filter__date__specific__date")[0].contains(e.target) &&
  //           !document.getElementsByClassName("antd__datepicker")[0].contains(e.target) 
  //           // !document.getElementsByClassName("ant-picker-body")[0].contains(e.target) &&
  //           // !document.getElementsByClassName("ant-picker-header")[0].contains(e.target)&&
  //           // !document.getElementsByClassName("ant-picker-cell-inner")[0].contains(e.target)
  //           ){
  //             setFilterClickedStatus(false);
  //             setSubFilterClickedStatus(false);
  //           }
  //         }
  //         else{
  //           setFilterClickedStatus(false);
  //           setSubFilterClickedStatus(false);
  //         }
  //       }    
  //   }  
  //   };


  //   document.addEventListener("mousedown", handler1);
    

  //   return() =>{
  //     document.removeEventListener("mousedown", handler1);
  //   }

  // });
                          
  const [selectedDate, setSelectedDate] = useState([])

  const dateRangeSelectorFunction = (date_data) => {
    let st_date = null
    let ed_date = null

    if(date_data !== null){
      let year = date_data[0]["$y"].toString()
      st_date = date_data[0]["$D"].toString() + "/" + (date_data[0]["$M"] + 1).toString() + "/" +year

      console.log(st_date)

      year = date_data[1]["$y"].toString()
      ed_date = date_data[1]["$D"].toString() + "/" + (date_data[1]["$M"] + 1).toString() + "/" +year
      console.log(ed_date)

      setSelectedDate([formatDateToDisplay(st_date), formatDateToDisplay(ed_date)])

      setFilterClickedStatus(false)
      setSubFilterClickedStatus(false)

      dispatch(adminDashboardAPI({startDate: st_date, endDate: ed_date}))
    }
  }  
  
  const [selectedFilterDate, setSelectedFilterDate] = useState(0)

  const clearFilter = () => {
    setSelectedDate([])
    dispatch(adminDashboardAPI({startDate: start_date, endDate: currentDate}))
    setSubFilterClickedStatus(false)
    setFilterClickedStatus(false)
    setSelectedFilterDate(0)
  }


  // Time Ago
  const customDateHandler = (flag) => {
    if(flag === "today"){
      setSelectedFilterDate(1)
      if(selectedFilterDate !== 1){
        dispatch(adminDashboardAPI({startDate: currentDate, endDate: currentDate}))
        setSelectedDate([formatDateToDisplay(currentDate), formatDateToDisplay(currentDate)])
      }
    }
    else if(flag === "yesterday"){
      setSelectedFilterDate(2)
      if(selectedFilterDate !== 2){
        let yesterday = subDays(new Date(), 1);
        yesterday = format(yesterday, "dd/MM/yyyy")
        dispatch(adminDashboardAPI({startDate: yesterday, endDate: yesterday}))
        setSelectedDate([formatDateToDisplay(yesterday), formatDateToDisplay(yesterday)])
      }
    }
    else if(flag === "week"){
      setSelectedFilterDate(3)
      if(selectedFilterDate !== 3){
        let week = subWeeks(new Date(), 1);
        week = format(week, "dd/MM/yyyy")
        dispatch(adminDashboardAPI({startDate: week, endDate: currentDate}))
        setSelectedDate([formatDateToDisplay(week), formatDateToDisplay(currentDate)])
      }
    }
    else if(flag === "month"){
      setSelectedFilterDate(4)
      if(selectedFilterDate !== 4){
        let oneMonthAgo = subMonths(new Date(), 1);
        oneMonthAgo = format(oneMonthAgo, "dd/MM/yyyy")

        dispatch(adminDashboardAPI({startDate: oneMonthAgo, endDate: currentDate}))
        
        setSelectedDate([formatDateToDisplay(oneMonthAgo), formatDateToDisplay(currentDate)])
      }
    }
    else{
      setSelectedFilterDate(5)
      if(selectedFilterDate !== 4){
        let year = subYears(new Date(), 1);
        year = format(year, "dd/MM/yyyy")

        dispatch(adminDashboardAPI({startDate: year, endDate: currentDate}))
        
        setSelectedDate([formatDateToDisplay(year), formatDateToDisplay(currentDate)])
      }
    }

    setFilterClickedStatus(false)
    setSubFilterClickedStatus(false)
  }


  // Format Date
  const formatDateToDisplay = (input_date) => {
    let dateStr = input_date

    // Split the date string into day, month, and year
    var parts = dateStr.split('/');

    // Convert the string parts into integers
    var day = parseInt(parts[0], 10);
    var month = parseInt(parts[1], 10);
    var year = parseInt(parts[2], 10);


    // Define month names
    var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    // Format the date string as "DD Mon YY"
    var formattedDate = ('0' + day).slice(-2) + ' ' + monthNames[month - 1] + ' ' + ('' + year).slice(-2);

    return formattedDate
  }


  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  const [userAccessID, setUserAccessID] = useState(0)
  const userAccessClickHandler = (userID) => {
    console.log("User ID: ", userID)
    setUserAccessID(userID)
    dispatch(userAccessAPI({userID: userID}))
    // if(userAccessAPIStatus==='succeeded'){
    //   dispatch(adminDashboardAPI({startDate: start_date, endDate: currentDate}))
    // }
  }

  useEffect(() => {
    if(userAccessAPIStatus==='succeeded'){
      dispatch(adminDashboardAPI({startDate: start_date, endDate: currentDate}))
    }
  }, [userAccessAPIStatus])
  


  


  return(
    <div className="users__statistics__main">
      <div className="users__statistics__main--box">

      <div className="userstatistics__filter__box">

          <div className='userinqueries__filter__box__leftelem'>

              <div className="userinquires__filter">
                
                <div className="userstats__leftelem">
                  <button ref={menuRef1} onClick={filterClickHandler} className='userinquires__filter__button'>
                      <img src={filterIcon} alt="filter" />
                      <div className='userinquires__filter__badge'>
                        <span>Filter:&nbsp;
                          <span className='filter--color'>
                            {
                              selectedDate.length === 0 ?
                              "DD-MM-YY"
                              :
                              selectedDate[0] === selectedDate[1]
                              ?
                              `${selectedDate[0]}`
                              :
                              `${selectedDate[0]} - ${selectedDate[1]}`
                            }
                            
                          </span>
                        </span>
                      </div>
                  </button>
                  
                  {
                    selectedDate.length !== 0 &&
                    <div onClick={clearFilter} className="userstats__cancel__div">
                      <MdCancel size={20} color={"#828282"}/>
                    </div>
                  }
                </div>


                {filterClickedStatus && 
                  <div onClick={() => setSubFilterClickedStatus(!subFilterClickedStatus)} className="filter__box__dropdown1">
                    <div className="filter__box__dropdown__elem">
                      <span className="filter__box__dropdown__elem__span">Filter by Date</span>
                      <IoIosArrowForward size={20}/>
                    </div>

                  </div>
                }
              </div>

              {subFilterClickedStatus &&
                <div className="filter__box__content">
                  <div className="filter__date__box">

                    <div className="filter__date__elem1">
                      <div className="filter__date__font__heading">Filter by Date</div>
                      <div className={selectedFilterDate===1 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                        <button onClick={() => customDateHandler("today")} className="no__button filter__date__font">
                          Today
                          {selectedFilterDate===1 && <img src={selectedIcon} alt="select" />}
                        </button>
                      </div>
                      <div className={selectedFilterDate===2 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                        <button onClick={() => customDateHandler("yesterday")} className="no__button filter__date__font">
                          Yesterday
                          {selectedFilterDate===2 && <img src={selectedIcon} alt="select" />}
                        </button>
                      </div>
                      <div className={selectedFilterDate===3 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                        <button onClick={() => customDateHandler("week")} className="no__button filter__date__font">
                          1 Week ago
                          {selectedFilterDate===3 && <img src={selectedIcon} alt="select" />}
                        </button>
                      </div>
                      <div className={selectedFilterDate===4 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                        <button onClick={() => customDateHandler("month")} className="no__button filter__date__font">
                          1 Month ago
                          {selectedFilterDate===4 && <img src={selectedIcon} alt="select" />}
                        </button>
                      </div>
                      <div className={selectedFilterDate===5 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                        <button onClick={() => customDateHandler("year")} className="no__button filter__date__font">
                          1 Year ago
                          {selectedFilterDate===5 && <img src={selectedIcon} alt="select" />}
                        </button>
                      </div>
                    </div>

                    <div className="filter__date__horizontal__line"></div>

                    <div className="filter__date__elem2">
                      <span className="filter__date__elem2__text">Custom Date Range</span>

                      <div className="filter__date__specific__date">
                        <DatePicker.RangePicker placeholder={["Start", "End"]}
                          className="antd__datepicker"
                          format={"DD-MM-YY"}
                          minDate={dayjs(start_date)}
                          maxDate={dayjs(new Date())}
                          // needConfirm={true} 
                          onChange={dateRangeSelectorFunction}
                          />
                      </div>

                    </div>
                  </div>
                </div>
              }
             
          </div>

          <div className='userinqueries__number__box'>   
            <div className='userinqueries__number__box__elem1'>
              <span className='userinqueries__showing--color'>Showing: </span>
              {
                totalChats === -1 ?
                <Skeleton height={40} width={50} className="userinqueries--count" animation="wave" variant="text" />
                :
                <span className='userinqueries--count'>
                  {totalChats} &nbsp;
                  <Tooltip placement="topRight" title={"The session is simply a conversation/chat in the chat history on the left side of the chat interface."}>
                    Sessions
                  </Tooltip>
                </span>
              }
              
            </div>
            <div className='userinqueries__number__box__elem1'>
              <span className='userinqueries__showing--color'>Total: </span>
              {
                totalQueries === -1 ?
                <Skeleton height={40} width={50} className="userinqueries--count" animation="wave" variant="text" />
                :
                <span className='userinqueries--count'>
                  {totalQueries} &nbsp;
                  <Tooltip placement="topLeft" title={"The total number of queries represents the count of questions users ask the bot. Billing is based on this criterion."}>
                    Queries
                  </Tooltip>
                </span>
              }
            </div> 
            <div className='userinqueries__number__box__elem1'>
              <span className='userinqueries__showing--color'>Total: </span>
              {
                totalUsers === -1 ?
                <Skeleton height={40} width={50} className="userinqueries--count" animation="wave" variant="text" />
                :
                <span className='userinqueries--count userinqueries--count--blueColor'>
                  {totalUsers} Users
                </span>
              }
            </div> 
          </div>
      </div>

        <div className="user__statistics__info__box__main">
          <div className="user__statistics__info__box">
            
            {/* <div className="user__statistics__countvalues">

              <div className="user__statistics__countelem1">
                <div className="user__statistics__countvalues__box">

                  {
                    totalUsers === -1 
                    ?
                    <div className="example">
                      <Spin size="large"/>
                    </div>
                    :
                    <span className="user__statistics__val">{totalUsers}</span>
                  }

                    
                    
                    
                    <span>Active users</span>

                </div>
                <div className="user__statistics__countvalues__box">

                  {
                    totalQueries===-1 ? 
                      <div className="example">
                        <Spin size="large"/>
                      </div>
                    :
                      <span className="user__statistics__val">{totalQueries}</span>
                    }


                    <span>Total Queries</span>
                </div>
              </div>

              <div className="user__statistics__countelem2">
                <div onClick={() => navigate("/userstats")} className="user__statistics__matrix">
                  Compare Stats
                </div>
              </div>
            </div> */}

            <div className="user__statistics__userslist">
                <div className="user__statistics__search__bar__box">
                  <IoSearchSharp />
                  <input type="text" className="user__statistics__search__bar" 
                  placeholder="Search by user name" 
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}/>
                </div>

              <div className="user__statistics__userslist--box">
                

                <div className="user__statistics__userslist__heading__box">
                    <div className="user__statistics__userslist--leftelement">
                      <span className="user__stat__leftelem__span1">User ID</span>
                      <span className="user__stat__leftelem__span2">User Name</span>
                    </div>
                    <div className="user__statistics__userslist--rightelement">
                      <span className="user__statistics__userlist__total__queries">Total Sessions</span>
                      <span className="user__statistics__userlist__total__queries">Total Queries</span>
                      <div className="user__statistics__userslist__rightelement--anotherelement">
                        <div className="user__statistics__userslist__anotherelement--element">
                          <span>Access</span>
                        </div>
                        <button style={{cursor: "default"}} className="heading__disabled__btn">
                          <img className="heading__disabled__img" src={rightArrow} alt="arrow"/>
                        </button>
                      </div>
                    </div>
                </div>

                <div className="user__statistics__userslist__content__box">
                  <div className="user__statistics__userslist__content__innerbox">

                    {
                      userDetailArray.map((userDetail, i) => (
                        userList.length === 0 ?

                        <div key={i} className="user__statistics__userslist__contents">
                          <div className="user__statistics__userslist--leftelement">
                            <Skeleton height={40} className="user__stat__leftelem__span1" animation="wave" variant="text" />
                            <Skeleton height={40} className="user__stat__leftelem__span2" animation="wave" variant="text" />
                          </div>
                          <div className="user__statistics__userslist--rightelement">
                              <Skeleton height={40} className="user__statistics__userlist__total__queries" animation="wave" variant="text" />
                              <Skeleton height={40} className="user__statistics__userlist__total__queries" animation="wave" variant="text" />
                              <div className="user__statistics__userslist__rightelement--anotherelement">
                                <div className="user__statistics__userslist__anotherelement--element">
                                  <Skeleton height={40} animation="wave" variant="text" />
                                </div>
                                <button></button>
                              </div>
                          </div>
                        </div>

                        :

                        <div key={i} 
                          style={userDetail.userName && { display: userDetail.userName.toUpperCase().includes(inputValue.toUpperCase()) ? '' : 'none' }}
                          className={(i+1)%2===0 ? "user__statistics__userslist__contents" : "user__statistics__userslist__contents user_statistics__userlist--color"}
                        >
                          <div className="user__statistics__userslist--leftelement">
                              <span className="user__stat__leftelem__span1">{userDetail.userID}</span>
                                <div className="user__stat__leftelem__span2">
                                  {userDetail.isAdmin &&
                                    <Tooltip placement="top" title={"Admin"}>
                                      <span style={{cursor: "pointer"}}>
                                        👑 
                                      </span>
                                    </Tooltip>
                                  }
                                  {userDetail.userName} 
                                </div>
                              
                          </div>
                          <div className="user__statistics__userslist--rightelement">
                              <span className="user__statistics__userlist__total__queries">{userDetail.totalChats}</span>
                              <span className="user__statistics__userlist__total__queries">{userDetail.totalQueries}</span>
                              <div className="user__statistics__userslist__rightelement--anotherelement">
                                <div className="user__statistics__userslist__anotherelement--element">

                                    {/* <ConfigProvider
                                      theme={{
                                        components: {
                                          Switch: {
                                            colorPrimary: '#00b96b',
                                            colorPrimaryHover: '#00b96b'
                                          }
                                        },
                                      }}
                                    >
                                        
                                      <Switch  
                                      disabled={false} 
                                      // {userDetail.isActive ? "" : ""}
                                      // defaultChecked={userDetail.isActive && true}
                                      // defaultValue={userDetail.isActive}
                                      checked={userDetail.isActive}
                                      // handleBg={"#fff"}
                                      />

                                    </ConfigProvider> */}
                                    {
                                      userAccessID===userDetail.userID && userAccessAPIStatus === "loading"
                                      ?
                                      <ClipLoader
                                      color="#424a49"
                                      size={20}
                                    />
                                    :
                                    <div onClick={() => userAccessClickHandler(userDetail.userID)} className="user__acess_div">
                                      {
                                        userDetail.isAdmin ? 
                                        <Tooltip placement="top" title={"Can't change Admin Permission"}>
                                          <FormControlLabel
                                        control={<IOSSwitch sx={{ m: 1 }} defaultChecked={userDetail.isActive} disabled={userDetail.isAdmin}/>}
                                        />
                                        </Tooltip>
                                        :
                                        <FormControlLabel
                                        control={<IOSSwitch sx={{ m: 1 }} defaultChecked={userDetail.isActive} disabled={userDetail.isAdmin}/>}
                                        />
                                      }
                                    </div>
                                    }

                                    {/* <Switch defaultChecked={userDetail.isActive} /> */}

                                    {/* <ClipLoader
                                      color="#424a49"
                                      size={20}
                                    /> */}

                                </div>
                                <button onClick={() => navigate(`/userchat/?user=${userDetail.userID}`)}>
                                  <img src={rightArrow} alt="arrow"/>
                                </button>
                              </div>
                          </div>
                        </div>

                      ))
                    }

                    

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
};

export default UsersStatistics;
