import React, { useEffect, useState } from 'react';
import { Table, Tooltip } from 'antd';


// Backend APIs
import { useSelector } from "react-redux";



const QueryMetricsTable = () => {
  
  // React Redux
  const {evalTableData, evalRunStatus, evalDataStatus} = useSelector((state) => state.querymetrics)
  
  
  const [tableRowData, setTableRowData] = useState([])
  const tableScrollableColumnWidth = 400

  const [columns, setColumns] = useState([])


  useEffect(() => {
    if(Object.keys(evalTableData).length>0){
      if(evalTableData.hasOwnProperty("row_headings")){
        let colData = formatTableColumn(evalTableData["row_headings"])
        setColumns(colData)
      }

      if(evalTableData.hasOwnProperty("row_data")){
        let rowData = formatTableRow(evalTableData["row_data"])
        setTableRowData(rowData)
      }
    }
  }, [evalTableData])


  // Formatting Table rows
  const formatTableRow = (row_data) => {
    let tempArr = []
    row_data.forEach((objElem, index) => {
      let new_Obj = {}
      let new_children_obj = {}

      new_Obj["key"] = index.toString()

      for(let objKey in objElem){

        if(Array.isArray(objElem[objKey])){
          let child_context_string = ""
          for(let i in objElem[objKey]){
            child_context_string += (objElem[objKey][i] + "")
          }
            child_context_string = child_context_string.split(" ");
            new_Obj[objKey] = child_context_string.slice(0, 20).join(" ");
          
          
            let remainingWords = child_context_string.slice(10).join(" ");
            if(remainingWords.length>0){
              new_children_obj[objKey] = remainingWords
            }

        }

        else if(typeof(objElem[objKey]) === "string"){
          let words = objElem[objKey];
            words = words.split(" ");
            new_Obj[objKey] = words.slice(0, 20).join(" ");
          
          
            let remainingWords = words.slice(10).join(" ");
            if(remainingWords.length>0){
              new_children_obj[objKey] = remainingWords
            }
        }
        else if(typeof(objElem[objKey]) === "number"){
          new_Obj[objKey] = formatNumberWithOneDecimal(objElem[objKey])
        }
        else{
          new_Obj[objKey] = objElem[objKey]
        }


        new_Obj["children"] = [new_children_obj]
      }


      tempArr.push(new_Obj)
      
    })

    return tempArr
  }


  const tableColumnShortDesc = {
    "Question": "The sample/test questions.",
    "Answer": "The answer synthesized by the system.",
    "Search Score": "A score between 0 and 1 defines the quality of the retrieval/search process. The quality of the retrieved chunks directly affects the generated answer.", 
    "Contexts": "The chunks of information retrieved from the documents by our search algorithm are used by the generative AI to synthesize a response.", 
    "Ground Truth": "Known answers to the sample/test questions",
    "Answer Score": "A score between 0 and 1 evaluates the quality of the final response being generated."
  }

  // Formatting Table column names
  const formatTableColumn = (row_headings) => {
    let colData = []

    evalTableData["row_headings"].forEach((elem, index) => {
      let new_obj = {}
      
      let titleData = formatString(elem)

      const desc = tableColumnShortDesc[titleData]
      if(typeof(desc) === 'undefined'){
        new_obj["title"] = titleData
      }
      else{
        new_obj["title"] = <Tooltip title={desc}>
          {titleData}
        </Tooltip>
      }

      new_obj["dataIndex"] = elem
      new_obj["key"] = elem
      new_obj["render"] = text => <div className="custom-cell">{text}</div>
      // new_obj["onCell"] = (_, index) => ({
      //   rowSpan: 2
      // })
      

      if(elem === "question" || elem==="answer"){
        new_obj["width"] = 300
        new_obj["fixed"] = 'left'
      }
      else if(elem === "search_score" || elem==="answer_score"){
        new_obj["width"] = 150
      }
      else{
        new_obj["width"] = tableScrollableColumnWidth
      }

      colData.push(new_obj)
    })

    return colData
  }


  // Formatting the x-Axis Label String
  const formatString = (str) => {
    return str.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
  }


  

  const tableColumnName = {question: "question", 
  response: "response", 
  context: "context", 
  prompt_injection: {score: "score_prompt_injection", explanation: "explanation_prompt_injection"}, 
  response_completeness: {score: "score_response_completeness", explanation: "explanation_response_completeness"}, 
  response_relevance: {score: "score_response_relevance", explanation: "explanation_response_relevance"}, 
  context_relevance: {score: "score_context_relevance", explanation: "explanation_context_relevance"}, 
  factual_accuracy: {score: "score_factual_accuracy", explanation: "explanation_factual_accuracy"}, 
  toxicity: {score: "score_toxicity", explanation: "explanation_toxicity"},
  jailbreak_attempt: {score: "score_jailbreak_attempted", explanation: "explanation_jailbreak_attempted"}
  }

  function formatNumberWithOneDecimal(num) {
    // Convert number to string
    var numStr = num.toString();

    // Split the string at the decimal point
    var parts = numStr.split('.');

    // If there is only one part (no decimal point)
    if (parts.length === 1) {
        return Number(parts[0]); // Return the number as is
    } else {
        // Concatenate the first part with the first character of the second part
        return Number(parts[0] + '.' + parts[1].charAt(0));
    }
  }

  const avgPercentageText = {safe: "Safe", caution: "Caution", danger: "Danger"}


  
  

  return (
    <>
    {
      <Table
        columns={columns}
        dataSource={tableRowData}
        bordered={true}
        size={"small"}
        rowClassName="e__matrix__table__row"
        
        scroll={
          {
            x: 1500,
            y: 450,
          }
        }

        loading={(evalDataStatus==="loading" || evalRunStatus === 'loading') ? true : false}
      />
    }
    </>
  );
};

export default QueryMetricsTable;

