import React from 'react'
import Navbar from '../../components/Navbar/Navbar'
import AdminSidebar from '../../components/AdminSidebar/AdminSidebar'
import UsersStatistics from '../../components/UsersStatistics/UsersStatistics'

const AdminPage = () => {


  return (
    <main className='admin__dashboard__main__page'>
        <div className='admin__dashboard__box'>
          <Navbar />
          <div className='admin__dashboard__elems'>
            <AdminSidebar />
            <UsersStatistics/>
          </div>

        </div>
    </main>
  )
}

export default AdminPage