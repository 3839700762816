import React, { useState } from "react";

import { comment, dislikeResponse } from "../../API/BackendAPI";

import dislikeResponseThums from "../svg-store/dislikeresponse/dislikeresponseThum.svg"
import closeDislikeResponseModal from "../svg-store/dislikeresponse/cross.svg"
// import responseSubmitTick from "../svg-store/likeresponse/likeResponse-tick.svg"
import contentSelectorTick from "../svg-store/dislikeresponse/contentSelectorTick.svg"
import successTick from "../svg-store/likeresponse/success-image.png";


const LikeMessage = ({ dislikedModalStatus, msgID, dislikedMsgArray }) => {
  // Taking User Comment as Input
  const [userInputComment, setUserInputComment] = useState("");

  // Response Submitted or not
  const [responseIsSubmitted, setResponseIsSubmitted] = useState(false)


  const responseSubmitHandler = async () => {
    const textAreaInput = userInputComment.trim();
    let chat_id = "";
    if (sessionStorage.getItem("chat_id") !== null) {
      chat_id = Number(sessionStorage.getItem("chat_id"));
    }

    const likeResData = await dislikeResponse(chat_id, msgID);
    // console.log(likeResData.message);
    if (likeResData.status) {
    //   console.log(likeResData.message.response);
      // Adding the messages into the Positive Feedback Array
      dislikedMsgArray(msgID);
      // console.log(positiveFeedbackResponseSent)
    }

    if (textAreaInput.length !== 0) {
      // console.log("Length zero")
      const resData = await comment(chat_id, msgID, textAreaInput);
      if (resData.status) {
        // console.log("Chat ID:", chat_id, "Msg ID:", msgID);
        // console.log(resData.message)
        setUserInputComment("");
        setResponseIsSubmitted(true)
      }
    }
    else{
        setResponseIsSubmitted(true)
    }

    // console.log(userInputComment);
  };

  return (
    <div className="feedback__page__main">
      <div className="feedback__page__box">
        <div className={responseIsSubmitted ? "feedback__main__page--responseSuccess" : "feedback__main__box"}>

          {!responseIsSubmitted ?
          <div className="likeresponse__main__page">
            <div className="likeresponse__upper__box">
              <div className="likeresponse__Intro">
                <img src={dislikeResponseThums} alt="like" />
                <span>Provide Additional Feedback</span>
              </div>
              <img
                src={closeDislikeResponseModal}
                alt="cross"
                onClick={() => dislikedModalStatus(false)}
              />
            </div>
            <div className="likeresponse__lower__box">
              <span className="likerespnse__heading__msg">What do you dislike about the response?</span>
              <div className="likeresponse__textarea">
                <textarea onChange={(e) => {setUserInputComment(e.target.value);}}
                  placeholder="Describe..."
                  rows="8"
                />
              </div>
            </div>

            <div className="likeresponse__lowest__box">
              <div className="likeresponse__submit__btn__box">
                  <button className="likeresponse__submit__feedback" onClick={responseSubmitHandler}>
                    <span>Submit Feedback</span>
                  </button>
                  <button className="likeresponse__cancel__feedback" onClick={() => dislikedModalStatus(false)}>
                    <span>Cancel</span>
                  </button>
              </div>
            </div>
          </div>
            :
           <div className="likeresponse__success__msg__box">
                <div className="likeresponse__success__msg__closingImg">
                    <img src={closeDislikeResponseModal} alt="cross" onClick={() => dislikedModalStatus(false)} />
                </div>
                <div className="feedback__horizontal__line  likeresponse__success__msg__horizontal__line">
                </div>
                <img src={successTick} alt="success" />
                <span>Thank You. Your feedback has been submitted successfully. </span>
            </div> 
        }
        </div>
      </div>
    </div>
  );
};

export default LikeMessage;
