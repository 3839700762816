import React, {useEffect, useState} from "react";

import { Tooltip } from 'antd';

import { useSelector } from "react-redux";

const QueryMetricsList = () => {
    const {evalNegativeChanges, evalPositiveChanges} = useSelector((state) => state.querymetrics)

    // console.log(evalPositiveChanges, evalNegativeChanges)
    const [positiveChangesData, setPositiveChangesData] = useState([])
    const [negativeChangesData, setNegativeChangesData] = useState([])

    useEffect(() => {
        if(evalPositiveChanges.length>0){
            setPositiveChangesData(evalPositiveChanges)
        }

        if(evalNegativeChanges.length>0){
            setNegativeChangesData(evalNegativeChanges)
        }

    }, [evalNegativeChanges, evalPositiveChanges])

  return (
    <div className="query__metrics__list__container">
        {
            evalPositiveChanges.length > 0
            &&
            <div className="query__metrics__list__box">
                <div className="query__metrics__list__heading">
                    <Tooltip placement="top" title="The questions which have seen a positive change in scores compared to the last evaluation run.">
                        Positive Changes
                    </Tooltip>
                </div>
                <div className="query__metrics__list__box__content">
                    {
                        positiveChangesData.map((data, i) => (
                            i%2 === 0 ?
                            <span key={i} className="query__metrics__list__data query__metrics__list__data--color">{data}</span>
                            :
                            <span key={i} className="query__metrics__list__data">{data}</span>
                        ))
                    }
                </div>
            </div>
        }

        {
            evalNegativeChanges.length > 0
            &&
            <div className="query__metrics__list__box">
                <div className="query__metrics__list__heading">
                    <Tooltip placement="top" title="The questions which have seen a negative change in scores compared to the last evaluation run.">
                        Negative Changes
                    </Tooltip>
                </div>
                <div className="query__metrics__list__box__content">
                    {
                        negativeChangesData.map((data, i) => (
                            i%2 === 0 ?
                            <span key={i} className="query__metrics__list__data query__metrics__list__data--color">{data}</span>
                            :
                            <span key={i} className="query__metrics__list__data">{data}</span>
                        ))
                    }
                </div>
            </div>
        }
    </div>
  );
};

export default QueryMetricsList;
