import React from 'react'
import IterationChart from './IterationChart'

function IterationComparisonGraph() {
  return (
    <div className='iteration__comparison__graph'>
      <IterationChart />
    </div>
  )
}

export default IterationComparisonGraph
