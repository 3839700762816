import React, {useEffect, useState, useRef} from 'react'
import { useNavigate, Link } from 'react-router-dom';


import toggleBarOpen from "../svg-store/navbar/toggleBarOpen.svg"
import toggleBarClose from "../svg-store/navbar/toggleBarClose.svg"
import valianceLogo from "../svg-store/navbar/valianceLogo.svg"
import languageSelectionBar from "../svg-store/navbar/languageSelection.svg"
import userThumbnail from "../image/navbar/user.png"
// import userThumbnail from "../svg-store/navbar/user-thumbnail.svg"
import userOptionBar from "../svg-store/navbar/user-option.svg"
import googleAPISelectorTick from "../svg-store/navbar/googleAPISelector.svg"
import bhasiniAPISelectorTick from "../svg-store/navbar/bhasiniAPISelector.svg"
import languageSelectedTick from "../svg-store/navbar/languageSelectedTick.svg"
import languageDropDownIcon from "../svg-store/navbar/language-dropdown.svg"

import { Tooltip } from 'antd';
import { FaRegSmile } from "react-icons/fa";

import { useLocation } from 'react-router-dom';


const Navbar = ({sideBarStatus, sideBarStatusClick, currentlySelectedLanguage, 
  currentLanguage, currentlySelectedTranslationAPI, currentTranslationAPI, currentSelectedService, handleSelectedService}) => {
  
  const navigate = useNavigate()
  const location = useLocation();
  // console.log(location, location.pathname)

  


  if(currentLanguage === undefined){
    currentLanguage = "en"
  }
  
  if(currentTranslationAPI === undefined){
    currentTranslationAPI = "bh"
  }

  // UserProfile Status
  const [userProfileBoxStatus, setUserProfileBoxStatus] = useState(false)

  // Language Box Status
  const [languageBoxStatus, setlanguageBoxStatus] = useState(false)

  // Sidebar Handler
  const sidebarBtnClickHandler = () => {
    if(sideBarStatus === undefined){
      return
    }
    sideBarStatusClick(!sideBarStatus)
  }

  // Language box
  const languageBoxClickHandler = () => {
    if(location.pathname.includes("technicaladvisor")){
      setlanguageBoxStatus(!languageBoxStatus)
    }
    else{
      setlanguageBoxStatus(false)
    }
  }

  // language Selector Handler
  const languageSelectorHandler = (lang) => {

    if (typeof currentlySelectedLanguage === 'function') {
      currentlySelectedLanguage(lang);
      setlanguageBoxStatus(false);
    }
    else{
      setlanguageBoxStatus(false);
    }
  }

  // User Profile Click handler
  const profileClickHandler = () => {
    setUserProfileBoxStatus(!userProfileBoxStatus)
  }

  // Logout Click handler
  const logOutClickHandler = () => {
      
      if(localStorage.getItem("auth_token") != null){
        localStorage.removeItem("auth_token")
        localStorage.removeItem("is_admin")
        sessionStorage.clear()
        navigate("/")
      }
  }

  const [isAdmin, setIsAdmin] = useState("false")

  useEffect(() => {
    if(localStorage.getItem("is_admin") != null){
      setIsAdmin(localStorage.getItem("is_admin"))
    }
  }, [])

  // console.log("HI")

  // translation Click Handler
  const translationAPIHandler = (translation_api) => {
    currentlySelectedTranslationAPI(translation_api)
  }

  // 
  const translationAPIToggleButtonHandler = () => {
    if(currentTranslationAPI === "bh"){
      currentlySelectedTranslationAPI("go")
    }
    else{
      currentlySelectedTranslationAPI("bh")
    }
  }


  let menuRef1 = useRef();
  let menuRef2 = useRef();
  let menuRef3 = useRef();

  useEffect(() => {
    let handler1 = (e)=>{
      // Condition for Language Box
      if(languageBoxStatus){
        if(!document.getElementsByClassName("nav__language__selection")[0].contains(e.target) && !menuRef1.current.contains(e.target)){
          setlanguageBoxStatus(false);
        } 
      }
    };

    let handler2 = (e)=>{
      // Condition for User Profile
      if(userProfileBoxStatus){
        if(!document.getElementsByClassName("nav__user")[0].contains(e.target) && !menuRef2.current.contains(e.target)){
          setUserProfileBoxStatus(false);
        } 
      }
    };

    let handler3 = (e)=>{
      // Condition for Service Box
      if(serviceBoxStatus){
        if(!document.getElementsByClassName("nav__service__selection")[0].contains(e.target) && !menuRef3.current.contains(e.target)){
          setServiceboxStatus(false);
        } 
      }
    };

    document.addEventListener("mousedown", handler1);
    document.addEventListener("mousedown", handler2);
    document.addEventListener("mousedown", handler3);
    

    return() =>{
      document.removeEventListener("mousedown", handler1);
      document.removeEventListener("mousedown", handler2);
      document.addEventListener("mousedown", handler3);
    }

  });

  // Service selection between OpenAI/Palm
  const [serviceBoxStatus, setServiceboxStatus] = useState(false)
  // Selected Service
  // const [selectedService, setSelectedService] = useState(null)

  
  // Service Box Status
  const serviceBoxClickHandler = () => {
    setServiceboxStatus(!serviceBoxStatus)
  }

  // Service Selection Click Handling
  const serviceSelectionClickHandler = (selected_service) => {
    handleSelectedService(selected_service)
    // Close the box
    setServiceboxStatus(false)
  }

  const languageArray = [{lang: "हिंदी", code: "hi"}, 
  {lang: "English", code: "en"}, {lang: "ગુજરાતી", code: "gu"}, {lang: "Benagli", code: "bn"}, 
  {lang: "Marathi", code: "mr"}, {lang: "Telugu", code: "te"}, {lang: "Tamil", code: "ta"}, {lang: "Kannada", code: "kn"}, 
  {lang: "Malayalam", code: "ml"}, {lang: "Oriya", code: "or"}, {lang: "Punjabi", code: "pa"}]

 

  return (
    <div className='nav__main__box'>

      <div className="nav__left__content">
        <div className='nav__togglebar'>
            <button onClick={sidebarBtnClickHandler}>
                <img src={sideBarStatus ? toggleBarClose : toggleBarOpen} alt="Toggle Bar" />
            </button>
        </div>
        <div className='nav__valiance__logo' style={{cursor: "pointer"}} onClick={() => navigate("/technicaladvisor")}>
            <img src={valianceLogo} alt="valiance" />
        </div>
        <div className="nav__vertical__line"></div>
        <div className='nav__client__name'>
            <span className='nav__client__name--title'>NITI Aayog E-Mitra</span>
            <span className='nav__client__name--subtitle'>Sector-Specific Insights </span>
        </div>
      </div>

      <div className="nav__right__content">
        
        {/* <div className="nav__language__selection nav__service__selection">
            <button onClick={serviceBoxClickHandler}>
                <span className='service--span'> {currentSelectedService === null ? "Service" : currentSelectedService === "palm" ? "Palm" : "OpenAI"} </span>
            </button>

            <div ref={menuRef3} className={serviceBoxStatus ? 'nav__language__dropdown_main__box' : 'nav__language__dropdown_main__box--None'}>
              <div className='nav__language__dropdown_box'>

                <div className="nav__language__dropdown--lowerbox">
                  <span style={{cursor: "pointer"}} onClick={() => serviceSelectionClickHandler(null)}>Select Service</span>
                  <div className='nav__language__dropdown--languagesbox'>


                    <Link onClick={() => serviceSelectionClickHandler("palm")}>
                      <span className={currentSelectedService==="palm" || currentSelectedService===null ? "nav__language__dropdown--activelanguagesbox" : ""}>
                        Palm
                      </span>
                      {currentSelectedService==="palm" || currentSelectedService===null ? <img src={languageSelectedTick} alt="language" /> : ""}
                    </Link>

                    <Link onClick={() => serviceSelectionClickHandler("openai")}>
                      <span className={currentSelectedService==="openai" ? "nav__language__dropdown--activelanguagesbox" : ""}>
                      OpenAI
                      </span>
                      {currentSelectedService==="openai" && <img src={languageSelectedTick} alt="language" />}
                    </Link>
                  </div>
                </div>

              </div>

            </div>


        </div> */}


        <div className="nav__language__selection">

            <button onClick={languageBoxClickHandler}>
                <span> {currentLanguage === undefined ? "EN" : currentLanguage} </span>
                <img src={languageDropDownIcon} alt="drop" />
            </button>

            <div ref={menuRef1} className={languageBoxStatus ? 'nav__language__dropdown_main__box' : 'nav__language__dropdown_main__box--None'}>
              <div className='nav__language__dropdown_box'>

                <div className="nav__language__dropdown--upperbox">
                  <span>Language Translation via</span>
                  <div className='nav__language__dropdown--apiselection'>
                    <Link onClick={() => translationAPIHandler("bh")}>
                      <span className={currentTranslationAPI === "bh" ? 'nav__language__dropdown--selectedapi' : ''}>Bhashini</span>
                    </Link>

                    {/* {
                      currentTranslationAPI === "bh" ? 
                        <Link onClick={translationAPIToggleButtonHandler}>
                          <img src={bhasiniAPISelectorTick} alt="bhasini" />
                        </Link> 
                      : 
                        <Link onClick={translationAPIToggleButtonHandler}>
                          <img src={googleAPISelectorTick} alt="google" /> 
                        </Link>
                    } */}

                    {/* <Link onClick={() => translationAPIHandler("go")}>
                      <span className={currentTranslationAPI === "go" ? 'nav__language__dropdown--selectedapi' : ''}>Google Translate</span>
                    </Link> */}
                  </div>
                  <div className='nav__language--horizontalline'>
                  </div>
                </div>

                <div className="nav__language__dropdown--lowerbox">
                  <span>LANGUAGES</span>
                  <div className='nav__language__dropdown--languagesbox'>
                    {
                      languageArray.map((language, i) => (
                        <Link className={currentLanguage===language.code ? "nav__language__dropdown--activeanchortag" : ""} key={i} onClick={() => languageSelectorHandler(language.code)}>
                          <span className={currentLanguage===language.code ? "nav__language__dropdown--activelanguagesbox" : ""}>
                            {language.lang}
                          </span>
                          {currentLanguage===language.code && <img src={languageSelectedTick} alt="language" />}
                        </Link>
                      ))
                    }


                    {/* <Link onClick={() => languageSelectorHandler("hi")}>
                      <span className={currentLanguage==="hi" ? "nav__language__dropdown--activelanguagesbox" : ""}>
                        हिंदी
                      </span>
                      {currentLanguage==="hi" && <img src={languageSelectedTick} alt="language" />}
                    </Link>
                    <Link onClick={() => languageSelectorHandler("en")}>
                      <span className={currentLanguage==="en" ? "nav__language__dropdown--activelanguagesbox" : ""}>
                        English
                      </span>
                      {currentLanguage==="en" && <img src={languageSelectedTick} alt="language" />}
                    </Link>
                    <Link onClick={() => languageSelectorHandler("gu")}>
                      <span className={currentLanguage==="gu" ? "nav__language__dropdown--activelanguagesbox" : ""}>
                        ગુજરાતી
                      </span>
                      {currentLanguage==="gu" && <img src={languageSelectedTick} alt="language" />}
                    </Link> */}
                  </div>
                </div>
              </div>


            </div>

        </div>

        <div className="nav__user">
            <div className='nav__user__name'>
              <Tooltip placement="bottom" 
              title={
              <div className='nav__user__name__tooltip'>
                <span>Hey, Akshat Mahajan</span>
                <FaRegSmile size={20}/>
              </div>
              }
              >
                Akshat Mahajan                                  
              </Tooltip>
            </div>
            <div className='nav__user__image'>
              <img className='nav__user--img' onClick={profileClickHandler} src={userThumbnail} alt="user-profile" />
              
              {userProfileBoxStatus &&
                <div ref={menuRef2} className='nav__user__img__dropdown__main'>
                  <div className='nav__user__img__dropdown__box'>
                      {
                        isAdmin === "true" &&
                        <button onClick={() => navigate("/admin/dashboard")}>
                          <span>View Stats</span>
                        </button>
                      }

                      <button onClick={logOutClickHandler}>
                        <span>Logout</span>
                      </button>
                  </div>
                </div>
              }

            </div>

            {/* <div className='nav__user__name'>
              <span>Akash Rawat</span>
            </div>
            <div className='nav__user__options'>
              <img src={userOptionBar} alt="user option bar" />
            </div> */}
        </div>
      </div>

    </div>
  )
}

export default Navbar