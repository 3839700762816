import React, { useState, useRef, useEffect } from "react";


import { MdCancel } from "react-icons/md";
import filterIcon from "../../components/svg-store/userInquires/filter.svg"
import { IoIosArrowForward } from "react-icons/io";
import selectedIcon from "../../components/svg-store/userstatistics/selected.svg"
import { AiOutlineExpand } from "react-icons/ai";
import RealTimeMetricsTable from "./RealTimeMetricsTable";
// Date Calendra
import { DatePicker, Tooltip } from 'antd';
import dayjs from 'dayjs';


// Time ago
import {subMonths, format, subDays, subWeeks, subYears, startOfWeek, endOfWeek } from 'date-fns'

// Backend APIs
import { evalMatricOverviewAPI, evalMatricFilterDataAPI, evalMatricFetchDataAPI } from "../../features/RealTimeMetricsPage/RealTimeMetricsPageSlice";
import { useDispatch, useSelector } from "react-redux";
import { setTablePopUPStatus, setRealTimeMetricsSidebarStatus } from "../../features/RealTimeMetricsPage/RealTimeMetricsPageSlice";

function RealTimeMetrics() {
    // Filter useState
  const [filterClickedStatus, setFilterClickedStatus] = useState(false)
  const [subFilterClickedStatus, setSubFilterClickedStatus] = useState(false)
  const [selectedDate, setSelectedDate] = useState([])
  const [selectedFilterDate, setSelectedFilterDate] = useState(0)
  const [currentActiveFilter, setCurrentActiveFilter] = useState(0)
  const [sortByFilterStatus, setSortByFilterStatus] = useState(false)
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const start_date = "1/1/21"
  const curr_date = new Date();
  let currentDate = curr_date.getDate().toString() + "/" + (curr_date.getMonth() + 1).toString() + "/" + curr_date.getFullYear().toString().slice(2)

  // Redux
  const {evalOverviewStatus, sideBarData, tableData, selectedProject, currentSelectedEvalData, evalFetchDataStatus, sidebarIsLoading, currentSelectedWeek} = useSelector((state) => state.realtimemetrics)
  const dispatch = useDispatch()

  // On Page Load
  useEffect(() => {
    if(evalOverviewStatus === 'idle'){
      dispatch(evalMatricOverviewAPI({year: "2024", project_name: "niti"}))
    }
  }, [])


  // On Page Load
  useEffect(() => {
    dispatch(setRealTimeMetricsSidebarStatus(true))
  }, [])


    // useRef
  let menuRef1 = useRef();
  let menuRef2 = useRef();

  // Date Filter Box Close Handler
  useEffect(() => {

    let handler1 = (e)=>{
      if(subFilterClickedStatus){
        if(!document.getElementsByClassName("filter__box__content")[0].contains(e.target) 
        && 
        !menuRef1.current.contains(e.target)
        ){
          if (isDatePickerOpen) {
            return;
          }
            setFilterClickedStatus(false);
            setSubFilterClickedStatus(false);
        }    
    }  
    };


    document.addEventListener("mousedown", handler1);
    

    return() =>{
      document.removeEventListener("mousedown", handler1);
    }

  });

  const filterClickHandler = () => {
    if(filterClickedStatus){
      setSubFilterClickedStatus(false)
    }
    // setFilterClickedStatus(!filterClickedStatus)
    setSubFilterClickedStatus(!subFilterClickedStatus)
  }

  const clearFilter = () => {
    setSelectedDate([])
    if(currentSelectedWeek !== undefined){
      dispatch(evalMatricFetchDataAPI({project_name: selectedProject, week: currentSelectedWeek}))
    }

    setSubFilterClickedStatus(false)
    setFilterClickedStatus(false)
    setSelectedFilterDate(0)
  }

  // Time Ago
  const customDateHandler = (flag) => {
    if(flag === "today"){
      setSelectedFilterDate(1)
      if(selectedFilterDate !== 1){
        dispatch(evalMatricFilterDataAPI({project_name: selectedProject, startDate: currentDate, endDate: currentDate}))
        setSelectedDate([formatDateToDisplay(currentDate), formatDateToDisplay(currentDate)])
      }
    }
    else if(flag === "yesterday"){
      setSelectedFilterDate(2)
      if(selectedFilterDate !== 2){
        let yesterday = subDays(new Date(), 1);
        yesterday = format(yesterday, "dd/MM/yy")
        dispatch(evalMatricFilterDataAPI({project_name: selectedProject, startDate: yesterday, endDate: yesterday}))
        setSelectedDate([formatDateToDisplay(yesterday), formatDateToDisplay(yesterday)])
      }
    }
    else if(flag === "week"){
      setSelectedFilterDate(3)
      if(selectedFilterDate !== 3){
        let week = subWeeks(new Date(), 1);
        week = format(week, "dd/MM/yy")
        dispatch(evalMatricFilterDataAPI({project_name: selectedProject, startDate: week, endDate: currentDate}))
        setSelectedDate([formatDateToDisplay(week), formatDateToDisplay(currentDate)])
      }
    }
    else if(flag === "month"){
      setSelectedFilterDate(4)
      if(selectedFilterDate !== 4){
        let oneMonthAgo = subMonths(new Date(), 1);
        oneMonthAgo = format(oneMonthAgo, "dd/MM/yy")

        dispatch(evalMatricFilterDataAPI({project_name: selectedProject, startDate: oneMonthAgo, endDate: currentDate}))
        
        setSelectedDate([formatDateToDisplay(oneMonthAgo), formatDateToDisplay(currentDate)])
      }
    }
    else{
      setSelectedFilterDate(5)
      if(selectedFilterDate !== 4){
        let year = subYears(new Date(), 1);
        year = format(year, "dd/MM/yy")

        dispatch(evalMatricFilterDataAPI({project_name: selectedProject, startDate: year, endDate: currentDate}))
        
        setSelectedDate([formatDateToDisplay(year), formatDateToDisplay(currentDate)])
      }
    }

    setFilterClickedStatus(false)
    setCurrentActiveFilter(0)
    setSortByFilterStatus(false)
    setSubFilterClickedStatus(!subFilterClickedStatus)
  }

  // Format Date
  const formatDateToDisplay = (input_date) => {
    let dateStr = input_date

    // Split the date string into day, month, and year
    var parts = dateStr.split('/');

    // Convert the string parts into integers
    var day = parseInt(parts[0], 10);
    var month = parseInt(parts[1], 10);
    var year = parseInt(parts[2], 10);


    // Define month names
    var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    // Format the date string as "DD Mon YY"
    var formattedDate = ('0' + day).slice(-2) + ' ' + monthNames[month - 1] + ' ' + ('' + year).slice(-2);

    return formattedDate
  }

  const dateRangeSelectorFunction = (date_data) => {
    let st_date = null
    let ed_date = null

    if(date_data !== null){
      let year = date_data[0]["$y"].toString().slice(2)
      st_date = date_data[0]["$D"].toString() + "/" + (date_data[0]["$M"] + 1).toString() + "/" +year

      // console.log(st_date)

      year = date_data[1]["$y"].toString().slice(2)
      ed_date = date_data[1]["$D"].toString() + "/" + (date_data[1]["$M"] + 1).toString() + "/" +year
      // console.log(ed_date)
      
    //   dispatch(evalMatricFilterDataAPI({project_name: selectedProject, startDate: st_date, endDate: ed_date}))

      setFilterClickedStatus(false)
      setCurrentActiveFilter(0)
      setSortByFilterStatus(false)
      setSubFilterClickedStatus(!subFilterClickedStatus)

      setSelectedDate([formatDateToDisplay(st_date), formatDateToDisplay(ed_date)])
    }

    if(st_date !== null){
      
    }
  }  

  return (
    <div className='admin__content__main'>
      <div className="admin__content__container real__time__metrics__elem--gaph">

        <div className="real__time__metrics__heading">
            <div className="userstatistics__filter__box">
                <div className='userinqueries__filter__box__leftelem userinqueries__filter__box--changes'>

                    <div className="userinquires__filter">
                        
                        <div className="userstats__leftelem">
                        <button ref={menuRef1} onClick={filterClickHandler} className='userinquires__filter__button'>
                            <img src={filterIcon} alt="filter" />
                            <div className='userinquires__filter__badge'>
                                <span>Filter:&nbsp;
                                <span className='filter--color'>
                                    {
                                    selectedDate.length === 0 ?
                                    "DD-MM-YY"
                                    :
                                    selectedDate[0] === selectedDate[1]
                                    ?
                                    `${selectedDate[0]}`
                                    :
                                    `${selectedDate[0]} - ${selectedDate[1]}`
                                    }
                                    
                                </span>
                                </span>
                            </div>
                        </button>
                        
                        {
                            selectedDate.length !== 0 &&
                            <div onClick={clearFilter} className="userstats__cancel__div">
                            <MdCancel size={20} color={"#828282"}/>
                            </div>
                        }
                        </div>


                        {filterClickedStatus && 
                        <div onClick={() => setSubFilterClickedStatus(!subFilterClickedStatus)} className="filter__box__dropdown1">
                            <div className="filter__box__dropdown__elem">
                            <span className="filter__box__dropdown__elem__span">Filter by Date</span>
                            <IoIosArrowForward size={20}/>
                            </div>

                        </div>
                        }
                    </div>

                    {subFilterClickedStatus &&
                        <div className="filter__box__content filter__box__content--changes">
                        <div className="filter__date__box">

                            <div className="filter__date__elem1">
                            <div className="filter__date__font__heading">Filter by Query Date</div>
                            <div className={selectedFilterDate===1 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                                <button onClick={() => customDateHandler("today")} className="no__button filter__date__font">
                                Today
                                {selectedFilterDate===1 && <img src={selectedIcon} alt="select" />}
                                </button>
                            </div>
                            <div className={selectedFilterDate===2 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                                <button onClick={() => customDateHandler("yesterday")} className="no__button filter__date__font">
                                Yesterday
                                {selectedFilterDate===2 && <img src={selectedIcon} alt="select" />}
                                </button>
                            </div>
                            <div className={selectedFilterDate===3 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                                <button onClick={() => customDateHandler("week")} className="no__button filter__date__font">
                                1 Week ago
                                {selectedFilterDate===3 && <img src={selectedIcon} alt="select" />}
                                </button>
                            </div>
                            <div className={selectedFilterDate===4 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                                <button onClick={() => customDateHandler("month")} className="no__button filter__date__font">
                                1 Month ago
                                {selectedFilterDate===4 && <img src={selectedIcon} alt="select" />}
                                </button>
                            </div>
                            <div className={selectedFilterDate===5 ? "filter__date__child__box1 filter__date__child__box1--color" : "filter__date__child__box1"}>
                                <button onClick={() => customDateHandler("year")} className="no__button filter__date__font">
                                1 Year ago
                                {selectedFilterDate===5 && <img src={selectedIcon} alt="select" />}
                                </button>
                            </div>
                            </div>

                            <div className="filter__date__horizontal__line"></div>

                            <div className="filter__date__elem2">
                            <span className="filter__date__elem2__text">Custom Date Range</span>

                            <div className="filter__date__specific__date">
                                <DatePicker.RangePicker placeholder={["Start", "End"]}
                                className="antd__datepicker"
                                format={"DD-MM-YY"}
                                minDate={dayjs(start_date)}
                                maxDate={dayjs(new Date())}
                                onChange={dateRangeSelectorFunction}
                                onOpenChange={(open) => setIsDatePickerOpen(open)}
                                />
                            </div>

                            </div>
                        </div>
                        </div>
                    }
                    
                </div>
            </div>

            <div className="e__matrix__expand" 
            onClick={() => dispatch(setTablePopUPStatus(true))}
            >
                <AiOutlineExpand size={20}/>
            </div>
        </div>

        <div className="real__time__metrics__table__box">
           <RealTimeMetricsTable />
        </div>

      </div>
    </div>
  )
}

export default RealTimeMetrics
